import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import Button from 'common/components/elements/buttons/primary';
import Switch from 'common/components/elements/inputs/switch';
import PropTypes from 'prop-types';
import IconLabel from 'common/components/elements/icon-label';
import Input from 'common/components/elements/inputs/inputs';
import Editor from 'common/components/modules/editor';
import VariableList from './variable-list';
import { getSubjectVariablesList, getAutoTweetVariablesList } from 'admin/views/my-site/settings/outgoing-notification-and-auto-tweet/data';
import randomstring from 'randomstring';
import toast from 'common/utils/toast';
import EmojiContent from 'common/components/modules/emoji-content';
import { getNickName } from 'common/utils/helper';
import TextArea from 'common/components/elements/inputs/textarea';
import { insertTextMac, getBrowserName } from 'common/utils/utils';
import { useSelector } from 'react-redux';
import { getProtocol } from 'common/utils/utils';
import CreateEditModal from 'common/components/modules/modal-wrapper';

const TWITTER_MAX_TEXT_LIMIT = 270;

const AutoNotificationWrrapper = ({
   title,
   icon,
   name,
   description,
   checked,
   needToConnectTwiter,
   onClickConnectTwitterAccount,
   tooltipText,
   onChange,
   hasPointerCursor,
   children,
   className,
   fetchingConnectedTwitterAccount,
   disabled,
   // screenWidth,
   showNewStyle,
   isInsideModal,
}) => {

   const isActiveState = checked || needToConnectTwiter;
   const switchComponent = (<Switch
      name={ name }
      checked={ checked }
      onChange={ onChange }
      disabled={ disabled || needToConnectTwiter }
      hasPointerCursor={ hasPointerCursor }
   />);
   const switchComponentTooltipText = () => {

      if(tooltipText){
         return tooltipText;
      }
      if(needToConnectTwiter){
         return 'Connect your twitter to enable Auto post';
      }
      return null
   }
   const screenWidth = useSelector(state => state.common.screenWidth);
   const [twitterConnectionModal, setTwitterConnectionModal] = useState(false);

   return (
      <div
         className={ `${ cx({
            [`flex flex-col bg-panel rounded-lg   w-full ${ className }`]: true,
            'border border-divider p-6': screenWidth > 1024 || showNewStyle,
            'py-6': screenWidth < 1025,
            '!mb-6 last:!mb-0': showNewStyle,
         }) }` }
      >
         <div
            className={ `${ cx({
               'flex justify-between': true,
               'items-baseline': 'auto_tweet' !== name,
               'items-center': 'auto_tweet' === name,
            }) }` }
         >
            <div className='flex flex-col'>
               <div
                  className={ `${ cx({
                     'flex items-center': true,
                     // 'mb-3': description,
                  }) }` }
               >
                  <IconLabel
                     color={  disabled || needToConnectTwiter ? 'disabled' : isActiveState ? 'action' : 'secondary'  }
                     name={ icon }
                     className='mr-3 h-8 w-8 min-w-[32px]'
                  />
                  <span className='font-bold text-major leading-[24px]'>{title}</span>
               </div>
               {
                  description && (
                     <span className='font-medium mt-1 text-placeholder panel-description leading-[20px] ml-[44px] text-start'>{description}</span>
                  )
               }

            </div>
            {
               switchComponentTooltipText() && (
                  <div data-tooltip-content={ switchComponentTooltipText() } data-tooltip-id='ams-top-tooltip' className='flex'>
                     {switchComponent}
                  </div>
               )
            }
            {
               !switchComponentTooltipText() && (
                  <>
                     {switchComponent}
                  </>
               )
            }
         </div>
         {
            needToConnectTwiter && (
               <div className='flex flex-col mt-4'>
                  <span className='text-secondary text-sm leading-[20px] font-medium w-fit ml-11'>Connect your X account to send {isInsideModal ? '' : 'Auto'} post</span>
                  <div className='inline-flex mr-auto justify-between mt-4 h-9'>
                     <Button
                        text='Connect to X account'
                        textSize='small'
                        classNames='h-full w-full px-[14px]'
                        iconName='connect'
                        iconPosition='left'
                        alignment='end'
                        backgroundColor='transparent'
                        primaryColor
                        fontIconSize='large'
                        textPosition='left'
                        padding='0'
                        textMarginX='0 ml-2'
                        onClick={ _  => setTwitterConnectionModal(true) }
                     />
                  </div>
                  {
                     window.twitter_connection_is_failed && (
                        <span
                           className='text-xs mt-1 text-error leading-[14px]'>
                           Something went wrong. Please try again.
                        </span>
                     )
                  }
               </div>
            )
         }
         {
            children && children
         }
         {
            twitterConnectionModal && (
               <CreateEditModal
                  type='confirm'
                  icon='connect'
                  descriptionColorClass='text-secondary'
                  action={ onClickConnectTwitterAccount }
                  onCloseModal={ () => setTwitterConnectionModal(false) }
                  title='Connect your X account'
                  description={ `Connect your X account to send ${ isInsideModal ? '' : 'Auto' } post` }
                  cancelText='Cancel'
                  buttonText='Connect to X account'
                  contentWidth='375px'
                  // isMobile={ isMobile }
                  buttonClassName='mt-8'
                  // iconSize='2xl'
                  disabled={ fetchingConnectedTwitterAccount }
               />
            )
         }
      </div>
   )
}

const AutoNotificationEdit = ({
   isTweet,
   data,
   isMobile,
   textAreaRef,
   inputRef,
   editorRef,
   name,
   onSaveAfterBlur,
   onChangeInput,
   onChangeEditor,
   errors,
   variableList,
   editorControl,
   tweetAttachment,
   messageAttachment,
   onChangeTextArea,
   subjectEditorTop,
   linkVariableName,
   slug,
   bodyLimit,
   hideSubject,
   showOptional,
   showVariableButton = true,
   isBodyInput,
   placeholderText,
   isInsideModal,
}) => {
   const [cursorPosition, setCursorPosition] = useState(null);


   const addEmoji = (e, type) => {
      const sym = e.unified.split('-');
      const codesArray = [];
      sym.forEach(el => codesArray.push(`0x${ el }`));
      const emojiPic = String.fromCodePoint(...codesArray);
      if('input' === type || 'textarea' === type) {
         let el = inputRef.current;
         if('textarea' === type){
            el = textAreaRef.current;
         }
         const selectionStart = el.selectionStart;
         if('safari' !== getBrowserName()){
            window.document.execCommand('insertText', false, emojiPic);
         } else {
            insertTextMac(el, selectionStart, emojiPic)
         }
         el.selectionStart = el.selectionEnd = selectionStart + emojiPic.length;
         el.focus();
      }
      if('editor' === type) {
         let el = editorRef.current
         el.editor.insertContent(`${ emojiPic } `)
      }
   }
   const addVariableIntoInput = (value, type = 'input') => {
      let el = inputRef.current;
      if('textarea' === type){
         el = textAreaRef.current;
      }
      const selectionStart = el.selectionStart;
      if('safari' !== getBrowserName()){
         window.document.execCommand('insertText', false, value);
      } else {
         insertTextMac(el, selectionStart, value)
      }
      el.selectionStart = el.selectionEnd = selectionStart + value.length;
      el.focus();
      return
   }

   const onFocusInput = async (type) => {
      if('input' === type || 'textarea' === type) {
         let ctrl = inputRef.current;
         if('textarea' === type){
            ctrl = textAreaRef.current;
         }
         let start = cursorPosition
         if(!start) {
            start = ctrl.value.length
         }
         if(ctrl != null) {
            if(ctrl.createTextRange) {
               let range = ctrl.createTextRange();
               range.move('character', start);
               range.select();
            }
            else {
               if(ctrl.selectionStart) {
                  ctrl.focus();
                  ctrl.setSelectionRange(start, start);
               }
               else
                  ctrl.focus();
            }
         }
      }
      if('editor' === type) {
         const ctrl = editorRef.current
         const editor = ctrl.editor
         editor.focus();
      }
   }

   const switchShowEmojis = async (type) => {
      if('input' === type || 'textarea' === type) {
         let ctrl = inputRef.current
         if('textarea' === type){
            ctrl = textAreaRef.current;
         }
         let start = cursorPosition
         if(!start) {
            start = ctrl.value.length
         }
         if(ctrl != null) {
            if(ctrl.createTextRange) {
               let range = ctrl.createTextRange();
               range.move('character', start);
               range.select();
            }
            else {
               if(ctrl.selectionStart) {
                  ctrl.focus();
                  ctrl.setSelectionRange(start, start);
               }
               else
                  ctrl.focus();
            }
         }
      }
      if('editor' === type) {
         const ctrl = editorRef.current
         const editor = ctrl.editor
         editor.focus();
      }
   }


   const getCurrentPosition = (type) => {
      let oField = inputRef.current
      if('textarea' === type){
         oField = textAreaRef.current;
      }
      let iCaretPos = 0;
      if(document.selection) {
         oField.focus();
         let oSel = document.selection.createRange();
         oSel.moveStart('character', -oField.value.length);
         iCaretPos = oSel.text.length;
      }

      else if(oField.selectionStart || +oField.selectionStart === 0)
         iCaretPos = oField.selectionDirection === 'backward' ? oField.selectionStart : oField.selectionEnd;

      return iCaretPos;
   }


   const calcTextLength = (value, variables) => {
      if(!value) return 0;
      let result = value;
      for(const iterator of variables) {
         if(iterator.valueLimit){
            let editorVariabel = iterator.text;
            editorVariabel = editorVariabel.replace('<', '&lt;');
            editorVariabel = editorVariabel.replace('>', '&gt;');
            result = result.replaceAll(iterator.text, randomstring.generate(iterator.valueLimit));
            result = result.replaceAll(editorVariabel, randomstring.generate(iterator.valueLimit));
         }
      }
      return result.length;
   }

   const getLinkOfContentVarable = () => {
      const linkVarables = variableList.find(el => el.text === '<link-of-content>') || { valueLimit: 0 };
      return linkVarables;
   }

   return (
      <>
         {
            isTweet && (
               <AutoNotificationTweet
                  data={ data }
                  variablesList={ getAutoTweetVariablesList(variableList) }
                  calcTextLength={ calcTextLength }
                  isMobile={ isMobile }
                  selectVariable={ (variable, type) => {
                     switchShowEmojis(type);
                     addVariableIntoInput(variable, type)
                  } }
                  onFocusInput={ onFocusInput }
                  addEmoji={ addEmoji }
                  switchShowEmojis={ switchShowEmojis }
                  name={ name || 'body' }
                  onTextAreaBlur={ (e) => {
                     let body = e.target.value;
                     if(onSaveAfterBlur){
                        onSaveAfterBlur(body, 'body')
                     }
                     const positionCursor = getCurrentPosition('textarea')
                     setCursorPosition(positionCursor);
                  } }
                  onChangeTextArea={ onChangeTextArea }
                  textAreaRef={ textAreaRef }
                  errors={ errors }
                  attachment={ tweetAttachment }
                  linkVariableName={ linkVariableName }
                  linkVarables={ getLinkOfContentVarable() }
                  isInsideModal={ isInsideModal }
               />
            )
         }
         {
            !isTweet && (
               <AutoNotificationMessage
                  data={ data }
                  variablesList={ variableList }
                  calcTextLength={ calcTextLength }
                  isMobile={ isMobile }
                  selectVariable={ (variable, type) => {
                     switchShowEmojis(type);
                     addVariableIntoInput(variable, type)
                  } }
                  onFocusInput={ onFocusInput }
                  addEmoji={ addEmoji }
                  switchShowEmojis={ switchShowEmojis }
                  name={ name  || 'subject' }
                  onInputBlur={ (e) => {
                     let subject = e.target.value;
                     if(onSaveAfterBlur){
                        onSaveAfterBlur(subject, 'subject')
                     }
                     const positionCursor = getCurrentPosition()
                     setCursorPosition(positionCursor);
                  } }
                  inputRef={ inputRef }
                  onChangeInput={ onChangeInput }
                  subjectVariableList={ getSubjectVariablesList(variableList) }
                  editorRef={ editorRef }
                  onChangeEditor={ onChangeEditor }
                  errors={ errors }
                  editorControl={ editorControl }
                  attachment={ messageAttachment }
                  subjectEditorTop={ subjectEditorTop }
                  bodyLimit={ bodyLimit }
                  hideSubject={ hideSubject }
                  showOptional={ showOptional }
                  showVariableButton={ showVariableButton }
                  isBodyInput={ isBodyInput }
                  placeholderText={ placeholderText }
                  isInsideModal={ isInsideModal }
               />
            )
         }

      </>
   )
}

const AutoNotificationTweet = ({
   data,
   calcTextLength,
   variablesList,
   isMobile,
   name,
   textAreaRef,
   onFocusInput,
   switchShowEmojis,
   addEmoji,
   selectVariable,
   onTextAreaBlur,
   onChangeTextArea,
   attachment,
   linkVariableName,
   errors,
   linkVarables,
   isInsideModal,
}) => {
   const [isFocus, setFocus] = useState(false);
   useEffect(() => {
      if(textAreaRef?.current && textAreaRef.current?.style && textAreaRef.current?.scrollHeight) {
         textAreaRef.current.style.height = textAreaRef.current.scrollHeight + "px";
      }
   }, [textAreaRef]);
   const teweetConstTextLangth = getNickName().length + getProtocol().length + linkVarables.valueLimit + 8;
   return (
      <div className='relative w-full flex flex-col mt-4'>
         <div className='flex justify-between mb-2 h-6 items-center'>
            <span className='block leading-none font-medium text-base text-major'>Auto post text</span>
            <VariableList
               variableList={ variablesList }
               selectVariable={ (variable) => {
                  selectVariable(variable, 'textarea')
               } }
               onClickOpen={ (variable) => onFocusInput('textarea') }
               name='auto_tweet'
               isInsideModal={ isInsideModal }
            />
         </div>

         <div className='border rounded-lg border-divider relative min-h-[120px]'>
            <EmojiContent
               switchShowEmojis={ (e) => switchShowEmojis('textarea') }
               // showEmojis={ e => showEmojis(e, textRef) }
               addEmoji={  (e) => addEmoji(e, 'textarea') }
               isMobile={ isMobile }
               id={ `${ name }_body_emoji` }
               style={ {
                  width: '20px',
                  height: '20px',
                  top: '0px',
                  marginTop: '10px',
                  marginBottom: '6px',
                  zIndex: 1,
               } }
               className='absolute right-0 mr-4 h-5 w-5'
               size='h-5 w-5'
               primaryIconSize='20px'
            />
            <TextArea
               value={ data?.body }
               onChange={ (name, value) => {
                  if(calcTextLength(value, variablesList) + teweetConstTextLangth > TWITTER_MAX_TEXT_LIMIT) {
                     toast.error(`${ TWITTER_MAX_TEXT_LIMIT } character limit has been reached`, { toastId: 'tweet_character_limit_reached' })
                     return
                  }
                  onChangeTextArea(name, value)


                  if(!textAreaRef?.current || !textAreaRef?.current?.style) {
                     return;
                  }
                  textAreaRef.current.style.height = 'auto';
                  if(textAreaRef.current?.scrollHeight){
                     textAreaRef.current.style.overflowY = 'hidden';
                     textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
                  }
               } }
               name={ name }
               TextAreaRef={  textAreaRef }
               className='tweet-body-content'
               onTextAreaBlur={ (e) => {
                  onTextAreaBlur(e)
                  setFocus(false)
               } }
               height='auto'
               errorMessage={ errors?.body?.[0] || null }
               onTextAreaFocus={ _ => {
                  setFocus(true)
               } }
            />
            <div className='text-placeholder leading-tight px-4 pb-2 !w-fit'>
               {linkVariableName}
               <br />
               {getNickName()}
            </div>
         </div>
         {
            (isFocus || !errors?.body?.[0]) && (
               <span className='leading-none font-normal text-xs mt-[6px] select-none text-secondary ml-auto'>{`${ data.body  ? calcTextLength(data.body, variablesList) + teweetConstTextLangth : teweetConstTextLangth } of ${ TWITTER_MAX_TEXT_LIMIT } characters`}</span>
            )
         }
         {
            !isFocus && errors?.body?.[0] && (
               <span
                  style={ { minHeight: '14px' } }
                  className='leading-none font-normal text-xs mt-[6px] text-error dark:text-error-dark block text-end'
               >
                  {errors?.body?.[0] }
               </span>
            )
         }

         {
            attachment && attachment
         }
      </div>

   )
}

const AutoNotificationMessage = ({
   data,
   calcTextLength,
   variablesList,
   isMobile,
   name,
   onFocusInput,
   switchShowEmojis,
   addEmoji,
   selectVariable,
   onInputBlur,
   errors,
   inputRef,
   onChangeInput,
   subjectVariableList,
   editorRef,
   onChangeEditor,
   editorControl,
   attachment,
   subjectEditorTop,
   bodyLimit,
   hideSubject,
   showOptional,
   showVariableButton = true,
   isBodyInput,
   placeholderText,
   isInsideModal,
}) => {
   const [titleIsError, setTitleIsError] = useState(false);
   const screenWidth = useSelector(state => state.common.screenWidth);

   let editorState = {}
   if(editorControl){
      editorState = {
         value: data.body,
      }
   }
   return (
      <>
         {
            hideSubject ||
               <div className='relative flex flex-col w-full mt-4'>
                  <div className='flex justify-between mb-2 items-center h-6'>
                     <span className='text-base leading-tight select-none font-medium text-major dark:text-major-dark'>
                        Subject
                     </span>
                     <VariableList
                        variableList={ subjectVariableList }
                        selectVariable={ (variable) => {
                           selectVariable(variable, 'input')
                        } }
                        onClickOpen={ (variable) => onFocusInput('input') }
                        name={ `${ name }_subject_variable_list` }
                        className=''
                        isInsideModal={ isInsideModal }
                     />
                  </div>
                  <Input
                     onChange={ (name, value) => {
                        // if(calcTextLength(value, subjectVariableList) > 78) {
                        //    toast.error(`78 character limit has been reached`, { toastId: 'auto_notification_subject_limit_reached' })
                        //    return
                        // }
                        onChangeInput(name,  value)
                     } }
                     type='text'
                     name='subject'
                     value={ data.subject }
                     initialvalue={ data.subject }
                     errorMessage={ errors && errors.subject && errors.subject[0] }
                     placeholder='What’s your message about?'
                     // description={ `${ calcTextLength(data.subject, subjectVariableList) } of 78 characters used` }
                     descriptionFontSize='xs'
                     descriptionFontWeight='normal'
                     onInputBlur={ onInputBlur }
                     inputRef={ inputRef }
                     className='input-with-emoji'
                     height='10'
                     descriptionPosition='right'
                     hideDescriptionWhenError
                     onSetError={ bool => setTitleIsError(bool) }
                     onInputFocus={ () => {
                        // console.log('x');
                        // if(!!inputRef) {
                        //    console.log(inputRef.current.value);
                        //    const length = inputRef.current.value.length;
                        //    console.log(length);
                        //    inputRef.current.setSelectionRange(length, length);
                        //    inputRef.current.focus()
                        //    inputRef.current.setSelectionRange(length, length);
                        //    inputRef.current.focus()
                        // }
                        // console.log('y');
                     } }
                  />
                  {
                     !titleIsError && (
                        <EmojiContent
                           switchShowEmojis={ (e) => switchShowEmojis('input') }
                           // showEmojis={ e => showEmojis(e, textRef) }
                           addEmoji={  (e) => addEmoji(e, 'input') }
                           isMobile={ isMobile }
                           id={ `${ name }_subject_emoji` }
                           style={ {
                              width: '20px',
                              height: '20px',
                              top: '32px',
                              marginTop: '10px',
                              marginBottom: '6px',
                              zIndex: 1,
                           } }
                           className='absolute right-0 mr-4 h-5 w-5'
                           size='h-5 w-5'
                           primaryIconSize='20px'
                        />
                     )
                  }
               </div>
         }
         {
            isBodyInput &&
               <div className={ `relative flex flex-col w-full ${ showOptional ? `` : `mt-4` } ` }>
                  <div className='flex justify-between mb-2 items-center h-6'>
                     <div className='flex items-center gap-1'>
                        <span className='text-base leading-tight select-none font-medium text-major dark:text-major-dark'>
                           Message
                        </span>
                        {
                           showOptional && (
                              <p className={ `cursor-default text-xs text-placeholder dark:text-placeholder-dark leading-[15px]` }>
                                 (optional)
                              </p>
                           )
                        }
                     </div>
                     <VariableList
                        variableList={ variablesList }
                        selectVariable={ (variable) => {
                           selectVariable(variable, 'input')
                        } }
                        onClickOpen={ (variable) => onFocusInput('input') }
                        name={ `${ name }_body_variable_list` }
                        className=''
                        isInsideModal={ isInsideModal }
                     />
                  </div>
                  <Input
                     onChange={ (name, value) => {
                        if(calcTextLength(value, variablesList) > 2000) {
                           toast.error(`2000 character limit has been reached`, { toastId: 'auto_notification_subject_limit_reached' })
                           return
                        }
                        onChangeInput(name,  value)
                     } }
                     type='text'
                     name='body'
                     value={ data.body }
                     initialvalue={ data.body }
                     errorMessage={ errors && errors.body && errors.body[0] }
                     placeholder={ placeholderText || 'What’s your message about?' }
                     description={ `${ calcTextLength(data.body, variablesList) } of 2000 characters used` }
                     descriptionFontSize='xs'
                     descriptionFontWeight='normal'
                     onInputBlur={ onInputBlur }
                     inputRef={ inputRef }
                     className='input-with-emoji'
                     height='10'
                     descriptionPosition='right'
                     hideDescriptionWhenError
                     onSetError={ bool => setTitleIsError(bool) }
                     onInputFocus={ () => {
                        // console.log('x');
                        // if(!!inputRef) {
                        //    console.log(inputRef.current.value);
                        //    const length = inputRef.current.value.length;
                        //    console.log(length);
                        //    inputRef.current.setSelectionRange(length, length);
                        //    inputRef.current.focus()
                        //    inputRef.current.setSelectionRange(length, length);
                        //    inputRef.current.focus()
                        // }
                        // console.log('y');
                     } }
                     inputClassName={ `!pr-9 ` }

                  />
                  {
                     !titleIsError && (
                        <EmojiContent
                           switchShowEmojis={ () => switchShowEmojis('input') }
                           addEmoji={ (e) => addEmoji(e, 'input') }
                           isMobile={ isMobile }
                           style={ {
                              width: '20px',
                              height: '20px',
                              bottom: '32px',
                              marginTop: '6px',
                              marginBottom: '6px',
                           } }
                           className='absolute right-0 mr-[14px] h-5 w-5'
                           top={ subjectEditorTop || '-260px' }
                           id={ `${ name }_body_emoji` }
                           size='h-5 w-5'
                           primaryIconSize='20px'
                        />
                     )
                  }
               </div>
         }
         {
            !isBodyInput &&
               <div
                  className={ `relative w-full ${ showOptional ? `mt-2 ` : `mt-4` }` }>
                  <Editor
                     refProps={ editorRef }
                     toolbar={ ['bold', 'italic', 'underline', 'align', 'link'] }
                     label='Body'
                     name='body'
                     handleChange={ onChangeEditor }
                     height={ screenWidth <= 437 ?  208 : 164 }
                     initialValue={ data.body }
                     { ...editorState }
                     isMobile={ isMobile }
                     placeholder='Type your message...'
                     variableList={ variablesList }
                     errorMessage={ errors && errors.body && errors.body[0] }
                     limit={ errors?.body?.[0] ? null : (bodyLimit || 3000) }
                     isShowEmoji
                     onCharCount={ value => calcTextLength(value, variablesList) }
                     isShowVariableButton={ showVariableButton }
                     errorMessageClassName='text-xs text-end mt-2'
                     showOptional={ showOptional }
                     bounds={ null }
                  />
                  {
                     attachment && attachment
                  }
               </div>
         }
      </>
   )
}

AutoNotificationWrrapper.defaultProps = {
   className: '',
   showNewStyle: false,
}
AutoNotificationTweet.propTypes = {
   data: PropTypes.object,
   variablesList: PropTypes.array,
   name: PropTypes.string,
   isMobile: PropTypes.bool,
   textAreaRef: PropTypes.any,
   attachment: PropTypes.any,
   onFocusInput: PropTypes.func,
   addEmoji: PropTypes.func,
   selectVariable: PropTypes.func,
   onChangeTextArea: PropTypes.func,
   onTextAreaBlur: PropTypes.func,
   switchShowEmojis: PropTypes.func,
   calcTextLength: PropTypes.func,
   linkVariableName: PropTypes.string,
   errors: PropTypes.object,
   linkVarables: PropTypes.object,
   isInsideModal: PropTypes.bool,
};
AutoNotificationMessage.propTypes = {
   data: PropTypes.object,
   errors: PropTypes.object,
   subjectVariableList: PropTypes.array,
   variablesList: PropTypes.array,
   name: PropTypes.string,
   isTweet: PropTypes.bool,
   isMobile: PropTypes.bool,
   editorControl: PropTypes.bool,
   textAreaRef: PropTypes.any,
   inputRef: PropTypes.any,
   attachment: PropTypes.any,
   editorRef: PropTypes.any,
   onFocusInput: PropTypes.func,
   addEmoji: PropTypes.func,
   selectVariable: PropTypes.func,
   onChangeEditor: PropTypes.func,
   onInputBlur: PropTypes.func,
   onChangeInput: PropTypes.func,
   switchShowEmojis: PropTypes.func,
   calcTextLength: PropTypes.func,
   slug: PropTypes.string,
   subjectEditorTop: PropTypes.string,
   bodyLimit: PropTypes.number,
   hideSubject: PropTypes.bool,
   showOptional: PropTypes.bool,
   showVariableButton: PropTypes.bool,
   isBodyInput: PropTypes.bool,
   placeholderText: PropTypes.string,
   isInsideModal: PropTypes.bool,
};
AutoNotificationEdit.propTypes = {
   data: PropTypes.object,
   errors: PropTypes.object,
   variableList: PropTypes.array,
   name: PropTypes.string,
   isTweet: PropTypes.bool,
   isMobile: PropTypes.bool,
   editorControl: PropTypes.bool,
   textAreaRef: PropTypes.any,
   inputRef: PropTypes.any,
   tweetAttachment: PropTypes.any,
   messageAttachment: PropTypes.any,
   editorRef: PropTypes.any,
   onSaveAfterBlur: PropTypes.func,
   onChangeTextArea: PropTypes.func,
   onChangeInput: PropTypes.func,
   onChangeEditor: PropTypes.func,
   subjectEditorTop: PropTypes.string,
   linkVariableName: PropTypes.string,
   slug: PropTypes.string,
   bodyLimit: PropTypes.number,
   hideSubject: PropTypes.bool,
   showOptional: PropTypes.bool,
   showVariableButton: PropTypes.bool,
   isBodyInput: PropTypes.bool,
   placeholderText: PropTypes.string,
   isInsideModal: PropTypes.bool,
};
AutoNotificationWrrapper.propTypes = {
   title: PropTypes.string,
   icon: PropTypes.string,
   tooltipText: PropTypes.string,
   className: PropTypes.string,
   name: PropTypes.string,
   description: PropTypes.string,
   checked: PropTypes.bool,
   needToConnectTwiter: PropTypes.bool,
   hasPointerCursor: PropTypes.bool,
   onClickConnectTwitterAccount: PropTypes.func,
   children: PropTypes.any,
   onChange: PropTypes.func,
   fetchingConnectedTwitterAccount: PropTypes.bool,
   disabled: PropTypes.bool,
   showNewStyle: PropTypes.bool,
   // screenWidth: PropTypes.number,
   isInsideModal: PropTypes.bool,
};

export { AutoNotificationWrrapper, AutoNotificationEdit, AutoNotificationTweet, AutoNotificationMessage };
