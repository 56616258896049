import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import VideoCard from 'common/components/modules/video-card';
import Button from 'common/components/elements/buttons/primary';
import NoMoreResults from 'common/components/modules/no-more-results';
import EmptyState from 'common/components/modules/empty-states';
import VideoFilterContent from './filter-content';
import CircleLoader from 'common/components/elements/loader/circle';
import cx from 'classnames';
import BulkEditButton from 'admin/views/media-modules/bulk-edit-button';
import CountState from 'admin/views/media-modules/count-state';
import ReorderingContent, { SortableItem } from 'admin/views/reorder-content';
import { createPortal } from 'react-dom';
// import PinLabel from 'common/components/modules/pin-label';

function Videos({
   storyCard,
   onClickCreate,
   resources,
   onFilterChange,
   isNewFetching,
   isFetchingByFilter,
   isEmptyByFilter,
   endList,
   desktopType,
   openDeleteConfirmModal,
   onMoreInfoVideos,
   onClearAllFilters,
   emptyFilters,
   downloadOriginalFile,
   isDownloading,
   isInsideModal,
   onSelectThisVideo,
   onBulkEdit,
   filters,
   destroyButtonDisabled,
   destroy,
   totalCount,
   onClickDetailsLink,
   siteUrl,
   onClickOpenTips,
   onClickSetId,
   allowAddContent,
   countState,
   showNewStyles,
   showCheckboxes,
   selections,
   screenWidth,
   isReorderMode,
   setReorderMode,
   onSortEnd,
   onClickPreview,
   pinVideo,
   contentType,
   bundleId,
   isMultipleSelect,
   onDeleteMessag,
   updateMessage,
   isMultipleAttachments,
   isDemoMode,
   updateMessageUnlockDetails,
}) {
   const [searchValue, setSearchValue] = useState(filters.search || '')
   const [filterType, setFilterType] = useState('')
   const [selectedId, setSelectedId] = useState('')
   const [isBulkEdit, setIsBulkEdit] = useState(false)
   const [selectedItems, setSelectedItems] = useState([])
   const [isSelectAll, setIsSelectAll] = useState(false)
   const [isSelectAllValue, setIsSelectAllValue] = useState(false)
   const [selectedCount, setSelectedCount] = useState(0)
   const [exceptIds, setExceptIds] = useState([])
   const [checkedItems, setCheckedItems] = useState([])
   const [checkedItemsIds, setCheckedItemsIds] = useState([])

   useEffect(() => {
      if(showCheckboxes) {
         onSelectThisVideo(checkedItems)
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [checkedItems]);

   useEffect(() => {
      if(selections.length) {
         setCheckedItems([...selections])
         let ids = selections.map(item => item.id)
         setCheckedItemsIds([...ids])
      }
   }, [selections])

   const [draggableItem, setDraggableItem] = useState(null)

   useEffect(() => {
      if(!isNewFetching && isSelectAll){
         let all = storyCard.reduce((acc, current) => {
            if(current){
               acc.push(current.id)
            }
            return acc
         }, [])
         setSelectedItems(all)
      }
   }, [storyCard, isNewFetching, isSelectAll]);

   const  onSelectVideos = (bool, id) => {
      const exist = Boolean(exceptIds.includes(id));
      let newExceptIds = [...exceptIds];
      let newSelectedItems = [...selectedItems];
      let count = selectedCount;
      let newSelectAllValue = isSelectAllValue;
      if(bool) {
         newSelectedItems = [...newSelectedItems, id];
         count = ++count;
         if(isSelectAll && exist){
            newExceptIds = [...newExceptIds].filter(i => i !== id)
         }
         newSelectAllValue = count === totalCount;
      } else {
         count = --count;
         newSelectedItems = [...newSelectedItems].filter(i => i !== id);
         if(isSelectAll && !exist){
            newExceptIds = [...newExceptIds, id];
         }
         newSelectAllValue = false;
      }
      setExceptIds(newExceptIds);
      setSelectedItems(newSelectedItems);
      setSelectedCount(count);
      setIsSelectAllValue(newSelectAllValue);
   }
   const onSelectAll = bool => {
      let all = []
      let count = 0
      if(bool) {
         count = totalCount;
         setExceptIds([]);
      }
      setSelectedCount(count)
      setIsSelectAll(bool)
      setIsSelectAllValue(bool)
      setSelectedItems(all)
   }

   const onCancelBulk = _ => {
      setSelectedCount(0)
      setIsSelectAll(false)
      setIsSelectAllValue(false)
      setSelectedItems([])
      setExceptIds([])
   }

   const onIsBulkEdit = bool => {
      // if(bool && !emptyFilters){
      //    setFilterType(searchValue);
      //    onClearAllFilters();
      // }
      setIsBulkEdit(bool);
   }

   const view = (
      <div
         id='videos-filters-container'
         className={ cx({
            'px-6 max-[700px]:px-4  videos-filtr-sorting': true,
            'media-list-header-inside-modal flex gap-3 flex-wrap !pr-2': isInsideModal,
            'flex w-full justify-between   max-lg:flex-col gap-2': !isInsideModal,
            // 'pb-6 !pr-2': (contentType === 'store' || isInsideModal) && screenWidth < 1025,
            'pb-6': contentType === 'store' && screenWidth < 1025 && isInsideModal,
            '!pb-6': contentType !== 'store' && isInsideModal,
         })
         }
      >
         <VideoFilterContent
            onFilterChange={ (...arg) => {
               setFilterType('filter');
               onFilterChange(...arg)
            } }
            resources={ resources }
            desktopType={ desktopType }
            onClearAllFilters={ () => {
               setFilterType(searchValue)
               onClearAllFilters()
            } }
            emptyFilters={ emptyFilters }
            isInsideModal={ isInsideModal }
            filtersData={ filters }
            isReorderMode={ isReorderMode }
            contentType={ contentType }
            onSearchChange={ (value) => {
               setSearchValue(value);
               setFilterType(value)
               onFilterChange(value, 'search')
            } }
            isMobile={ screenWidth <= 1024 }
            isMultipleAttachments={ isMultipleAttachments }
         />
      </div>
   )

   return (
      <>
         <div className={ cx({
            'adminDashboard__videosBox relative min-h-full flex pb-12 flex-col': true,
            'justify-center': isEmptyByFilter && !isFetchingByFilter && screenWidth > 1024,
         })
         }>
            {
               !isInsideModal && (

                  <div className='pb-4 px-6 max-[700px]:px-4 pt-8 max-[700px]:pt-6 flex mobile-padding lg:flex-row lg:items-center flex-col max-w-full gap-2'>
                     <div className='flex flex-1 md:flex-row gap-2 flex-col flex-wrap'>
                        {
                           allowAddContent && !isBulkEdit && (
                              <div className='h-11 auto-w-button'>
                                 <Button
                                    text='Add new video'
                                    backgroundColor='action'
                                    iconName='add'
                                    iconPosition='left'
                                    borderRadius='large'
                                    onClick={ onClickCreate }
                                    textClassName='ml-2'
                                    textMarginX='0'
                                    classNames='px-5 py-[10px]'
                                    disabled={ isReorderMode || isDemoMode }
                                    dataToolTipContent={ isDemoMode ? 'Not available in demo' : '' }
                                    textSize='base'
                                 />
                              </div>

                           )
                        }
                        <div
                           className={
                              cx({
                                 'flex': true,
                                 'w-full': isBulkEdit,
                              })
                           }
                        >
                           <BulkEditButton
                              isBulkEdit={ isBulkEdit }
                              onBulkEdit={ () => {
                                 onBulkEdit({
                                    ids: selectedItems,
                                    all: isSelectAll,
                                    completed: 1,
                                    count: selectedCount,
                                    excludedIds: isSelectAll ? exceptIds : [],
                                 }) } }
                              setIsBulkEdit={ (bool) => onIsBulkEdit(bool) }
                              onSelectAll={ onSelectAll }
                              isSelectAll={ isSelectAllValue }
                              selectedLength={ selectedCount }
                              type='video'
                              destroyMedais={ (callBack) => destroy({
                                 ids: selectedItems,
                                 all: isSelectAll,
                                 completed: 1,
                                 count: selectedCount,
                                 excludedIds: isSelectAll ? exceptIds : [],
                              }, callBack) }
                              setSelectedCount={ setSelectedCount }
                              destroyButtonDisabled={ destroyButtonDisabled }
                              totalCount={ totalCount }
                              onCancel={ onCancelBulk }
                              isReorderMode={ isReorderMode }
                              isDemoMode={ isDemoMode }
                           />
                        </div>
                        {
                           !isBulkEdit && <Button
                              text={ isReorderMode ? 'Done' : 'Reorder' }
                              onClick={ setReorderMode }
                              iconName={ isReorderMode ? '' : 'drag' }
                              iconPosition='left'
                              borderRadius='large'
                              backgroundColor={ isReorderMode ? '' : 'major-dark' }
                              textColor='major'
                              fontIconColor='secondary'
                              textSize='base'
                              classNames={ cx({
                                 'px-5 py-[10px] flex flex-row gap-2 border border-divider h-11 !w-[fit-content] whitespace-nowrap ': true,
                                 'hover:!bg-hover intercom-reorder-content': !isReorderMode,
                              }) }
                              textMarginX='0'
                              // iconClassName={ `${ isReorderMode ? '!rotate-90' : '' }` }
                              primaryColor={ isReorderMode }
                           />
                        }
                        {
                           isReorderMode &&
                              <Button
                                 text='Preview on my site'
                                 onClick={ onClickPreview }
                                 iconName='go-to'
                                 fontIconSize='extraLarge'
                                 iconPosition='left'
                                 borderRadius='large'
                                 backgroundColor='major-dark'
                                 textColor='major'
                                 fontIconColor='secondary'
                                 textSize='base'
                                 classNames='px-5 py-[10px] flex flex-row gap-2 border border-divider h-11 !max-w-[fit-content] hover:!bg-hover whitespace-nowrap'
                                 textMarginX='0'
                              />
                        }
                        {
                           !isBulkEdit && countState && (
                              <CountState
                                 state={ countState }
                                 type={ isReorderMode ? `published video${ storyCard?.length > 1 ? 's' : '' } can be reordered` : 'video' }
                                 className='whitespace-nowrap ml-0  max-[1024px]:justify-start mt-2 md:mt-auto pl-4'
                                 hideTooltip={ isReorderMode }
                              />
                           )
                        }
                     </div>
                     {
                        !isBulkEdit && (
                           <div
                              className='h-9 auto-w-button max-[1023px]:order-first'
                           >
                              <Button
                                 text='More info on videos'
                                 textSize='small'
                                 iconName='attention-outline'
                                 fontIconSize='base'
                                 iconPosition='left'
                                 backgroundColor='transparent'
                                 padding='none'
                                 classNames='h-full px-3 intercom-more-info-on-videos'
                                 primaryColor
                                 onClick={ onMoreInfoVideos }
                              />
                           </div>
                        )
                     }
                  </div>
               )
            }

            {
               !isBulkEdit && (
                  ['store', 'chat'].includes(contentType) && screenWidth > 1024 && !!document?.getElementById('videos-filter-content') ?
                     createPortal(view, document?.getElementById('videos-filter-content'))
                     : view

               )
            }
            {
               !isFetchingByFilter && !!storyCard?.length &&
               <ReorderingContent
                  contentType='videos'
                  onSortEnd={ onSortEnd }
                  setDraggableItem={ (value) => {
                     setDraggableItem(value)
                  } }
                  data={ storyCard }
                  isInsideModal={ isInsideModal }
                  screenWidth={ screenWidth }
               >
                  {
                     storyCard.map((data, index) => {
                        let selectedBulkEdit =  selectedItems.includes(data.id);
                        if(isSelectAll) selectedBulkEdit = !exceptIds.includes(data.id);

                        let tooltiptext = ''
                        if(data.is_future_publish_enabled) {
                           tooltiptext = 'scheduled'
                        } else if(!data.is_future_publish_enabled && !data.is_published) {
                           tooltiptext = 'unpublished'
                        }

                        let disabledCardForSelect = isInsideModal ? contentType === 'store' && data?.already_added_to_bundle_id !== null && data?.already_added_to_bundle_id !== bundleId : isReorderMode && !data?.is_published

                        return (
                           <SortableItem
                              key={ storyCard.id }
                              className='relative'
                              index={ index }
                              id={ storyCard.id }
                              isRotate={ draggableItem === index }
                              type='videos'
                              isReorderMode={ isReorderMode }
                              disabled={ !data?.is_published }
                              disabledState={ isReorderMode && !data?.is_published }
                              tooltiptext={ tooltiptext }
                           >
                              <div
                                 className={ cx({
                                 // ' videos-card-width': true,
                                    'opacity-50': !selectedBulkEdit && isBulkEdit,
                                    '!rotate-2 overflow-hidden bg-transparent': draggableItem === index,
                                 }) }
                                 key={ data && data.id }>
                                 {/* {
                                 data.is_pinned && (
                                    <PinLabel margin={ 4 } className='z-40 left-2 top-4' />
                                 )
                              } */}
                                 <VideoCard
                                    data={ data }
                                    points={ !isInsideModal }
                                    openDeleteConfirmModal={ () => openDeleteConfirmModal(data.id, !!data.attached_bundle_id, data.attached_bundle_name, data.attached_bundle_id) }
                                    desktopType={ desktopType }
                                    downloadOriginalFile={ downloadOriginalFile }
                                    isDownloading={ isDownloading }
                                    streamAccess={ data.stream_access ? data.stream_access[data.who_can_stream] : {} }
                                    isInsideModal={ isInsideModal }
                                    isSelected={ isMultipleAttachments ? selections.includes(data?.id) : selectedId === data.id }
                                    onSelectThisVideo={ async () => {
                                       const {
                                          poster_src,
                                          id,
                                          duration,
                                          basename,
                                          poster,
                                          locked_image_is_blur,
                                          title,
                                          extname,
                                          publish_date,
                                          messages,
                                       } = data
                                       if(showCheckboxes) {
                                          if(checkedItemsIds.includes(data.id)) {
                                             await setCheckedItems(prev => {
                                                let arr = prev.filter(item => item.id !== data.id)
                                                return arr
                                             })
                                             await setCheckedItemsIds(prev => {
                                                let arr = prev.filter(id => id !== data.id)
                                                return arr
                                             })
                                          } else {
                                             await setCheckedItems([...checkedItems, data])
                                             await setCheckedItemsIds([...checkedItemsIds, data.id])
                                          }

                                       } else {
                                          let messageStatus = !messages?.length ? 'unsent' : messages?.find(el => el.status === 'unlocked')?.status || messages[0]?.status
                                          setSelectedId(data.id)
                                          onSelectThisVideo({  poster_src, id, duration, basename, poster, locked_image_is_blur, title, extname, publish_date, messageStatus })
                                       }
                                    } }
                                    isBulkEdit={ isBulkEdit }
                                    isBulkEditSelected={ selectedBulkEdit }
                                    onSelectVideos={ bool => onSelectVideos(bool, data.id) }
                                    onClickDetailsLink={ onClickDetailsLink }
                                    siteUrl={ siteUrl }
                                    onClickOpenTips={ onClickOpenTips }
                                    onClickSetId={ onClickSetId }
                                    disabled={ disabledCardForSelect }
                                    showNewStyles={ showNewStyles }
                                    selectedItemsIds={ checkedItemsIds }
                                    showCheckboxes={ showCheckboxes }
                                    pinVideo={ (id, bool) => pinVideo(id, bool) }
                                    isPinned={ data?.is_pinned }
                                    isMultipleSelect={ isMultipleSelect }
                                    onDeleteMessag={ onDeleteMessag }
                                    updateMessage={ updateMessage }
                                    contentType={ contentType }
                                    isDemoMode={ isDemoMode }
                                    updateMessageUnlockDetails={ updateMessageUnlockDetails }
                                    isMultipleAttachments={ isMultipleAttachments }
                                    originalContentStorageSettingsRoute='MY_SITE_CONTENT_STORAGE'
                                 />
                              </div>
                           </SortableItem>

                        )
                     })
                  }
               </ReorderingContent>
            }
            {
               isFetchingByFilter && (
                  <div
                     className='flex w-full justify-center items-center flex-1'>
                     <CircleLoader />
                  </div>
               )
            }
            {
               isNewFetching && (
                  <div
                     className='flex w-full h-20 justify-center items-center'>
                     <CircleLoader />
                  </div>
               )
            }

            {
               isEmptyByFilter && !isFetchingByFilter && (
                  <div
                     style={ isEmptyByFilter ? { minHeight: isInsideModal ? '100%' : 'calc(100vh - 320px)' } : {} }
                     className='flex items-center w-full flex-1'>
                     <EmptyState
                        type={ filterType === 'filter' ? 'filter' : 'search' }
                        result={  filterType }
                     />
                  </div>
               )
            }
            {
               !isFetchingByFilter && !isEmptyByFilter && endList && (
                  <NoMoreResults className='pb-10 no_results mt-4 flex-1' />
               )
            }
            {/* {
               !isInsideModal && (
                  <div className='w-full fixed bottom-0 add-new-video-button'>
                     <Button
                        text='Add new video'
                        iconName='add'
                        iconPosition='left'
                        backgroundColor='action'
                        onClick={ onClickCreate }
                     />
                  </div>

               )
            } */}
         </div>
      </>
   );
}
Videos.propTypes = {
   storyCard: PropTypes.array,
   onClickCreate: PropTypes.func.isRequired,
   onFilterChange: PropTypes.func.isRequired,
   openDeleteConfirmModal: PropTypes.func.isRequired,
   onMoreInfoVideos: PropTypes.func.isRequired,
   resources: PropTypes.object,
   isNewFetching: PropTypes.bool,
   isFetchingByFilter: PropTypes.bool,
   isEmptyByFilter: PropTypes.bool,
   endList: PropTypes.bool,
   desktopType: PropTypes.string,
   onClearAllFilters: PropTypes.func,
   emptyFilters: PropTypes.bool,
   downloadOriginalFile: PropTypes.func,
   isDownloading: PropTypes.bool,
   isInsideModal: PropTypes.bool,
   onSelectThisVideo: PropTypes.func,
   onBulkEdit: PropTypes.func,
   filters: PropTypes.object,
   destroyButtonDisabled: PropTypes.bool,
   totalCount: PropTypes.number,
   destroy: PropTypes.func,
   onClickDetailsLink: PropTypes.func,
   siteUrl: PropTypes.string,
   onClickOpenTips: PropTypes.func,
   onClickSetId: PropTypes.func,
   allowAddContent: PropTypes.bool,
   countState: PropTypes.object,
   showNewStyles: PropTypes.bool,
   showCheckboxes: PropTypes.bool,
   selections: PropTypes.array,
   screenWidth: PropTypes.number,
   isReorderMode: PropTypes.bool,
   setReorderMode: PropTypes.func,
   onSortEnd: PropTypes.func,
   onClickPreview: PropTypes.func,
   pinVideo: PropTypes.func,
   contentType: PropTypes.string,
   bundleId: PropTypes.any,
   isMultipleSelect: PropTypes.bool,
   onDeleteMessag: PropTypes.func,
   updateMessage: PropTypes.func,
   isMultipleAttachments: PropTypes.bool,
   isDemoMode: PropTypes.object,
   updateMessageUnlockDetails: PropTypes.func,
}

Videos.defaultProps = {
   showNewStyles: false,
   showCheckboxes: false,
   selections: [],
   isReorderMode: false,
   onSortEnd: () => {},
   onClickPreview: () => {},
   contentType: '',
   bundleId: null,
   isMultipleSelect: true,
}

export default Videos;
