import * as actions from "./actions";

import {
   getPhotosets,
   getPhotosetsById,
   updatePhotosets,
   deletePhotosets,
   // createPhotos,
   // uploadPhotosetsImage,
   deletePhotos,
   createNewPhotosets,
   // photosMultipleActionsMove,
   reorderPhotosetsPhotos,
   getMemberships,
   photosMultipleActionsDelete,
   photosetsStatistic,
   photosetsStepValidate,
   destroyPhotosets,
   getByIdsPhotosets,
   multiplePhotosetsUpdate,
   getMassMessageLimit,
} from 'admin/api/AuthApi'
import Router from 'admin/routes/router';
import { push } from "connected-react-router";
import toast from "common/utils/toast";
import {
   fetchResourcesOperation,
   updateCountStateAfterDelete,
   fetchMediaCountsStateOperation,
} from 'admin/state/modules/resources/operations';

import {
   deletePhotosetsUploadCompleted,
   addOrUpdatePhotosetCompleted,
   destroyCompleted as  destroyUploading,
} from 'admin/state/modules/uploads/actions';
import { siteDetailsRefreshOperation } from 'admin/state/modules/common/operations';
import { setMassMessageLimit } from 'admin/state/modules/common/actions';
import { isEmpty } from 'lodash'


export function fetchPhotosetsOperation(query, key = '', emptyFilters = false) {
   return async dispatch => {
      dispatch(actions.fetchRequest(key));
      try {
         const { data }  = await getPhotosets(query, true);
         dispatch(actions.fetchRequestCompleted(data, emptyFilters, key))
      } catch (error) {
         // dispatch( fetchColorFailed(error) )
      }
   }
}
export function fetchDataByFilterOperation(query, key) {
   return async dispatch => {
      dispatch(actions.fetchByFilterRequest(key));
      try {
         const { data }  = await getPhotosets(query, true);
         dispatch(actions.fetchByFilterCompleted(data, key))
      } catch (error) {
         // dispatch( fetchColorFailed(error) )
      }
   }
}
export function fetchNewDataOperation(query, key) {
   return async dispatch => {
      dispatch(actions.fetchNewRequest(key));
      try {
         const { data }  = await getPhotosets(query, true);
         dispatch(actions.fetchNewCompleted(data, key))
      } catch (error) {
         dispatch(actions.fetchNewFailed(error))
      }
   }
}

export function fetchByIdOperation(id, photosData = [], callBack) {
   return async (dispatch) => {
      await dispatch(actions.fetchByIdRequest());
      try {
         await dispatch(fetchResourcesOperation('cast_members,tags'));
         let photos = {
            photos: photosData,
         };
         if(id) {
            const { data }  = await getPhotosetsById(id);
            let photoset = { ...data }
            photos = data.photos
            dispatch(actions.selectCoverPhotoCompleted(photos.cover_image || {}));
            dispatch(actions.fetchByIdCompleted(photoset))
            if(callBack) {
               callBack(data);
            }
         }
      } catch (error) {
         if(404 !== error?.response.status){
            dispatch(actions.fetchByIdFailed(error))
         }
      }
   }
}

export function updatePhotosetsDataOperation(data, id, step, bool, callBack) {
   return async (dispatch, getStore) => {
      dispatch(actions.updatePhotosetsRequest(bool));
      try {
         if(!isEmpty(data?.notify_members_on_publish || {}) || !isEmpty(data.pre_release_notify_members_on_publish || {})){
            const massMessageLimit =  await getMassMessageLimit();
            if(massMessageLimit?.data){
               dispatch(setMassMessageLimit({ ...massMessageLimit.data, openModal: massMessageLimit.data?.is_monthly_limit_reached, type: 'photoset' }))
            }
         }
      } catch (error) {

      }
      try {
         let { common: { siteInfo: { site_status } }, photosets: { filters, isInited } } = getStore()
         let sendData = { ...data }

         if(step === 'access_settings' && 'published_data' in data){
            const publishData = data.published_data
            delete data.published_data;
            sendData = data
            data = {
               ...data,
               ...publishData,
            }
         }
         if('cover_image' in data) {
            sendData = { cover: data.cover_image.id }
         }

         await updatePhotosets(sendData, id);
         const query = [];
         let allPhotosets = null
         if(isInited && (!step || step === 'publish')) {
            if(
               ('search' in filters && 'title' in data) ||
               ('is_published' in data || 'is_future_publish_enabled' in data) ||
               ('tags' in data && 'tags' in filters) ||
               ('casts' in data && 'casts' in filters) ||
               ('unpublished_duration' in data) ||
               ('duration_enabled' in data)
            ){
               Object.keys(filters).forEach((key) => {
                  if(key){
                     if(filters[key]) {
                        if('insideModal' !== key &&  key !== 'count' && key !== 'page' && key !== 'sortBy'){
                           query.push(`query[]=${ key }=${ filters[key] }`)
                        } else if(key === 'sortBy') {
                           query.push(`${ key }=${ filters[key] }`);
                        } else if(key === 'count') {
                           query.push(`count=${ +filters.page * 20 }`);
                        }
                     }
                  }
               });
               const { data } = await getPhotosets(query);
               allPhotosets = data.data
            } else {
               allPhotosets = data
            }
         }
         if(Boolean(callBack)) {
            const { photosets } = getStore()
            callBack({ ...photosets.activeData, ...data })
         }
         if(step && step === 'publish') {
            dispatch(deletePhotosetsUploadCompleted(id));
            dispatch(push(Router.route('PHOTOSETS').getCompiledPath()));
            if('live' !== site_status) {
               dispatch(siteDetailsRefreshOperation());
            }
            let text = 'Photoset has been published'
            if(!data.is_published && !data.is_future_publish_enabled) {
               text = 'Photoset has been saved unpublished'
            }
            if(data.is_future_publish_enabled) {
               text = 'Photoset has been scheduled'
            }
            toast.success(text)
         }

         if(!step) {
            if('cover_image' in data) {
               toast.remove('Cover has been changed')
            } else {
               if(!('duration_enabled' in data) || !data?.duration_enabled) toast.success('Your change has been saved!')
            }
         }

         if(!step && 'is_published' in data && data.is_published){
            data = {
               ...data,
               is_published_once: true,
            }
         }

         await dispatch(actions.updatePhotosetsCompleted({ data, step, allPhotosets }));
      } catch (error) {
         if(error.response) {
            dispatch(actions.updatePhotosetsFailed(error.response.data));
            if(error.response.data.casts){
               toast.error(step && step === 'cast_members' ? 'At least one cast member must be selected' : error.response.data.casts[0])
            }  else if(error.response.data.publish_date) {
               toast.error(error.response.data.publish_date[0])
            } else if(error.response.data.multiple_access) {
               toast.error(error.response.data.multiple_access[0])
            } else  {
               toast.error('Please fix errors below')
            }
         }
      }
   };
}

export function deletePhotosetsOperation(id, page, callback, emptyFilterParams = true) {
   return async (dispatch, getStore) => {
      dispatch(actions.deleteRequest());
      try {
         await deletePhotosets(id);
         let { common: { siteSteps: { site_status } }, photosets: { filters } } = getStore();
         if('live' !== site_status) {
            dispatch(siteDetailsRefreshOperation());
         }
         if(page !== 'upload'){
            dispatch(updateCountStateAfterDelete(id, 'photosets'));
            let isEmptyFilterParams = emptyFilterParams;
            if('edit' === page){
               let filtersParas = filters;
               if('count' in filtersParas){
                  delete filtersParas.count;
               }
               if('page' in filtersParas){
                  delete filtersParas.page;
               }
               isEmptyFilterParams = isEmpty(filtersParas);
            }
            dispatch(actions.deleteCompleted(id, isEmptyFilterParams));
         }
         if(!!page && page === 'list') {
            toast.remove('Photoset has been deleted')
         } else if(!!page && page === 'upload'){
            dispatch(deletePhotosetsUploadCompleted(id));
            toast.remove('Photoset has been deleted')
         } else {
            dispatch(push({
               pathname: Router.route('PHOTOSETS').getCompiledPath(),
               search: 'deleted=success',
            }
            ))
         }
      } catch (error) {
         if(error?.response?.status === 409) {
            const warningModal = deletePhotosetCallBack(error.response.data, dispatch);
            dispatch(actions.setWarningModal(warningModal));
         }
         dispatch(actions.updatePhotosetsFailed(error))
      }
   }
}
const deletePhotosetCallBack = (data) => {
   let title = 'Content connected to bundle';
   let buttonText = `Edit bundle${ data?.multiple_bundles ? 's' : '' }`;
   let buttonIconName = 'go-to';
   let cancelText = 'Ok';
   let secondaryButtonClassName = '';

   if('paid_for_unlock_or_download' === data.reason){
      title = 'Content cannot be deleted';
      buttonText = 'Ok';
      buttonIconName = null;
      cancelText = 'Contact support';
      secondaryButtonClassName = 'intercom-content-cannot-be-deleted';
   }

   const warningModal = {
      isOpen: true,
      description: data.message,
      ids: data.bundle_ids,
      multiple_bundles: data.multiple_bundles,
      reason: data.reason,
      buttonText,
      title,
      buttonIconName,
      cancelText,
      secondaryButtonClassName,
      // onCloseModal,
   }
   return warningModal;
}
// export function uploadPhotosOperation(data, id = null, index) {
//    return async dispatch => {
//       dispatch(actions.uploadPhotosRequest(index));
//       try {
//          const { data: { path } } =  await uploadPhotosetsImage(data);
//          const res = await createPhotos({
//             file_name: path,
//             photo_collection_id: id,
//          });
//          toast.remove('Photo has been added');
//          dispatch(actions.uploadPhotosCompleted(res.data));
//       } catch (error) {
//          dispatch(actions.uploadPhotosFailed(index))
//       }
//    }
// }

export function deletePhotosOperation(data) {
   return async dispatch => {
      try {
         if(data.id) {
            await deletePhotos(data.id)
         }
         dispatch(actions.savePhotosCompleted(data));
      } catch (error) {
         // dispatch( actions.updatePhotosetsFailed(error) )
      }
   }
}
export function deletePhotoOperation(data) {
   return async (dispatch, getState) => {
      try {
         const { photosets } = getState()
         const cover = photosets.activeData.cover_image
         if(cover.id === data.id && photosets.activeData) {
            const newCover =  photosets.activeData.photos.find(item => item.id !== cover.id)
            await updatePhotosets({ cover: newCover.id }, photosets.activeData.id)
            dispatch(actions.updatePhotosetsCompleted({ data: { cover_image: newCover } }));
         }
         if(data.id) {
            await deletePhotos(data.id)
         }
         dispatch(actions.deletePhotosCompleted(data));
         toast.remove('Photo has been deleted')

      } catch (error) {
         // dispatch( actions.updatePhotosetsFailed(error) )
      }
   }
}
export function removeDeletedPhotosOperation(data) {
   return async dispatch => {
      try {
         dispatch(actions.removeDeletedPhotosCompleted(data));
      } catch (error) {
         // dispatch( actions.updatePhotosetsFailed(error) )
      }
   }
}

export function reorderPhotosOperation(id = null, orderedData, data) {
   return async dispatch => {
      try {
         dispatch(actions.updatePhotosetsCompleted({ data: { photos: data } }))
         await reorderPhotosetsPhotos(id, { data: orderedData })
         toast.remove('Photos have been reordered');
      } catch (error) {
         // dispatch( actions.updatePhotosetsFailed(error) )
      }
   }
}

export function createNewOperation(data, photosData) {
   return async dispatch => {
      try {
         const { photos, cover, order, locked_image_is_blur } = data
         const response = await createNewPhotosets({ photos: photos, cover: cover, locked_image_is_blur })

         const id = response.data.id;
         let photoset = {
            photos: photosData,
            cover: response.data.cover,
            id,
         }
         if(order) {
            try {
               await reorderPhotosetsPhotos(id, { data: order })
            } catch (error) {

            }
         }
         dispatch(addOrUpdatePhotosetCompleted({ data: photoset }))
         dispatch(push(Router.route('PHOTOSETS_PUBLISH').getCompiledPath({ id })))
      } catch (error) {
         toast.error()
         if(error.response) {
            dispatch(actions.updatePhotosetsFailed(error.response.data))

         }
      }
   }
}
export function updatePhotosetsPhotoOperation(id, photos, cover, deletePhotosData) {
   return async dispatch => {
      try {
         if(deletePhotosData && deletePhotosData.length !== 0) {
            await photosMultipleActionsDelete({ data: deletePhotosData })
         }
         await dispatch(updatePhotosetsDataOperation({ cover: cover ? cover.id : 0 }, id))
         dispatch(actions.updatePhotosetsCompleted({ data: { cover_image: cover, photos } }))
         dispatch(actions.savePhotosCompleted(photos));
         // dispatch(push(Router.route('PHOTOSETS_SHOW').getCompiledPath({ id })))
      } catch (error) {
         // dispatch( actions.updatePhotosetsFailed(error) )
      }
   }
}

export function fetchAccessOperation() {
   return async dispatch => {
      dispatch(actions.customPricesRequest())
      try {
         const { data } = await getMemberships();
         let accessing = []
         const customPrices = data.map(i => {
            let item = {}
            item.pricing_name = i.name;
            item.pricing_id = i.id;
            item.credits_price = null;
            item.id = null;
            item.is_free = true;
            item.price = null;
            item.active_memberships_count = i.active_memberships_count;
            item.download_available = true;
            accessing.push(item.pricing_id)
            return item
         })
         dispatch(actions.customPricesCompleted({ customPrices, accessing }))
      } catch (error) {
         // dispatch( actions.updatePhotosetsFailed(error) )
      }
   }
}

export function fetchPhotosetsStatsOperation() {
   return async dispatch => {
      dispatch(actions.fetchStatsRequestStart());
      try {
         const { data }  = await photosetsStatistic();
         dispatch(actions.fetchStatsRequestCompleted(data))
      } catch (error) {
         // dispatch( fetchColorFailed(error) )
      }
   }
}

export function validatePhotosetsStepsOperation(currentTab, nextTab, data) {
   return async dispatch => {
      dispatch(actions.validateStepsRequestStart());
      try {
         await photosetsStepValidate(currentTab, data);
         dispatch(actions.validateStepsRequestCompleted(nextTab))
      } catch (error) {
         if(error.response) {
            if(error.response.data.casts){
               toast.error('At least one cast member must be selected')
            } else {
               toast.error('Please fix errors below')
            }
            dispatch(actions.validateStepsRequestFailed(error.response.data))
         }
      }
   }
}
export function destroyPhotosetsOperation(ids, callBack, params) {
   return async (dispatch) => {
      dispatch(actions.destroyRequest(true));
      try {
         let data = {}
         if(ids.all) {
            data = { count: 'all', completed: ids.completed, excludedIds: ids.excludedIds  }
         } else {
            data = { data: ids.ids }
         }
         await destroyPhotosets(data, params);
         await dispatch(fetchMediaCountsStateOperation('photosets', params))

         dispatch(actions.destroyCompleted({ ids: ids.all ? 'all' : ids.ids, hasFilter: !!params && params.length > 0, excludedIds: ids.excludedIds }));
         if(callBack) {
            callBack()
         }
         toast.remove('Photosets has been deleted')
      } catch (error) {
         if(error?.response?.status === 409) {
            const warningModal = deletePhotosetCallBack(error.response.data, dispatch);
            dispatch(actions.setWarningModal(warningModal));
         }
         dispatch(actions.destroyRequest(false));
      }
   };
}
export function getPhotosetsByIdOperations(query) {
   return async (dispatch) => {
      dispatch(actions.bulkPhotosetsRequest(true));
      try {
         const { data } = await getByIdsPhotosets(query);
         dispatch(actions.bulkPhotosetsCompleted(data));
         dispatch(fetchResourcesOperation());
      } catch (error) {
         dispatch(actions.bulkPhotosetsRequest(false));
      }
   };
}
export function multipleUpdateOperations(data, callBack) {
   return async (dispatch) => {
      dispatch(actions.multipleUpdateAction({ bool: true, errors: {} }));
      try {
         await multiplePhotosetsUpdate(data);
         dispatch(actions.multipleUpdateAction({ bool: false, errors: {} }));
         if(callBack) {
            callBack()
         } else {
            let ids = []
            data.photosets.forEach((el => {
               ids.push(el.id)
            }))
            if(data.data.completed) {

               dispatch(destroyUploading({ ids, type: 'photoset' }))
            }
            toast.success('Your changes have been saved')
            dispatch(push(Router.route('PHOTOSETS').getCompiledPath()))

         }
      } catch (error) {
         if(error.response) {
            const { data } = error.response || {}
            dispatch(actions.multipleUpdateAction({ bool: false, errors: data }));
            if(data['data.casts']){
               toast.error('At least one cast member must be selected')
            } else if(data['publish_date']) {
               toast.error(data['publish_date']?.[0])
            } else if(error.response.data.multiple_access) {
               toast.error(error.response.data.multiple_access[0])
            } else  {
               toast.error('Please fix errors below')
            }
         } else {
            toast.error('Something went wrong')
            dispatch(actions.multipleUpdateAction({ bool: false, errors: {} }));
         }
      }
   };
}

export function pinPhotosetOperations(id, bool){
   return async dispatch => {
      try {
         await updatePhotosets({ 'is_pinned': !bool }, id);

         toast.success(`Photoset has been ${ bool ? `unpinned` : `pinned` } `)
         dispatch(actions.pinPhotosetCompleted({ id, bool }));
      } catch (error) {
         toast.error('Something went wrong')
      }
   }
}
