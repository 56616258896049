import React from "react";
import VariableList from './variable-list';
import PropTypes from 'prop-types';
import { ToolbarButton, ToolbarSelect, ToolbarEmoji } from "./modules";

const colors = [
   '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
   '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
   '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
   '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
   'transparent',
   // 'color-picker',
];

const CustomToolbar = ({
   // toolbar,
   editor,
   insertText,
   variableList,
   cursorPosition,
   setCursorPosition,
   id,
   setOpenSourceCodeModal,
   // fontsList,
   // lineheightList,
   // sizeList,
   isMobile,
   showSourceCode,
   toolbarRef,
   isInited,
}) => {
   const TOOLBAR_LIST_BY_ORDER = [
      { header: [1, 2, false] },
      'bold',
      'italic',
      'underline',
      'link',
      { align: ['', 'center', 'right', 'justify'] },
      { list: 'bullet' },
      { list: 'ordered' },
      'background',
      'color',
      'source-code',
      // { lineheight: lineheightList },
      // { font: fontsList },
      // { size: sizeList },
      'variable',
      'emoji',
      'undo',
      'redo',
      'clean',
   ]
   // const toolbarRef = useRef(null);

   const addEmojiOnInput = (e) => {
      const sym = e.unified.split('-');
      const codesArray = [];
      sym.forEach(el => codesArray.push(`0x${ el }`));
      const emojiPic = String.fromCodePoint(...codesArray);
      if(insertText){
         insertText(emojiPic);
      }

   }
   const getToolbarMapProps = () => {
      return {
         addEmojiOnInput: addEmojiOnInput,
         cursorPosition: cursorPosition,
         editor: editor,
         setCursorPosition: setCursorPosition,
         variableList: variableList,
         insertText: insertText,
         setOpenSourceCodeModal: setOpenSourceCodeModal,
         id: id,
         isMobile: isMobile,
         isInited: isInited,
      }
   }

   const getToolBar = () => {
      let tools = [];
      if(isMobile){
         tools = [
            ...tools,
            'emoji',
         ]
      }
      if(!showSourceCode){
         tools = [
            ...tools,
            'source-code',
         ]
      }
      if(!tools.length) return TOOLBAR_LIST_BY_ORDER;
      return TOOLBAR_LIST_BY_ORDER.filter(el => !tools.includes(el))
   }
   return (
      <div
         ref={ toolbarRef }
         id={ id }
         className='ql-toolbar ql-snow flex-wrap w-full gap-2 z-[3] relative'>

         <ToolMap
            toolbar={ getToolBar() }
            { ...getToolbarMapProps() }
         />
      </div>
   )
}

const ToolMap = ({
   toolbar,
   addEmojiOnInput,
   cursorPosition,
   editor,
   setCursorPosition,
   variableList,
   insertText,
   setOpenSourceCodeModal,
   id,
   isMobile,
   isInited,
}) => {
   return (
      <>
         {
            toolbar.map((tool, index) => {
               if(!isInited){
                  return (
                     <div className='h-8 w-8 min-h-[32px] min-w-32px tool-loading-card'>  </div>
                  )
               }
               if('source-code' === tool){
                  return (
                     <ToolbarButton
                        key={ index.toString() }
                        tool='source-code'
                        editor={ editor }
                        onClick={ setOpenSourceCodeModal }
                     />
                  );
               }
               if('variable' === tool){
                  return (
                     <VariableList
                        key={ index.toString() }
                        variableList={ variableList }
                        selectVariable={ (variable) => {
                           let parentEl = document.createElement("p");
                           let el = document.createElement("span");
                           el.innerText = variable;
                           el.classList.add('editor-variable');
                           parentEl.appendChild(el)
                           insertText(variable, true)
                        } }
                        onClickOpen={ (variable) => {
                           if(cursorPosition === null){
                              let length = 0;
                              if(editor?.getLength){
                                 length = editor.getLength();
                              }
                              editor.setSelection(length - 1, 0);
                              setCursorPosition(length - 1);
                              editor.focus();
                           }
                        } }
                        name={ `${ id }_variable` }
                        disabled={ false }
                     />
                  );
               }
               if(!isMobile && 'emoji' === tool){
                  return (
                     <ToolbarEmoji
                        key={ index.toString() }
                        switchShowEmojis={ () => {
                           if(cursorPosition === null){
                              let length = 0;
                              if(editor?.getLength){
                                 length = editor.getLength();
                              }
                              editor.setSelection(length - 1, 0);
                              setCursorPosition(length - 1);
                              editor.focus();
                           }
                        } }
                        addEmoji={ addEmojiOnInput }
                        isMobile={ false }
                        id={ `${ id }_emoji` }

                     />
                  );
               }
               if('string' === typeof tool){
                  return (
                     <ToolBarItem tool={ tool } key={ index.toString() } editor={ editor } />
                  )
               }
               if('object' === typeof tool){
                  const keysArray = Object.keys(tool);
                  return (
                     <>
                        {
                           keysArray.map((key, index) => {
                              return (
                                 <ToolBarItem
                                    tool={ key }
                                    options={ tool[key] }
                                    key={ index.toString() }
                                    editor={ editor }
                                 />
                              )
                           })
                        }
                     </>
                  )
               }

               return null
            })
         }
      </>
   )
}

const ToolBarItem = ({
   tool,
   options,
   editor,
   className,
   ...rest
}) => {

   const getSelectOptions = () => {
      if('align' === tool && typeof options !== 'string' && options?.length) return options;
      if('header' === tool && typeof options !== 'string' && options?.length) return options;
      if(['font', 'size', 'lineheight'].includes(tool) && Array.isArray(options)) return options;
      if(['background', 'color'].includes(tool)) return colors;
      return null
   }
   if(getSelectOptions()){
      let defaultValueState = {};
      if('size' === tool){
         defaultValueState = { defaultValue: '16px' }
      }
      if('header' === tool){
         defaultValueState = { defaultValue: false }
      }
      return (
         <ToolbarSelect
            options={ getSelectOptions() }
            tool={ tool }
            className={ className }
            { ...defaultValueState }
         />
      )
   }

   let buttonState = {
      ...rest,
   }
   if('redo' === tool){
      buttonState = {
         disabled: !editor?.history?.stack?.redo?.length,
         onClick: () => {
            if(editor){
               editor.history.redo()
            }
         },
      }
   }
   if('undo' === tool){
      buttonState = {
         disabled: !editor?.history?.stack?.undo?.length,
         onClick: (e) => {
            if(editor){
               editor.history.undo()
            }
         },
      }
   }

   return (
      <ToolbarButton
         { ...buttonState }
         tool={ tool }
         options={ options }
         editor={ editor }
         className={ className }
      />
   )
}
ToolBarItem.propTypes = {
   keysArray: PropTypes.array,
   options: PropTypes.array,
   tool: PropTypes.string,
   editor: PropTypes.any,
   className: PropTypes.string,
}
ToolMap.propTypes = {
   editor: PropTypes.any,
   setCursorPosition: PropTypes.func,
   variableList: PropTypes.array,
   insertText: PropTypes.func,
   setOpenSourceCodeModal: PropTypes.func,
   id: PropTypes.string,
   toolbar: PropTypes.array,
   addEmojiOnInput: PropTypes.func,
   cursorPosition: PropTypes.func,
   isMobile: PropTypes.bool,
   isInited: PropTypes.bool,
}

CustomToolbar.propTypes = {
   toolbar: PropTypes.array,
   variableList: PropTypes.array,
   editor: PropTypes.any,
   setOpenSourceCodeModal: PropTypes.func,
   insertText: PropTypes.func,
   cursorPosition: PropTypes.number,
   setCursorPosition: PropTypes.func,
   id: PropTypes.string,
   showSourceCode: PropTypes.bool,
   isMobile: PropTypes.bool,
   isInited: PropTypes.bool,
   toolbarRef: PropTypes.any,
}
export default CustomToolbar
