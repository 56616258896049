
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

import NewMessageField from 'common/components/modules/chat/new-message-field';
import SpinnerAnimation from 'common/components/modules/spinner-animation';
import Message from './message'
import MuteMember from  'admin/views/chat/modules/mute-block-member/notification';
import DateFormatter from 'common/utils/DateFormatter';
import CircleLoader from 'common/components/elements/loader/circle';
import EmptyConversation from 'common/components/modules/chat/new-message/chat';
import Button from 'common/components/elements/buttons/primary';
import ChatHepler from 'common/utils/chat';
import Icon from 'common/components/elements/icons';
import cx from "classnames";
import { getCurrentLanguageByValue } from 'common/utils/utils';
import Router from 'admin/routes/router';
import SitesRouter from 'sites/routes/router';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import MultipleAttachmentModal from '../multiple-attachment-modal';
import Modal from '../../modal';
import ConfirmDialog from '../../confirm-dialog';
import { generateUriSegment } from 'common/utils/mediaRouteHelper';

class Dialogue extends Component {
   static propTypes = {
      isMobile: PropTypes.bool,
      authUser: PropTypes.object,
      messages: PropTypes.array,
      onClickUserAvatar: PropTypes.func,
      unlock: PropTypes.bool,
      mutedInfo: PropTypes.object,
      isAdmin: PropTypes.bool,
      onScroll: PropTypes.func,
      isNewFetching: PropTypes.bool,
      onFetchLikesInfo: PropTypes.func,
      onLikedMemberPagination: PropTypes.func,
      likesInfo: PropTypes.object,
      onUserTyping: PropTypes.func,
      typerText: PropTypes.string,
      onLikeMessage: PropTypes.func,
      onDeleteMessag: PropTypes.func,
      sendMessage: PropTypes.func,
      isEmpty: PropTypes.bool,
      converstionInfo: PropTypes.object,
      emptyState: PropTypes.object,
      unmuteMember: PropTypes.func,
      onReadMessage: PropTypes.func,
      lastSeen: PropTypes.string,
      diff: PropTypes.number,
      setMutedMemberInfo: PropTypes.func,
      isConnected: PropTypes.bool,
      adminChatSettings: PropTypes.object,
      goToChatSettings: PropTypes.func,
      unblockMember: PropTypes.func,
      closeChatPopup: PropTypes.func,
      memberCanOnlyRead: PropTypes.bool,
      selectFileByLiber: PropTypes.func,
      removeSelectedContent: PropTypes.func,
      deleteVaultVideo: PropTypes.func,
      onStream: PropTypes.func,
      onSaveUploadPhotos: PropTypes.func,
      goToContentMessage: PropTypes.func,
      needToJoin: PropTypes.bool,
      maxPrice: PropTypes.number,
      goToJoin: PropTypes.func,
      purchaseMessageId: PropTypes.number,
      paymentButtonLoading: PropTypes.bool,
      offLiveStreamChat: PropTypes.bool,
      isLivstream: PropTypes.bool,
      isMiniChat: PropTypes.bool,
      downloadFile: PropTypes.func,
      onSendVoiceMessage: PropTypes.func,
      adminUuid: PropTypes.string,
      privateAttachmentSettings: PropTypes.object,
      defaultVoicePrice: PropTypes.number,
      onUpdateRecordStatus: PropTypes.func,
      paymentButtonResourceId: PropTypes.string,
      handleUploadImage: PropTypes.func,
      cancelVideoUplaod: PropTypes.func,
      checkVaultByHash: PropTypes.func,
      createVault: PropTypes.func,
      checkContentTypeInTheChat: PropTypes.func,
      getMediaBlurhash: PropTypes.func,
      goTo: PropTypes.func,
      attachments: PropTypes.array,
      attachmentsForShow: PropTypes.array,
      onRemoveMedia: PropTypes.func,
      onClickCancel: PropTypes.func,
      isDemoMode: PropTypes.bool,
      mainMessageId: PropTypes.any,
      screenWidth: PropTypes.number,
      siteInfo: PropTypes.object,
      isMaxAttachmentCountReached: PropTypes.bool,
   };
   state = {
      confirmMod: '',
      selectedMessage: '',
      showCopy: false,
      showImageModal: false,
      startScroll: false,
      replyMessage: {},
      updateMessageType: '',
      setShowButton: false,
      keyH: 0,
      isMessageToLong: false,
      showAttachmentModal: false,
      currentMessage: {},
      openDeleteConfirmModal: false,
      attachmentStateForDelete: {},
      openDeleteAttachmentModal: false,
   };
   dialogueRef = React.createRef();
   inputRef = React.createRef();
   containerRef = React.createRef();
   timeout = ''
   needScrollChat = false
   calcDiffDay = []

   componentDidMount() {
      const { isMobile, converstionInfo } = this.props
      if(this.inputRef && this.inputRef.current && !isMobile) {
         this.inputRef.current.focus()
      }
      this.timeout = setTimeout(() => {
         this.setState({
            startScroll: true,
         })
         ChatHepler.syncDialogueScroll('smooth', converstionInfo?.chat_room_id)
      }, 500)
      if(this.chatContainerBox) {
         if(this.chatContainerBox.current) {
            this.chatContainerBox.current.scrollTop = this.chatContainerBox.current.scrollHeight - this.chatContainerBox.current.clientHeight;
         }
      }
   }
   componentWillUnmount(){
      const { isMobile } = this.props

      clearTimeout(this.timeout)
      if(this.inputRef && this.inputRef.current && !isMobile) {
         this.inputRef.current.blur()
      }
   }

   componentDidUpdate(){
      if(this.dialogueRef?.current) {
         this.dialogueRef.current.style.paddingRight = this.dialogueRef?.current?.offsetHeight < this.dialogueRef?.current?.scrollHeight ? '4px' : '16px'
      }
   }

   onClickLockContent = (type, isOpen) => {
   }
   onReplyMessage = replyMessage => {
      const { isMobile } = this.props
      this.setState({ replyMessage }, () => {
         if(this.inputRe && !isMobile) {
            this.inputRef.current.focus()
         }
      })
   }

   updateMessage = updateMessage => {
      const {
         messageId,
         text,
         updateMessageType,
      } = updateMessage
      this.setErrorToLong(false)
      this.updateMessagID(messageId, text, updateMessageType)
   }

   updateMessagID = (updateMessageId, text, updateMessageType) => {
      this.setState({
         updateMessageId,
         replyMessage: null,
         updateMessageType,
      },
      () => {
         if(this.inputRef && this.inputRef.current) {
            this.inputRef.current.focus()
            this.inputRef.current.innerText = '';
            window.document.execCommand('insertText', false, text);
         }
      })
   }


   onScrollToButton(behavior = 'smooth') {
      if(!this?.dialogueRef?.current){
         return
      }
      const { scrollHeight, offsetHeight, scrollTop } =  this.dialogueRef.current
      if(scrollTop === scrollHeight - offsetHeight) {
         return
      }
      let lastelmH = 32
      const { isMobile } = this.props

      if(this.dialogueRef && this.dialogueRef.current) {
         if(isMobile){
            lastelmH = this.dialogueRef.current.lastChild.offsetHeight
         } else {
            lastelmH = this.dialogueRef.current.lastChild.lastChild.offsetHeight
         }

      }
      const top = (scrollHeight - offsetHeight) + lastelmH + 16
      if(top && typeof top === 'number') {
         this.dialogueRef.current.scrollTo({
            top: (scrollHeight - offsetHeight) + lastelmH + 16,
            behavior: 'auto',
         })
      }
   }

   calcDays(date, callBack){
      const day = DateFormatter.diff(date)
      let res = null
      if(Math.round(day) < 1){
         res = 'Today'
      } else if(Math.round(day) < 2) {
         res = 'Yesterday'
      } else if(day >= 2){
         let format = 'MMMM DD'
         if(DateFormatter.convertToUserTzV2(date, 'YYYY') !==  DateFormatter.currentYears()){
            format = 'MMMM DD, YYYY'
         }
         res = DateFormatter.convertToUserTzV2(date, format)
      }
      if(callBack) {
         callBack(res)
      }
      return res

   }

   setShowButton = (bool) => {
      this.setState({
         setShowButton: bool,
      })
   }
   inputForIosFocus = (bool) => {
      const { userAgent } = window.navigator;
      const isIos = /iP(ad|od|hone)/i.test(userAgent)
      const { isMobile } = this.props
      if(!isMobile) {
         return
      }
      var isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(userAgent);
      if(!isIos && !isMacLike) {
         return
      }
   }

   noScroll = (e) => {
      e = e || window.event;
      if(e.preventDefault)
         e.preventDefault();
      e.returnValue = false;
      e.stopPropagation();
      window.scrollTo(0, 0);
   }

   setErrorToLong = bool => {
      this.setState({ isMessageToLong: bool })
   }


   getCanWrite = () => {
      let res = true;
      const { isAdmin, memberCanOnlyRead, adminChatSettings } = this.props
      if(!isAdmin) {
         res = !memberCanOnlyRead
      } else {
         if(adminChatSettings) {
            const { active, type, chatOff } = adminChatSettings;
            res = active
            if('private' === type) res = !chatOff
         }
      }
      return res
   }

   getMembershipStatus = () => {
      const { converstionInfo } = this.props
      let res = 'active'
      if(converstionInfo.member){
         res = converstionInfo.member.membership_status
         if((!res && !converstionInfo.member.subscriber) || converstionInfo.member.membership_status === 'incomplete') {
            res = 'guest'
         }
      }
      return res
   }

   translatedLanguage = () => {
      const { converstionInfo, isAdmin } = this.props
      let language = null;
      if(!!converstionInfo.isGroup) return null
      const memberlanguage = converstionInfo?.member?.translte_language || '';
      if(isAdmin && memberlanguage && 'en' !== memberlanguage.toLowerCase()){
         language = getCurrentLanguageByValue(memberlanguage, true);
         if('Select' === language){
            language = null;
         }
      }
      return language;

   }

   goToAttachmentDetails = (type, id, title, vaultData, currentAttachmentsMessage, isThereOneAttachment = false) => {
      const { isAdmin, goTo } = this.props
      const { showAttachmentModal } = this.state

      if(isAdmin) {
         if(type === 'video') {
            goTo(Router.route('VIDEOS_SHOW').getCompiledPath({ id: generateUriSegment(id, title) }))
         }
         if(type === 'photoset') {
            goTo(Router.route('PHOTOSETS_SHOW').getCompiledPath({ id: generateUriSegment(id, title) }))
         }
         if(type === 'video_vault') {
            goTo(Router.route('GET_VAULT_VIDEO').getCompiledPath({ id }))
         }
         if(type === 'photo_vault') {
            goTo(Router.route('VAULT').getCompiledPath())
         }

         if(!isThereOneAttachment) {
            this.setState({
               showAttachmentModal: !showAttachmentModal,
            })
         }

         return
      }

      if((!!Number(id) || (typeof id === 'string' && id.length === 32)) && ['video', 'photoset', 'photo_vault', 'video_vault'].includes(type)) {
         if(type === 'video') {
            if(currentAttachmentsMessage.unlock_details.unlock_price  === 0) {
               const link = SitesRouter.route('CHAT_UNLCOKED_CONTENT').getCompiledPath({ id: vaultData?.messageId })
               goTo(link + `?resourceId=${ id }&resourceType=${ type }`)
            } else {
               goTo(SitesRouter.route('VIDEOS_INDIVIDUAL').getCompiledPath({ id: generateUriSegment(id, title) }))
            }
         }
         if(type === 'photoset') {
            if(currentAttachmentsMessage.unlock_details.unlock_price  === 0) {
               const link = SitesRouter.route('CHAT_UNLCOKED_CONTENT').getCompiledPath({ id: vaultData?.messageId })
               goTo(link + `?resourceId=${ id }&resourceType=${ type }`)
            } else {
               goTo(SitesRouter.route('PHOTOSETS_INDIVIDUAL').getCompiledPath({ id: generateUriSegment(id, title) }))
            }
         }
         if(
            !!vaultData
            && vaultData.messageId
            && vaultData.pathHash
            && currentAttachmentsMessage && currentAttachmentsMessage.unlock_details
            && ((Number(currentAttachmentsMessage.unlock_details.unlock_price) > 0 && currentAttachmentsMessage.unlock_details.is_unlocked) || (Number(currentAttachmentsMessage.unlock_details.unlock_price) === 0 && !currentAttachmentsMessage.unlock_details.is_unlocked))
         ) {
            if(type === 'photo_vault' || type === 'video_vault') {
               const link = SitesRouter.route('CHAT_UNLCOKED_CONTENT').getCompiledPath({ id: vaultData?.messageId })
               goTo(link + `?hash=${ vaultData.pathHash }`)
            }
         }

         if(!isThereOneAttachment) {
            this.setState({
               showAttachmentModal: !showAttachmentModal,
            })
         }
      }
   }

   getContentLink = (content, status, singleAttachmentMessage = {}) => {
      const { isAdmin } = this.props
      const { currentMessage } = this.state

      const { resource_type: type, resource_id: id, title } = content

      let contentURL

      if(isAdmin) {
         if(type === 'video') {
            contentURL = Router.route('VIDEOS_SHOW').getCompiledPath({ id: generateUriSegment(id, title) })
         }
         if(type === 'photoset') {
            contentURL = Router.route('PHOTOSETS_SHOW').getCompiledPath({ id: generateUriSegment(id, title) })
         }
         if(type === 'video_vault') {
            contentURL = Router.route('GET_VAULT_VIDEO').getCompiledPath({ id })
         }
         if(type === 'photo_vault') {
            contentURL = Router.route('VAULT').getCompiledPath()
         }
         if(!(this.props?.authUser?.uuid === singleAttachmentMessage?.user?.uuid) && singleAttachmentMessage && singleAttachmentMessage?.type === 'photo_unlock') {
            contentURL = Router.route('CHAT_GET_CONTENT').getCompiledPath({ id: singleAttachmentMessage?.id })
         }

         return contentURL
      }

      if(
         (!!Number(id) || (typeof id === 'string' && id.length === 32))
         && ['video', 'photoset', 'photo_vault', 'video_vault'].includes(type)
      ) {
         const currentAttachmentsMessage = !!Object.keys(currentMessage).length ? currentMessage : singleAttachmentMessage
         const isSingleAttachment = !!Object.keys(singleAttachmentMessage)?.length
         const price = Number(currentAttachmentsMessage?.unlock_details?.unlock_price)
         const vaultData = {
            messageId: currentAttachmentsMessage?.id,
            pathHash: content?.resources?.[0].split('.')[0],
         }
         const contentLink = SitesRouter.route('CHAT_UNLCOKED_CONTENT').getCompiledPath({ id: vaultData?.messageId })
         const linkExtraInfo = `?resourceId=${ id }&resourceType=${ type }`
         const chatUnlockContentLink = contentLink + linkExtraInfo

         if(type === 'video') {
            if(
               (!!currentAttachmentsMessage?.unlock_details?.is_unlocked && price > 0)
               // || (price === 0 && !content?.publish_date)
               || (price === 0 && !isSingleAttachment)
               || (!!content.locked_image_is_blur && !!content.cover && !content?.publish_date)
               || (isSingleAttachment && ['is_free_unlock_content', 'is_hidden_content'].includes(status))
               || (isSingleAttachment && !status && !content?.publish_date)
               || (isSingleAttachment && !content?.publish_date && content?.is_unlocked_for_view)
            ) {
               contentURL = isSingleAttachment ? contentLink : chatUnlockContentLink
            } else {
               contentURL = SitesRouter.route('VIDEOS_INDIVIDUAL').getCompiledPath({ id: generateUriSegment(id, title) })
            }
         }

         if(type === 'photoset') {
            if(
               (!!currentAttachmentsMessage?.unlock_details?.is_unlocked && price > 0)
               // || (price === 0 && !content?.publish_date)
               || (price === 0 && !isSingleAttachment)
               || (!!content.locked_image_is_blur && !!content.cover && !content?.publish_date)
               || (isSingleAttachment && ['is_free_unlock_content', 'is_hidden_content'].includes(status))
               || (isSingleAttachment && !status && !content?.publish_date)
               || (isSingleAttachment && !content?.publish_date && content?.is_unlocked_for_view)
            ) {
               contentURL = isSingleAttachment ? contentLink : chatUnlockContentLink
            } else {
               contentURL = SitesRouter.route('PHOTOSETS_INDIVIDUAL').getCompiledPath({ id: generateUriSegment(id, title) })
            }
         }

         if(
            !!vaultData
            && vaultData.messageId
            && vaultData.pathHash
            && currentAttachmentsMessage && currentAttachmentsMessage?.unlock_details
            && ((!!status && status === 'locked_free_content') || (price > 0 && currentAttachmentsMessage?.unlock_details?.is_unlocked) || (price === 0))
            && (type === 'photo_vault' || type === 'video_vault')
         ) {
            // const link = SitesRouter.route('CHAT_UNLCOKED_CONTENT').getCompiledPath({ id: vaultData?.messageId })
            // goTo(link, `?hash=${ vaultData.pathHash }`)
            contentURL = `${ SitesRouter.route('CHAT_UNLCOKED_CONTENT').getCompiledPath({ id: vaultData?.messageId }) }?hash=${ vaultData.pathHash }`
         }

         return contentURL
      }
   }

   render() {
      const {
         authUser,
         isMobile,
         messages,
         onClickUserAvatar,
         unlock,
         isAdmin,
         onScroll,
         isNewFetching,
         onFetchLikesInfo,
         likesInfo,
         onLikedMemberPagination,
         onUserTyping,
         typerText,
         onLikeMessage,
         onDeleteMessag,
         sendMessage,
         isEmpty,
         converstionInfo,
         mutedInfo,
         unmuteMember,

         emptyState,
         onReadMessage,
         lastSeen,
         diff = 56,
         setMutedMemberInfo,
         isConnected,

         adminChatSettings,
         goToChatSettings,

         unblockMember,
         closeChatPopup,
         selectFileByLiber,
         removeSelectedContent,
         onStream,
         deleteVaultVideo,
         onSaveUploadPhotos,
         goToContentMessage,
         needToJoin,
         goToJoin,
         purchaseMessageId,
         paymentButtonLoading,
         maxPrice,
         offLiveStreamChat,
         isLivstream,
         isMiniChat,
         downloadFile,
         onSendVoiceMessage,
         adminUuid,
         privateAttachmentSettings,
         defaultVoicePrice,
         onUpdateRecordStatus,
         paymentButtonResourceId,
         handleUploadImage,
         cancelVideoUplaod,
         checkVaultByHash,
         createVault,
         checkContentTypeInTheChat,
         getMediaBlurhash,
         goTo,
         attachments,
         attachmentsForShow,
         onRemoveMedia,
         onClickCancel,
         isDemoMode,
         mainMessageId,
         screenWidth,
         siteInfo,
         isMaxAttachmentCountReached,
      } = this.props;
      const {
         startScroll,
         replyMessage,
         updateMessageId,
         setShowButton,
         keyH,
         isMessageToLong,
         showAttachmentModal,
         currentMessage,
         openDeleteConfirmModal,
         openDeleteAttachmentModal,
         attachmentStateForDelete,
         updateMessageType,
      } = this.state
      const showTypeField = this.getCanWrite();
      const userStatus = this.getMembershipStatus();

      const scrollToView = (
         this?.dialogueRef?.current && setShowButton && (
            <div
               className={ cx({
                  'w-8 h-8 z-50 absolute -top-8 left-[calc(50%-32px)] grid place-items-center group bg-major-20 rounded-full hover:bg-panel duration-300 cursor-pointer backdrop-blur-[10px]': true,
                  '-top-[180px]': !!isAdmin && !!adminChatSettings.type && (!!adminChatSettings.chatOff || !adminChatSettings.active),
               }) }
               role='presentation'
               onClick={ () => {
                  ChatHepler.syncDialogueScroll('smooth', converstionInfo.chat_room_id)
                  if(!isMobile) {
                     ChatHepler.inputState()
                  }
               } }
            >
               <Icon
                  name='downward'
                  size='xl'
                  className='text-major-dark group-hover:text-secondary'
               />
            </div>
         )
      )

      return (
         <>
            <div
               style={ isMobile ? {
                  height: `calc(100% - ${ keyH + diff }px)`,
               } : {
                  overflowY: 'hidden',
               } }
               className={ cx({
                  'flex flex-1 flex-col w-full bg-panel dark:bg-panel-dark chat-dialogue-content relative z-1': true,
                  'notranslate': !siteInfo?.chat_settings?.chat_auto_translator,
               }) }
               ref={ this.containerRef }
            >
               {
                  !!converstionInfo && !!converstionInfo.user_uuid && (
                     <>
                        {
                           converstionInfo.muted_since && converstionInfo.member && !converstionInfo.member.banned && (
                              <div
                                 className='w-full z-10 mb-px'>
                                 <MuteMember
                                    unmuteMember={ () => unmuteMember(converstionInfo.user_uuid) }
                                    info={
                                       {
                                          muted_period_in_seconds: converstionInfo.muted_period_in_seconds,
                                          muted_since: converstionInfo.muted_since,
                                       }

                                    }
                                 />
                              </div>
                           )
                        }
                        {
                           ((converstionInfo.member && !!converstionInfo.member.banned) || !!converstionInfo.user_banned) && (
                              <div
                                 className='w-full z-10'>
                                 <MuteMember
                                    type='block'
                                    unblockMember={ unblockMember }
                                    info={ {} }
                                 />
                              </div>
                           )
                        }
                     </>
                  )
               }
               {
                  isNewFetching && (
                     <>
                        <div className='py-4 mt-8 flex w-full items-center justify-center absolute z-20 top-0'>
                           <CircleLoader />
                        </div>
                     </>
                  )
               }
               {
                  document.getElementById('chat-input-field') && createPortal(
                     scrollToView,
                     document.getElementById('chat-input-field')
                  )
               }
               {
                  !isConnected && (
                     <div className='absolute left-0 w-full flex items-convertToUserTz'>
                        <div
                           style={ isMobile ?  {
                              maxWidth: isAdmin ? '351px' : '100%',
                           }  : {} }
                           className='no-internet-content flex items-center h-9 bg-warning dark:bg-warning-dark z-30 justify-center mx-auto lg:mt-5 mt-4'>
                           <div className='flex h-6 w-6 items-center justify-center mr-2'>
                              <Icon
                                 name='no-internet'
                                 color='major-dark'
                                 size='lg'
                              />
                           </div>
                           <span className='text-major-dark font-medium text-sm leading-none'> No internet connection</span>

                        </div>
                     </div>
                  )
               }
               <div
                  onScroll={ (e) => {
                     if(onScroll && startScroll) {
                        onScroll(e)
                     }
                     const {
                        scrollTop, scrollHeight, offsetHeight,
                     } = e.target
                     if(scrollTop < (scrollHeight - offsetHeight - 300) && !setShowButton) {
                        this.setShowButton(true)
                     }
                     if(scrollTop + 100 >= scrollHeight - offsetHeight && !!setShowButton){
                        this.setShowButton(false)
                     }
                  } }
                  ref={ this.dialogueRef }
                  id={ converstionInfo.chat_room_id ?  `messages-content-${ converstionInfo.chat_room_id }` : '' }
                  className={ cx({
                     "flex-1 overflow-y-auto overflow-x-hidden px-4 justify-between messages-content ams-custom-scrool relative z-2 !outline-none": true,
                     "pb-2": lastSeen,
                     "pt-16": !isEmpty,
                  }) }

               >
                  <div className={ `flex flex-1 flex-col relative justify-end ${ isEmpty ? 'h-full' : '' }` }>

                     {
                        updateMessageId && (
                           <div className='absolute top-0 left-0 z-10 opacity-50 bg-panel dark:bg-panel-dark w-full h-full' />
                        )
                     }
                     {
                        isEmpty && (
                           <EmptyConversation
                              data={ emptyState }
                           />
                        )
                     }
                     {
                        Array.isArray(messages) && messages.slice(0).reverse().map((message, index) => {

                           if(message.type && message.type.includes('promo_') && !message.promo_resource){
                              return null
                           }
                           let reverseMessages = messages.slice(0).reverse()
                           const user = message.user ? message.user : {}
                           const nextMessage = reverseMessages[index - 1] ? reverseMessages[index - 1] : {};
                           let restult = null
                           if(message.sent_at) {
                              this.calcDays(message.sent_at, (res) => {
                                 let bool = false
                                 let next = this.calcDays(nextMessage.sent_at)
                                 if(res === next) {
                                    bool = false
                                    restult = null
                                 } else {
                                    bool = true
                                    restult = res
                                 }
                                 return bool
                              })
                           }
                           const parent = message?.parentMessage || message?.parent;
                           return (
                              <Fragment key={ message.id }>
                                 {
                                    restult && !isNewFetching && (
                                       <span className='text-center text-placeholder text-sm my-4 dark:text-placeholder-dark'>
                                          {restult}
                                       </span>
                                    )
                                 }
                                 <Message
                                    unlock={ unlock }
                                    onClickUserAvatar={ onClickUserAvatar ? () => {
                                       onClickUserAvatar(message)
                                    } : null }
                                    isMyMessage={ authUser.uuid === user.uuid }
                                    isMobile={ isMobile }
                                    user={ user }
                                    message={ message }
                                    onClickLockContent={ () => this.onClickLockContent('pay', true) }
                                    nextIsMyMessage={ restult ? false : nextMessage.user ? user.uuid === nextMessage.user.uuid  : false }
                                    parent={ parent }
                                    onFetchLikesInfo={ () => onFetchLikesInfo(message.id) }
                                    onLikedMemberPagination={ (e) => onLikedMemberPagination(e, message.id) }
                                    likes={ likesInfo }
                                    onLikeMessage={ (isLiked) => {
                                       onLikeMessage(message.id, isLiked)
                                    } }
                                    onDeleteMessag={ () => {
                                       onDeleteMessag(message.id, () => {}, null, true, message)
                                    } }
                                    onReplyMessage={ this.onReplyMessage }
                                    updateMessage={ this.updateMessage }
                                    isUpdate={ updateMessageId === message.id }
                                    isGroup={ !converstionInfo.user_uuid }
                                    isAdmin={ isAdmin }
                                    closeChatPopup={ closeChatPopup }
                                    onStream={ (isParent = false) => {
                                       if(onStream) {
                                          let messageData = !isParent ?  { ...message } : { ...parent };
                                          if('voice_message' === messageData.type){
                                             messageData.type = 'voice_unlock';
                                          }
                                          onStream(messageData, message.id);
                                       }
                                    } }
                                    goToContentMessage={ goToContentMessage }
                                    paymentButtonLoading={ mainMessageId === purchaseMessageId &&  paymentButtonLoading && purchaseMessageId === message.id && paymentButtonResourceId && paymentButtonResourceId.includes(message.id) }
                                    isMiniChat={ isMiniChat }
                                    goToJoin={ (id, notifiaction) => {
                                       closeChatPopup()
                                       goToJoin(id, notifiaction)
                                    }  }
                                    downloadFile={ downloadFile }
                                    adminUuid={ adminUuid }
                                    lastElement={ index === messages.length - 1 }
                                    goToDetails={ () => {
                                       if(isAdmin && adminUuid !== user?.uuid && (isMiniChat || !converstionInfo.isGroup)) {
                                          return goTo(Router.route('MEMBERS_SHOW').getCompiledPath({ id: user?.id }))
                                       } else {
                                          return false
                                       }
                                    } }
                                    authUser={ authUser }
                                    replyMessage={ replyMessage }
                                    isLivstream={ isLivstream }
                                    onClickAttachment={ (message) => {
                                       const isThereOneAttachment = message?.unlock_details?.attachments?.length === 1
                                       const content = message?.unlock_details?.attachments?.[0]

                                       if(
                                          isThereOneAttachment &&
                                          (!!Number(content.resource_id) || (typeof content.resource_id === 'string' && content.resource_id.length === 32))
                                          && ['video', 'photoset', 'photo_vault', 'video_vault'].includes(content.resource_type)
                                          && (
                                             (
                                                ['video', 'photoset'].includes(content?.resource_type)
                                                  && message?.unlock_details?.attachmentsStatuses
                                                  && 'status' in message?.unlock_details?.attachmentsStatuses?.[0]
                                                  && message?.unlock_details?.attachmentsStatuses?.[0]?.status !== 'is_hidden_content'
                                             )
                                             ||
                                             (['photo_vault', 'video_vault', 'video', 'photoset'].includes(content.resource_type))
                                          )
                                       ) {
                                          this.goToAttachmentDetails(
                                             content.resource_type,
                                             content.resource_id,
                                             content.title,
                                             {
                                                messageId: message?.id,
                                                pathHash: content?.resources?.[0].split('.')[0],
                                             },
                                             messages?.find(it => it?.id === message?.id),
                                             true
                                          )
                                          return
                                       }

                                       this.setState({
                                          currentMessage: message,
                                          showAttachmentModal: true,
                                       })
                                    } }
                                    onRemoveMedia={ onRemoveMedia }
                                    screenWidth={ screenWidth }
                                    getContentLink={ this.getContentLink }
                                 />
                                 {
                                    index === messages.length - 1 && (
                                       <div
                                          style={ {
                                             minHeight: lastSeen ? '14px' : '4px',
                                          } }
                                          className='flex items-center justify-end mt-3'>
                                          {
                                             lastSeen &&  DateFormatter.checkIsValid(lastSeen)  && (
                                                <>
                                                   <div
                                                      style={ {
                                                         width: '15px',
                                                         height: '10px',
                                                         marginRight: '6px',
                                                      } }
                                                      className='flex items-center justify-center'
                                                   >
                                                      <Icon
                                                         name='views'
                                                         color='placeholder'
                                                         size='base'
                                                      />
                                                   </div>
                                                   <span
                                                      style={ {
                                                         lineHeight: '14px',
                                                      } }
                                                      className='text-placeholder text-sm dark:text-placeholder-dark'>
                                                      {DateFormatter.convertToUserTzV2(lastSeen, 'MMM DD, YYYY • hh:mm A')}
                                                   </span>
                                                </>
                                             )
                                          }
                                       </div>
                                    )
                                 }
                              </Fragment>
                           )

                        })
                     }
                     {
                        typerText && (
                           <div>
                              <SpinnerAnimation
                                 className='mt-2'
                                 text={ typerText }
                              />
                           </div>
                        )
                     }

                  </div>
               </div>
               {
                  adminChatSettings?.chat_auto_translator && this.translatedLanguage() && (
                     <div className='flex h-[18px] items-center justify-center m-2'>
                        <div className='flex items-center justify-center h-4 w-4 min-[16px] mr-2'>
                           <Icon
                              name='translate'
                              color='placeholder'
                              size='base'
                           />
                        </div>
                        <div className='flex items-center flex-row gap-3'>
                           <span className='text-xs text-placeholder leading-[18px]'>{converstionInfo.isGroup ? "Your messages will be translated according to the member's language preference" : `Your messages will be translated to ${ this.translatedLanguage() }`}</span>
                           <Icon
                              name='settings'
                              color='secondary'
                              size='base'
                              className='cursor-pointer'
                              onClick={ () => goTo(Router.route('CHAT_SETTINGS').getCompiledPath()) }
                              data-tooltip-id='ams-top-tooltip'
                              data-tooltip-content='Chat settings'
                           />
                        </div>
                     </div>
                  )
               }
               {
                  !!isAdmin && !!adminChatSettings.type && (!!adminChatSettings.chatOff || !adminChatSettings.active) && (
                     <div
                        className={ classNames({
                           'shadow dark:shadow-dark flex flex-col bg-panel dark:bg-panel-dark p-4': true,
                           'pb-0': showTypeField,
                        }) }
                     >
                        <span className='text-base font-bold text-major mt-1 dark:text-major-dark'>
                           {
                              'private' === adminChatSettings.type ?
                                 (adminChatSettings.chatOff ?  'Private chat is OFF ' : 'This member cannot reply') :
                                 `${ adminChatSettings.type.charAt(0).toUpperCase() + adminChatSettings.type.slice(1) } chat is OFF`
                           }

                        </span>
                        <span className='mt-1 text-secondary leading-tight dark:text-secondary-dark panel-description'>
                           {
                              'private' === adminChatSettings.type ?

                                 (adminChatSettings.chatOff
                                    ? 'You’ve turned off your private chat. Now it’s not visible on your site and you cannot send messages in private chat.' :
                                    `You have disabled sending private messages for ${ userStatus } members. They can see your messages but they cannot reply.`)
                                 :
                                 ' You’ve turned off your group chat. Now it’s not visible on your site and you cannot send messages in group chat.'
                           }
                        </span>
                        <div className='h-9 inline-flex mr-auto mt-4'>
                           <Button
                              text='Go to chat settings'
                              primaryColor
                              classNames='px-3 h-full'
                              padding='none'
                              textSize='small'
                              borderRadius='large'
                              fontWeight='medium'
                              onClick={ goToChatSettings }
                           />
                        </div>
                     </div>
                  )
               }
               {
                  !showTypeField && !isAdmin && (
                     <div
                        className='p-6 bg-panel dark:bg-panel-dark flex flex-col items-baseline dark:shadow-none not-avalable-shadow'>
                        {
                           needToJoin && (
                              <>
                                 <span className='leading-tight text-major dark:text-major-dark'> Only active members can reply to messages.</span>
                                 <div className='mt-4 h-11'>
                                    <Button
                                       borderRadius='large'
                                       primaryColor
                                       text='Get membership to chat'
                                       onClick={ () => {
                                          closeChatPopup()
                                          goToJoin()
                                       } }
                                       fontWeight='medium'
                                       textSize='base'
                                       classNames='h-full pl-[18px] pr-[10px]'
                                       iconName='membership'
                                       iconPosition='left'
                                       fontIconSize='extraLarge'
                                    />
                                 </div>

                              </>
                           )
                        }
                        {
                           !needToJoin && (
                              <span className='leading-tight text-major dark:text-major-dark'> {isLivstream ? 'Livestream chat is currently disabled.' : 'Private messaging is currently disabled.'}</span>

                           )
                        }
                     </div>
                  )
               }
               {
                  showTypeField && (
                     <NewMessageField
                        mutedInfo={ mutedInfo }
                        isMobile={ isMobile }
                        sendMessage={ (text, parnet, callBack) => {
                           if(sendMessage) {
                              sendMessage(text, parnet, updateMessageId, () => {
                                 if(updateMessageId) {
                                    this.updateMessagID(null)
                                 } else {
                                    this.onScrollToButton()
                                 }
                                 if(callBack) {
                                    callBack()
                                 }
                              }, { updateMessageType })
                           }
                        } }
                        isAdmin={ isAdmin }
                        onUserTyping={ () => {
                           if(updateMessageId) {
                              return
                           }
                           if(onUserTyping) {
                              onUserTyping()
                           }
                        }  }
                        replyMessage={ replyMessage }
                        canelReplyMessage={ () => this.onReplyMessage(null) }
                        inputRef={ this.inputRef }
                        onReadMessage={ onReadMessage }
                        inputForIosFocus={ this.inputForIosFocus }
                        setMutedMemberInfo={ setMutedMemberInfo }
                        isConnected={ isConnected && !offLiveStreamChat }
                        isMessageToLong={ isMessageToLong }
                        setErrorToLong={ this.setErrorToLong }
                        selectByLiber={ selectFileByLiber }
                        removeSelectedContent={ removeSelectedContent }
                        deleteVaultVideo={ deleteVaultVideo }
                        isGroup={ !converstionInfo.user_uuid  }
                        userUuid={ converstionInfo.user_uuid  }
                        content={ converstionInfo.content || null }
                        chatRoomId={ converstionInfo.chat_room_id || null }
                        onSaveUploadPhotos={ onSaveUploadPhotos }
                        maxPrice={ maxPrice }
                        isLivstream={ isLivstream }
                        onSendVoiceMessage={ (blob, details, callBack, parent) => {
                           onSendVoiceMessage(blob, details, () => {
                              if(callBack){
                                 callBack();
                              }
                              onUpdateRecordStatus();
                              this.onScrollToButton();
                           }, parent)
                        } }
                        privateAttachmentSettings={ privateAttachmentSettings }
                        defaultVoicePrice={ defaultVoicePrice }
                        onUpdateRecordStatus={ onUpdateRecordStatus }
                        handleUploadImage={ handleUploadImage }
                        cancelVideoUplaod={ cancelVideoUplaod }
                        checkVaultByHash={ checkVaultByHash }
                        createVault={ createVault }
                        checkContentTypeInTheChat={ checkContentTypeInTheChat }
                        getMediaBlurhash={ getMediaBlurhash }
                        containerRef={ this.containerRef }
                        isUpdateState={ !!updateMessageId }
                        onClickCancelEditMessage={ () => {
                           this.updateMessagID(null)
                        } }
                        isMiniChat={ isMiniChat }
                        showFrequentlyUsedEmojis={ isAdmin }
                        isDemoMode={ isDemoMode }
                        authUser={ authUser }
                        attachments={ attachments }
                        attachmentsForShow={ attachmentsForShow }
                        isMultipleAttachments={ isAdmin }
                        onRemoveMedia={ onRemoveMedia }
                        onClickCancel={ onClickCancel }
                        updateMessageType={ updateMessageType }
                        isMaxAttachmentCountReached={ isMaxAttachmentCountReached }
                     />

                  )
               }

            </div>
            {
               showAttachmentModal &&
                  <MultipleAttachmentModal
                     onClose={ () => {
                        this.setState({
                           showAttachmentModal: !showAttachmentModal,
                        })
                     } }
                     data={ currentMessage }
                     adminMessage={ isAdmin }
                     onRemoveMedia={ (media, index, message) => {
                        if(currentMessage?.unlock_details?.attachments?.length === 1 && index === 0 && !message?.text) {
                           this.setState({ openDeleteConfirmModal: true })
                        } else {
                           this.setState({
                              attachmentStateForDelete: { media, index, message, closeAfterUnsend: currentMessage?.unlock_details?.attachments?.length === 1 },
                              openDeleteAttachmentModal: true,
                           })
                        }
                     } }
                     isAdmin={ isAdmin }
                     goToDetails={ (type, id, title, vaultData) => {
                        this.goToAttachmentDetails(type, id, title, vaultData, currentMessage)
                     } }
                     siteInfo={ siteInfo }
                     onCloseChat={ () => {
                        this.setState({
                           showAttachmentModal: !showAttachmentModal,
                        })
                     } }
                     getContentLink={ this.getContentLink }
                  />
            }
            {
               isAdmin && openDeleteConfirmModal && (
                  <Modal
                     contentPosition={ screenWidth > 1024 ? 'center' : 'bottom' }
                     onClose={ () => this.setState({ openDeleteConfirmModal: false }) }
                     isCloseAction={ false }
                     contentWidth={ screenWidth > 1024 ? '375px' : '' }
                  >
                     <ConfirmDialog
                        modalInfo={ {
                           title: 'Unsend message?',
                           description: 'Are you sure you want to unsend this message? This cannot be undone.',
                           actionText: 'Yes, unsend',
                           action: () => {
                              onDeleteMessag(currentMessage.id, () => {
                                 this.setState({ openDeleteConfirmModal: false, showAttachmentModal: !showAttachmentModal })
                              }, null, null, currentMessage)
                           },
                        }  }
                        closeConfirmDialog={ () => this.setState({ openDeleteConfirmModal: false }) }
                        iconName='delete'
                     />

                  </Modal>
               )
            }
            {
               isAdmin && openDeleteAttachmentModal && (
                  <Modal
                     contentPosition={ screenWidth > 1024 ? 'center' : 'bottom' }
                     onClose={ () => this.setState({ openDeleteAttachmentModal: false, attachmentStateForDelete: {} }) }
                     isCloseAction={ false }
                     contentWidth={ screenWidth > 1024 ? '375px' : '' }
                  >
                     <ConfirmDialog
                        modalInfo={ {
                           title: `Unsend ${ ['photo_vault', 'video_vault']?.includes(attachmentStateForDelete?.media?.resource_type) ? attachmentStateForDelete?.media?.resource_type === 'photo_vault' ? 'vault image' : 'vault video' : attachmentStateForDelete?.media?.resource_type === 'video' ? 'video' : 'photoset' }?`,
                           description: 'Are you sure you want to unsend this single attachment? This cannot be undone.',
                           actionText: 'Yes, unsend',
                           action: () => {

                              const { media, index, message, closeAfterUnsend } = attachmentStateForDelete

                              onRemoveMedia(media, index, message, (updatedMessage) => {
                                 this.setState({
                                    currentMessage: updatedMessage,
                                    openDeleteAttachmentModal: false,
                                    attachmentStateForDelete: {},
                                    showAttachmentModal: closeAfterUnsend ? !showAttachmentModal : showAttachmentModal,
                                 })
                              }, true)
                           },
                        }  }
                        closeConfirmDialog={ () => this.setState({ openDeleteAttachmentModal: false, attachmentStateForDelete: {} }) }
                        iconName='delete'
                        className={ cx({
                           'notranslate': !siteInfo?.chat_settings?.chat_auto_translator,
                        }) }
                     />

                  </Modal>
               )
            }
         </>
      );
   }
}

export default Dialogue;

