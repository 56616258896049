import * as actions from "./actions";

import BackendMocker from 'admin/utils/BackendMocker';
import {
   getVideoById,
   updateVideo,
   fetchVideos,
   removeVideo as removeVideoApi,
   getByIdsVideos,
   destroyVideos,
   multipleVideosUpdate,
   createVideo,
   getMassMessageLimit,
}  from 'admin/api/AuthApi';
import Router from 'admin/routes/router';
import { push } from "connected-react-router";
import toast from "common/utils/toast";
import {
   fetchResourcesOperation,
   updateCountStateAfterDelete,
   fetchMediaCountsStateOperation,
} from 'admin/state/modules/resources/operations';
import { removeVideo, destroyCompleted as  destroyUploading } from 'admin/state/modules/uploads/actions';
import { siteDetailsRefreshOperation } from 'admin/state/modules/common/operations';
import { makeRandomString } from 'common/utils/utils';
import { init as BuildEvaporate } from 'common/utils/evaporate';
import { updateOrAddVideo } from 'admin/state/modules/uploads/actions';
import { setMassMessageLimit } from 'admin/state/modules/common/actions';
import { isEmpty } from 'lodash'

const VideosMocker = new BackendMocker('videos');

export function fetchVideosOperation(query = ['count=20'], key, bool) {
   return async (dispatch) => {
      dispatch(actions.fetchRequest(key));
      try {
         dispatch(fetchResourcesOperation());
         const { data } = await fetchVideos(query);
         dispatch(actions.fetchRequestCompleted(data, bool, key));

      } catch (error) {
         // dispatch(actions);
      }
   };
}
export function fetchVideosByFilterOperation(params = [], key) {
   return async (dispatch) => {
      dispatch(actions.fetchByFilterRequest(key));
      try {
         const { data } = await fetchVideos(params);
         dispatch(actions.fetchByFilterRequestCompleted(data, key));

      } catch (error) {
         // dispatch(actions);
      }
   };
}
export function videosNextPageOperation(params = [], key) {
   return async (dispatch) => {
      dispatch(actions.nextPageRequest(key));
      try {
         const { data } = await fetchVideos(params);
         dispatch(actions.nextPageRequestCompleted(data, key));

      } catch (error) {
         // dispatch(actions);
      }
   };
}

export function fetchNotCompletedVideos(params = {}) {
   return dispatch => {
      dispatch(actions.fetchNotCompletedRequest());
      VideosMocker.index(params).then(res => {
         const { data } = res;
         dispatch(actions.fetchNotCompletedCompleted(data))
      }).catch(err => { // eslint-disable-line
      });
   }
}

export function fetchVideoByIdOperation(id, videoType, callBack) {
   return async (dispatch) => {
      dispatch(actions.fetchVideoByIdRequest());
      try {
         await dispatch(fetchResourcesOperation());

         let query = '';
         if(videoType) {
            query = `?video_type=${ videoType }`
         }
         const { data } = await getVideoById(id, query);
         if('livestream' === videoType && (!data.completed || data.has_livestream_error)){
            dispatch(push(Router.route('LIVE_STREAM').getCompiledPath()));
            return
         }
         let video = { ...data }

         dispatch(actions.fetchVideoByIdCompleted(video));
         if(callBack){
            callBack(video);
         }
      } catch (error) {
         let backRouteName = 'VIDEOS'
         if('livestream' === videoType){
            backRouteName = 'LIVE_STREAM'
         }
         dispatch(push(Router.route(backRouteName).getCompiledPath()))
         if(404 !== error?.response.status){
            dispatch(actions.fetchVideoByIdFailed(error.response));
         }
      }
   };
}



export function updateVideoOperation(id, video, step, bool, callback, videoType) {
   return async (dispatch, getStore) => {
      dispatch(actions.updateVideoRequest(bool));
      try {
         if(!isEmpty(video?.notify_members_on_publish || {}) || !isEmpty(video.pre_release_notify_members_on_publish || {})){
            const massMessageLimit =  await getMassMessageLimit();
            if(massMessageLimit?.data){
               dispatch(setMassMessageLimit({ ...massMessageLimit.data, openModal: massMessageLimit.data?.is_monthly_limit_reached }))
            }
         }
      } catch (error) {

      }
      try {
         let { common: { siteInfo: { site_status } }, videos: { filters, isInited } } = getStore();
         let sendData = { ...video };
         if(step === 'access_settings' && 'published_data' in video){
            const publishData = video.published_data
            delete video.published_data;
            sendData = video
            video = {
               ...video,
               ...publishData,
            }
         }
         let apiQuery = '';
         if(videoType) {
            apiQuery = `?video_type=${ videoType }`
         }
         await updateVideo(id, sendData, apiQuery);
         const query = [];
         let allVideos = null;
         if(isInited && (!step || step === 'publish')) {
            if(
               ('search' in filters && 'title' in video) ||
               ('is_published' in video || 'is_future_publish_enabled' in video) ||
               ('tags' in video && 'tags' in filters) ||
               ('casts' in video && 'casts' in filters) ||
               ('collections' in video && 'collections' in filters) ||
               ('unpublished_duration' in video) ||
               ('duration_enabled' in video)
            ){
               Object.keys(filters).forEach((key) => {
                  if(key){
                     if(filters[key]) {
                        if('insideModal' !== key &&  key !== 'count' && key !== 'page' && key !== 'sortBy'){
                           query.push(`query[]=${ key }=${ filters[key] }`)
                        } else if(key === 'sortBy') {
                           query.push(`${ key }=${ filters[key] }`);
                        } else if(key === 'count') {
                           query.push(`count=${ +filters.page * 20 }`);
                        }
                     }
                  }
               });
               const { data } = await fetchVideos(query);
               allVideos = data.data
            } else {
               allVideos = video
            }
         }
         if(Boolean(callback)) {
            const { videos: { fetchVideoById } } = getStore()
            callback({ ...fetchVideoById, ...video })
         }
         if(step && step === 'publish') {
            dispatch(removeVideo(id));
            let backRouteName = 'VIDEOS'
            if('livestream' === videoType){
               backRouteName = 'LIVE_STREAM'
            }
            dispatch(push(Router.route(backRouteName).getCompiledPath()));

            if('live' !== site_status) {
               dispatch(siteDetailsRefreshOperation());
            }
            let text = 'Video has been published'
            if(!video.is_published && !video.is_future_publish_enabled) {
               text = 'Video has been saved unpublished'
            }
            if(video.is_future_publish_enabled) {
               text = 'Video has been scheduled'
            }
            toast.success(text)
         }
         if(!step && (!('duration_enabled' in video) || !video?.duration_enabled)) {
            toast.success('Your change has been saved!')
         }
         if(!step && 'is_published' in video && video.is_published){
            video = {
               ...video,
               is_published_once: true,
            }
         }
         dispatch(actions.updateVideoCompleted({ video, step, allVideos }));

      } catch (error) {
         if(error.response) {
            dispatch(actions.updateVideoFailed(error.response.data));
            if(error.response.data.casts){
               toast.error(step && step === 'cast_members' ? 'Select cast members to continue' : error.response.data.casts[0])
            }  else if(error.response.data.publish_date) {
               toast.error(error.response.data.publish_date[0])
            } else if(error.response.data.multiple_access) {
               toast.error(error.response.data.multiple_access[0])
            } else  {
               toast.error('Please fix errors below')
            }
         }
      }
   };
}
export function deleteVideoOperation(id, action, videoType, callback, emptyFilterParams = true) {
   return async (dispatch, getStore) => {
      dispatch(actions.deleteVideoRequest());
      try {
         let apiQuery = '';
         if(videoType) {
            apiQuery = `?video_type=${ videoType }`
         }
         await removeVideoApi(id, apiQuery);
         let { common: { siteSteps: { site_status } }, videos: { filters } } = getStore()
         if('live' !== site_status) {
            dispatch(siteDetailsRefreshOperation());
         }
         dispatch(updateCountStateAfterDelete(id, 'videos'));
         let isEmptyFilterParams = emptyFilterParams;
         if('edit' === action){
            let filtersParas = filters;
            if('count' in filtersParas){
               delete filtersParas.count;
            }
            if('page' in filtersParas){
               delete filtersParas.page;
            }
            isEmptyFilterParams = isEmpty(filtersParas);
         }
         dispatch(actions.deleteVideoCompleted(id, isEmptyFilterParams));
         if(action !== 'list') {
            let backRouteName = 'VIDEOS'
            if('livestream' === videoType){
               backRouteName = 'LIVE_STREAM'
            }
            dispatch(push(Router.route(backRouteName).getCompiledPath()))
         }
         toast.remove('Video has been deleted')
      } catch (error) {
         if(error?.response?.status === 409) {
            const warningModal = deleteVideoCallBack(error.response.data, dispatch);
            dispatch(actions.setWarningModal(warningModal));
         }
         dispatch(actions.deleteVideoFailed(error.response));
      }
   };
}
const deleteVideoCallBack = (data) => {
   let title = 'Content connected to bundle';
   let buttonText = `Edit bundle${ data?.multiple_bundles ? 's' : '' }`;
   let buttonIconName = 'go-to';
   let cancelText = 'Ok';
   let secondaryButtonClassName = '';

   if('paid_for_unlock_or_download' === data.reason){
      title = 'Content cannot be deleted';
      buttonText = 'Ok';
      buttonIconName = null;
      cancelText = 'Contact support';
      secondaryButtonClassName = 'intercom-content-cannot-be-deleted';
   }

   const warningModal = {
      isOpen: true,
      description: data.message,
      ids: data.bundle_ids,
      multiple_bundles: data.multiple_bundles,
      reason: data.reason,
      buttonText,
      title,
      buttonIconName,
      cancelText,
      secondaryButtonClassName,
      // onCloseModal,
   }
   return warningModal;
}

export function destroyVideosOperation(ids, callBack, q) {
   return async (dispatch) => {
      dispatch(actions.destroyVideosRequest(true));
      try {
         let data = {}
         if(ids.all) {
            data = { count: 'all', completed: ids.completed, excludedIds: ids.excludedIds }
         } else {
            data = { data: ids.ids }
         }
         await destroyVideos(data, q);
         await dispatch(fetchMediaCountsStateOperation('videos', q))
         dispatch(actions.destroyVideosCompleted({ ids: ids.all ? 'all' : ids.ids, hasFilter: !!q && q.length > 0, excludedIds: ids.excludedIds }));
         if(callBack) {
            callBack()
         }
         toast.remove('Videos has been deleted')
      } catch (error) {
         if(error?.response?.status === 409) {
            const warningModal = deleteVideoCallBack(error.response.data, dispatch);
            dispatch(actions.setWarningModal(warningModal));
         }
         dispatch(actions.destroyVideosRequest(false));
      }
   };
}
export function getVideosByIdOperations(query) {
   return async (dispatch) => {
      dispatch(actions.bulkVideosRequest(true));
      try {
         const { data } = await getByIdsVideos(query);
         dispatch(actions.bulkVideosCompleted(data));
         dispatch(fetchResourcesOperation());
      } catch (error) {
         dispatch(actions.bulkVideosRequest(false));
      }
   };
}
export function multipleVideosUpdateOperations(data, callBack) {
   return async (dispatch) => {
      dispatch(actions.multipleVideosUpdateAction({ bool: true, errors: {} }));
      try {
         await multipleVideosUpdate(data);
         dispatch(actions.multipleVideosUpdateAction({ bool: false, errors: {} }));
         if(callBack) {
            callBack()
         } else {
            let ids = []
            data.videos.forEach((el => {
               ids.push(el.id)
            }))
            if(data.data.completed) {
               dispatch(destroyUploading({ ids, type: 'video' }))
            }
            toast.success('Your changes have been saved')
            dispatch(push(Router.route('VIDEOS').getCompiledPath()))
         }
      } catch (error) {
         if(error.response) {
            const { data } = error.response || {}
            dispatch(actions.multipleVideosUpdateAction({ bool: false, errors: data }));
            if(data['data.casts']){
               toast.error('At least one cast member must be selected')
            } else if(data['publish_date']) {
               toast.error(data['publish_date']?.[0])
            } else if(error.response.data.multiple_access) {
               toast.error(error.response.data.multiple_access[0])
            } else  {
               toast.error('Please fix errors below')
            }
         } else {
            toast.error('Something went wrong.')
            dispatch(actions.multipleVideosUpdateAction({ bool: false, errors: {} }));
         }
      }
   };
}

export const cancelUplaodOperation = (originalVideoId, callBack) => {
   return async (dispatch, getState) => {
      const { videos: { replaceState } } = getState()
      const data = replaceState?.[originalVideoId];
      if(!data) return
      switch (data?.uploadingStatus) {
         case 'uploading':
            dispatch(updateReplaceStateOperation(originalVideoId, { isFetchCancel: true }));
            BuildEvaporate().then((evaporate) => {
               const { uploadInfo: { uploadPath } } = data;
               const bucket = process.env.REACT_APP_AWS_S3_BUCKET;
               evaporate.cancel(`${ bucket }/${ uploadPath }`).then(() => {
                  console.log('Canceld BuildEvaporate cancel api')
                  dispatch(updateReplaceStateOperation(data.originalVideoId, null));
                  toast.success('Uploading has been cancelled')
                  if(callBack){
                     callBack();
                  }

               }).catch((error) => {
                  console.log(error, 'Cancel BuildEvaporate error cancel api')
                  dispatch(updateReplaceStateOperation(data.originalVideoId, null));
                  if(callBack){
                     callBack();
                  }

               })
            });
            break;
         case 'error':
         case 'in_optimization_queue':
         case 'optimizing':
            if(data.id !== originalVideoId){
               dispatch(updateReplaceStateOperation(originalVideoId, { isFetchCancel: true }));
               try {
                  await removeVideoApi(data.id)
                  dispatch(updateReplaceStateOperation(originalVideoId, null));
                  if(callBack){
                     callBack();
                  }

               } catch (error) {
                  toast.error('Something went wrong');
                  dispatch(updateReplaceStateOperation(originalVideoId, { isFetchCancel: false }));

               }
            } else {
               console.log('wrong id')
            }
            break;
         default:
            dispatch(updateReplaceStateOperation(originalVideoId, null));
            break;
      }
   }
}


export function replaceVideoOperation(file, originalVideoId) {
   return (dispatch, getState) => {
      if(file){
         const { name } = file;
         const extension = name.substr(name.lastIndexOf('.') + 1).toLowerCase();
         const newFileRandomName = makeRandomString(32);
         const newFileName = `${ newFileRandomName }.${ extension }`;
         const uploadPath = `videos/${ newFileRandomName }/${ newFileName }`;
         const candidate = {
            uploadingStatus: 'pending',
            title: file.name.split('.').slice(0, -1).join('.'),
            originalVideoId,
            uploadInfo: {
               file,
               uploadPath,
               extension,
               newFileRandomName,
               newFileName,
            },
         };
         dispatch(updateReplaceStateOperation(originalVideoId, { ...candidate }));
      }
      const { videos: { replaceState } } = getState()
      let replacedVideo = replaceState?.[originalVideoId] || [];
      BuildEvaporate().then((evaporate) => {
         evaporate.add({
            file: replacedVideo.uploadInfo.file,
            name: replacedVideo.uploadInfo.uploadPath,
            progress: (percent, stats) => {
               const formattedPercent = Math.round(percent * 100);
               const info = {
                  percentage: formattedPercent,
               };
               dispatch(updateReplaceStateOperation(originalVideoId, info));
            },
            error: (mssg) => console.log('Error BuildEvaporate', mssg),
            paused: () => console.log('Paused BuildEvaporate'),
            pausing: () => console.log('Pausing BuildEvaporate'),
            resumed: () => console.log('Resumed BuildEvaporate'),
            cancelled: () => {
               console.log('Cancelled BuildEvaporate')
            },
            started: (fileKey) => {
               const info = {
                  uploadingStatus: 'uploading',
               };
               dispatch(updateReplaceStateOperation(originalVideoId, info));
            },
            uploadInitiated: (s3Id) => {},
            warn: (mssg) => console.log('Warning BuildEvaporate', mssg),
         }).then(
            (awsObjectKey) => {
               // Back update api start TO DOO
               dispatch(updateReplaceStateOperation(originalVideoId, { isFetchCancel: true }));
               const data = {
                  title: replacedVideo.title,
                  extname: replacedVideo.uploadInfo.extension,
                  basename: replacedVideo.uploadInfo.newFileRandomName,
                  replacement_id: replacedVideo.originalVideoId,
               }
               setTimeout(async () => {
                  const info = await repliceCurrentVideoVideo(data);
                  dispatch(updateReplaceStateOperation(originalVideoId, info));
               }, 5000)
               // Back update api end TO DOO
            },
            (reason) => {
               console.log('File did not upload sucessfully:', reason)
            }
         );
      });
   };
}

async function repliceCurrentVideoVideo(body) {
   try {
      const { data } =  await createVideo(body);
      const {
         v2_optimization_status,
         id,
      } = data;
      const errorStatuses = [
         'mediainfo_error',
         'optimization_error',
         'other_error',
      ];
      let status = null;
      if(errorStatuses.includes(v2_optimization_status)) {
         status = 'error'
      } else if('in_optimization_queue' === v2_optimization_status) {
         status = 'in_optimization_queue';
      } else if('optimizing' === v2_optimization_status) {
         status = 'optimizing';
      }
      const info = {
         uploadingStatus: status,
         id,
         isFetchCancel: false,
      };
      return Promise.resolve(info);
   } catch (error) {
      console.log('File did not upload sucessfully:', error)
      return Promise.reject(error);
   }
}

export function updateReplaceStateOperation(id, data) {
   return (dispatch, getState) => {
      try {
         const { videos: { videosData, fetchVideoById }, uploads: { videos } } = getState();
         let originalVideoUpdateInputs = null;
         if(data){
            if('ready' === data?.uploadingStatus){
               let posterImages = data?.posterImages || {}
               const oldPosterImages = fetchVideoById?.poster_images || {};
               let csutomThumbnail = {};
               for(const [key, value] of Object.entries(oldPosterImages)) {
                  if(key.includes('custom-')){
                     csutomThumbnail = { ...csutomThumbnail, [key]: value }
                  }
               }
               if(csutomThumbnail){
                  posterImages = { ...posterImages, ...csutomThumbnail }
               }
               originalVideoUpdateInputs = {
                  basename: data?.basename,
                  hls_manifest: data?.hlsManifest,
                  poster_images: posterImages,
                  duration: data?.duration,
               }
               const oldPoster = fetchVideoById?.poster || '';
               if(!oldPoster.includes('custom-')){
                  originalVideoUpdateInputs = {
                     ...originalVideoUpdateInputs,
                     poster: data?.poster,
                     poster_src: data?.posterImage,
                     posterImage: data?.posterImage,
                  }
               }
               toast.success('Your video has been replaced');
            }
         } else {
            originalVideoUpdateInputs = {
               replacing_id: null,
               v2_optimization_status: null,
            }
         }
         if(originalVideoUpdateInputs && 'ready' === data?.uploadingStatus){
            const videoList = videosData?.data || [];
            const uploadsList = videos || [];
            const existInVideoList = [...videoList].find(item => item.id === id);
            const existInUploadsList = [...uploadsList].find(item => item.id === id);
            if(existInVideoList){
               dispatch(actions.updateVideoOnVideosList(id, originalVideoUpdateInputs))
            }
            if(existInUploadsList){
               dispatch(updateOrAddVideo(id, originalVideoUpdateInputs))
            }
         }
         dispatch(actions.setReplaceStateAction(id, data, originalVideoUpdateInputs))
      } catch (error) {
         // dispatch(actions);
      }
   };
}

export function pinVideoOperations(id, bool){
   return async dispatch => {
      try {
         await updateVideo(id, { 'is_pinned': !bool }, '');

         toast.success(`Video has been ${ bool ? `unpinned` : `pinned` } `)
         dispatch(actions.pinVideoCompleted({ id, bool }));
      } catch (error) {
      }
   }
}
