import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './style.scss'
import PhotosetCard from 'common/components/modules/photoset-card';
import Button from 'common/components/elements/buttons/primary';
import CircleLoader from 'common/components/elements/loader/circle';
import NoMoreResults from 'common/components/modules/no-more-results';
import EmptyState from 'common/components/modules/empty-states';
import PhotosetFilterContent from './filter-content';

import BulkEditButton from 'admin/views/media-modules/bulk-edit-button';
import cx from 'classnames';
import CountState from 'admin/views/media-modules/count-state';
import ReorderingContent, { SortableItem } from 'admin/views/reorder-content';
import { createPortal } from 'react-dom';
// import PinLabel from 'common/components/modules/pin-label';

function Photosets({
   data,
   onFilterChange,
   isEmpty,
   endList,
   goToAddNew,
   resources,
   desktopType,
   isNewFetching,
   isFetchingByFilter,
   openDeleteConfirmModal,
   onMoreInfoPhotosets,
   onClearAllFilters,
   emptyFilters,
   downloadOriginalFile,
   isDownloading,
   isInsideModal,
   onSelectThisPhotoset,
   onBulkEdit,
   filters,
   destroyButtonDisabled,
   destroy,
   totalCount,
   onClickDetailsLink,
   siteUrl,
   onClickOpenTips,
   onClickSetId,
   allowAddContent,
   countState,
   showNewStyles,
   showCheckboxes,
   selections,
   screenWidth,
   isReorderMode,
   setReorderMode,
   onSortEnd,
   onClickPreview,
   pinPhotoset,
   contentType,
   bundleId,
   isMultipleSelect,
   onDeleteMessag,
   updateMessage,
   isMultipleAttachments,
   isDemoMode,
   updateMessageUnlockDetails,
}) {
   const [searchValue, setSearchValue] = useState(filters.search || '')
   const [filterType, setFilterType] = useState('')
   const [selectedId, setSelectedId] = useState(null)
   const [isBulkEdit, setIsBulkEdit] = useState(false)
   const [selectedItems, setSelectedItems] = useState([])
   const [isSelectAll, setIsSelectAll] = useState(false)
   const [selectedCount, setSelectedCount] = useState(0)
   const [exceptIds, setExceptIds] = useState([])
   const [isSelectAllValue, setIsSelectAllValue] = useState(false)
   const [checkedItems, setCheckedItems] = useState([])
   const [checkedItemsIds, setCheckedItemsIds] = useState([])
   const [draggableItem, setDraggableItem] = useState(null)

   useEffect(() => {
      if(showCheckboxes) {
         onSelectThisPhotoset(checkedItems)
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [checkedItems]);

   useEffect(() => {
      if(selections.length) {
         // console.log(selections);
         setCheckedItems([...selections])
         let ids = selections.map(item => item.id)
         setCheckedItemsIds([...ids])
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {
      if(isSelectAll && !isNewFetching) {
         let all = data.reduce((acc, current) => {
            if(current){
               acc.push(current.id)
            }
            return acc
         }, [])
         setSelectedItems(all)
      }
   }, [data, isSelectAll, isNewFetching]);


   const  onSelectPhotoset = (bool, id) => {
      const exist = Boolean(exceptIds.includes(id));
      let newExceptIds = [...exceptIds];
      let newSelectedItems = [...selectedItems];
      let count = selectedCount;
      let newSelectAllValue = isSelectAllValue;
      if(bool) {
         newSelectedItems = [...newSelectedItems, id];
         count = ++count;
         if(isSelectAll && exist){
            newExceptIds = [...newExceptIds].filter(i => i !== id)
         }
         newSelectAllValue = count === totalCount;
      } else {
         count = --count;
         newSelectedItems = [...newSelectedItems].filter(i => i !== id);
         if(isSelectAll && !exist){
            newExceptIds = [...newExceptIds, id];
         }
         newSelectAllValue = false;
      }
      setExceptIds(newExceptIds);
      setSelectedItems(newSelectedItems);
      setSelectedCount(count);
      setIsSelectAllValue(newSelectAllValue);

   }
   const onSelectAll = bool => {
      let all = []
      let count = 0
      if(bool) {
         count = totalCount;
         setExceptIds([]);
      }
      setSelectedCount(count)
      setIsSelectAll(bool)
      setIsSelectAllValue(bool)
      setSelectedItems(all)
   }

   const onCancelBulk = _ => {
      setSelectedCount(0)
      setIsSelectAll(false)
      setIsSelectAllValue(false)
      setSelectedItems([])
      setExceptIds([])
   }
   const onIsBulkEdit = bool => {
      // if(bool && !emptyFilters){
      //    setFilterType(searchValue);
      //    onClearAllFilters();
      // }
      setIsBulkEdit(bool);
   }

   const view = (
      <div
         id='photosets-filters-container'
         className={ cx({
            'flex justify-between media-list-header-inside-modal sm:px-6 px-4': true,
            'pb-6 !pr-0': contentType === 'store' && screenWidth < 1025,
            'pb-6 !pr-2': isInsideModal && contentType !== 'store',

         }) }
      >
         <div className='potosets-filtr-sorting z-20 flex-1 gap-4'>
            <PhotosetFilterContent
               onFilterChange={ (...arg) => {
                  setFilterType('filter');
                  onFilterChange(...arg)
               } }
               resources={ resources }
               desktopType={ desktopType }
               onClearAllFilters={ () => {
                  setFilterType(searchValue)
                  onClearAllFilters()
               }  }
               emptyFilters={ emptyFilters }
               isInsideModal={ isInsideModal }
               showNewStyles={ showNewStyles }
               contentType={ contentType }
               isMobile={ screenWidth <= 1024 }
               onSearchChange={ (value) => {
                  setSearchValue(value);
                  setFilterType(value);
                  onFilterChange(value, 'search')
               } }
               filtersData={ filters }
               isMultipleAttachments={ isMultipleAttachments }
            />
         </div>
      </div>
   )

   return (
      <>
         <div
            style={ {
               zIndex: 9,
            } }
            className={ cx({
               'relative photosets-list-container flex flex-col min-h-full': true,
               'justify-center': isEmpty && !isFetchingByFilter && screenWidth > 1024,
            }) }>
            {
               isInsideModal && <>
                  {
                     ['store', 'chat'].includes(contentType) && screenWidth > 1024 && !!document.getElementById('photosets-filter-content') ?
                        createPortal(view, document.getElementById('photosets-filter-content'))
                        : view
                  }
               </>
            }
            {
               !isInsideModal && (
                  <>
                     <div className='pb-4 px-6 max-[700px]:px-4 pt-8 max-[700px]:pt-6  flex lg:items-center lg:flex-row flex-col max-w-full gap-2'>
                        <div className='flex flex-1 flex-wrap flex-row max-md:flex-col gap-2'>
                           {
                              allowAddContent && !isBulkEdit && (
                                 <div className='auto-w-button'>
                                    <Button
                                       text='Add new photoset'
                                       backgroundColor='action'
                                       iconName='add'
                                       iconPosition='left'
                                       borderRadius='large'
                                       onClick={ goToAddNew }
                                       classNames='px-5 py-2.5 h-11'
                                       textMarginX='0'
                                       textClassName='ml-2'
                                       disabled={ isReorderMode || isDemoMode }
                                       textSize='base'
                                       dataToolTipContent={ isDemoMode ? 'Not available in demo' : '' }
                                    />
                                 </div>

                              )
                           }
                           <div className={ `flex ${ isBulkEdit ? 'w-full' : '' }` }>
                              <BulkEditButton
                                 isBulkEdit={ isBulkEdit }
                                 onBulkEdit={ () => onBulkEdit({
                                    ids: selectedItems,
                                    all: isSelectAll,
                                    completed: 1,
                                    count: selectedCount,
                                    excludedIds: isSelectAll ? exceptIds : [],

                                 }) }
                                 setIsBulkEdit={ onIsBulkEdit }
                                 onSelectAll={ onSelectAll }
                                 isSelectAll={ isSelectAllValue }
                                 selectedLength={ selectedCount }
                                 type='photoset'
                                 destroyMedais={ (callBack) => destroy({
                                    ids: selectedItems,
                                    all: isSelectAll,
                                    completed: 1,
                                    count: selectedCount,
                                    excludedIds: isSelectAll ? exceptIds : [],
                                 }, callBack) }
                                 destroyButtonDisabled={ destroyButtonDisabled }
                                 totalCount={ totalCount }
                                 onCancel={ onCancelBulk }
                                 isReorderMode={ isReorderMode }
                                 isDemoMode={ isDemoMode }
                              />
                           </div>
                           {
                              !isBulkEdit && <Button
                                 text={ isReorderMode ? 'Done' : 'Reorder' }
                                 onClick={ setReorderMode }
                                 iconName={ isReorderMode ? '' : 'drag' }
                                 iconPosition='left'
                                 borderRadius='large'
                                 backgroundColor={ isReorderMode ? '' : 'major-dark' }
                                 textColor='major'
                                 fontIconColor='secondary'
                                 textSize='base'
                                 classNames={ cx({
                                    'px-5 py-[10px] flex flex-row gap-2 border border-divider h-11 !w-[fit-content] whitespace-nowrap ': true,
                                    'hover:!bg-hover intercom-reorder-content': !isReorderMode,
                                 }) }
                                 textMarginX='0'
                                 primaryColor={ isReorderMode }
                                 // iconClassName={ `${ isReorderMode ? '!rotate-90' : '' }` }
                              />
                           }
                           {
                              isReorderMode &&
                                 <Button
                                    text='Preview on my site'
                                    onClick={ onClickPreview }
                                    iconName='go-to'
                                    iconPosition='left'
                                    borderRadius='large'
                                    backgroundColor='major-dark'
                                    textColor='major'
                                    fontIconColor='secondary'
                                    textSize='base'
                                    classNames='px-5 py-[10px] flex flex-row gap-2 border border-divider h-11 !max-w-[fit-content] hover:!bg-hover whitespace-nowrap'
                                    textMarginX='0'
                                 />
                           }
                           {
                              !isBulkEdit &&  countState && (
                                 <CountState
                                    state={ countState }
                                    type={ isReorderMode ? `published photoset${ data?.length > 1 ? 's' : '' } can be reordered` : 'photoset' }
                                    className='whitespace-nowrap ml-0 max-[1024px]:justify-start mt-2 md:mt-auto pl-4'
                                    hideTooltip={ isReorderMode }
                                 />
                              )
                           }
                        </div>
                        {
                           !isBulkEdit && (
                              <div
                                 className='h-9 auto-w-button max-[1023px]:order-first'
                              >
                                 <Button
                                    text='More info on photosets'
                                    textSize='small'
                                    iconName='attention-outline'
                                    fontIconSize='base'
                                    iconPosition='left'
                                    backgroundColor='transparent'
                                    padding='none'
                                    classNames='h-full w-auto'
                                    primaryColor
                                    onClick={ onMoreInfoPhotosets }
                                 />
                              </div>
                           )
                        }
                     </div>
                     {
                        !isBulkEdit && (
                           <div className='flex w-full justify-between md:flex-row flex-col md:flex-wrap flex-nowrap px-6 max-[700px]:px-4 gap-y-2 gap-x-3'>
                              <div className='potosets-filtr-sorting z-20 flex-1 gap-4'>
                                 <PhotosetFilterContent
                                    onFilterChange={ (...arg) => {
                                       setFilterType('filter');
                                       onFilterChange(...arg)
                                    } }
                                    resources={ resources }
                                    desktopType={ desktopType }
                                    onClearAllFilters={ () => {
                                       setFilterType(searchValue)
                                       onClearAllFilters()
                                    }  }
                                    emptyFilters={ emptyFilters }
                                    filtersData={ filters }
                                    isReorderMode={ isReorderMode }
                                    isMobile={ screenWidth <= 1024 }
                                    onSearchChange={ (value) => {
                                       setSearchValue(value);
                                       setFilterType(value);
                                       onFilterChange(value, 'search')
                                    } }
                                 />
                              </div>
                           </div>
                        )
                     }
                  </>
               )
            }
            {
               !isFetchingByFilter && !!data?.length &&
               <ReorderingContent
                  contentType='photosets'
                  onSortEnd={ onSortEnd }
                  setDraggableItem={ (value) => {
                     setDraggableItem(value)
                  } }
                  data={ data }
                  isInsideModal={ isInsideModal }
                  screenWidth={ screenWidth }
               >
                  {
                     data.map((data, index) => {
                        let selectedBulkEdit =  selectedItems.includes(data.id);
                        if(isSelectAll) selectedBulkEdit = !exceptIds.includes(data.id);

                        let tooltiptext = ''
                        if(data.is_future_publish_enabled) {
                           tooltiptext = 'scheduled'
                        } else if(!data.is_future_publish_enabled && !data.is_published) {
                           tooltiptext = 'unpublished'
                        }

                        let disabledCardForSelect = isInsideModal ? contentType === 'store' && data?.already_added_to_bundle_id !== null && data?.already_added_to_bundle_id !== bundleId : isReorderMode && !data?.is_published
                        return (
                           <SortableItem
                              key={ data.id }
                              className='relative'
                              index={ index }
                              id={ data.id }
                              isRotate={ draggableItem === index }
                              type='photosets'
                              isReorderMode={ isReorderMode }
                              disabled={ !data?.is_published }
                              disabledState={ isReorderMode && !data?.is_published }
                              tooltiptext={ tooltiptext }
                           >
                              <div
                                 className={ cx({
                                 // 'photosets-card-width': true,
                                    'opacity-50': !selectedBulkEdit && isBulkEdit,
                                    '!rotate-2 overflow-hidden bg-transparent': draggableItem === index,
                                 }) }
                                 key={ data && data.id }>
                                 {/* {
                                 data?.is_pinned && (
                                    <PinLabel margin={ 4 } className='z-40 left-2 top-4' />
                                 )
                              } */}
                                 <PhotosetCard
                                    desktopType={ desktopType }
                                    openDeleteConfirmModal={ () => {
                                       openDeleteConfirmModal(data.id, !!data?.attached_bundle_id, data?.attached_bundle_name, data?.attached_bundle_id)
                                    } }
                                    downloadOriginalFile={ downloadOriginalFile }
                                    isDownloading={ isDownloading }
                                    data={ data }
                                    points={ !isInsideModal }
                                    isInsideModal={ isInsideModal }
                                    isSelected={ isMultipleAttachments ? selections.includes(data.id) : selectedId === data.id }
                                    onSelectThisPhotoset={ async () => {
                                       const {
                                          cover_photo,
                                          id,
                                          photos_count,
                                          photos_src,
                                          locked_image_is_blur,
                                          title,
                                          publish_date,
                                          messages,
                                       } = data
                                       if(showCheckboxes) {
                                          if(checkedItemsIds.includes(data.id)) {
                                             await setCheckedItems(prev => {
                                                let arr = prev.filter(item => item.id !== data.id)
                                                return arr
                                             })
                                             await setCheckedItemsIds(prev => {
                                                let arr = prev.filter(id => id !== data.id)
                                                return arr
                                             })
                                          } else {
                                             await setCheckedItems([...checkedItems, data])
                                             await setCheckedItemsIds([...checkedItemsIds, data.id])
                                          }

                                       } else {
                                          let messageStatus = !messages?.length ? 'unsent' : messages?.find(el => el.status === 'unlocked')?.status || messages[0]?.status
                                          setSelectedId(data.id)
                                          onSelectThisPhotoset({  cover_photo, id, photos_count, photos_src, locked_image_is_blur, title, publish_date, messageStatus })
                                       }

                                    } }
                                    isBulkEdit={ isBulkEdit }
                                    isBulkEditSelected={ selectedBulkEdit }
                                    onSelectPhotosets={ bool => onSelectPhotoset(bool, data.id) }
                                    onClickDetailsLink={ onClickDetailsLink }
                                    siteUrl={ siteUrl }
                                    onClickOpenTips={ onClickOpenTips }
                                    onClickSetId={ onClickSetId }
                                    disabled={ disabledCardForSelect }
                                    showNewStyles={ showNewStyles }
                                    selectedItemsIds={ checkedItemsIds }
                                    showCheckboxes={ showCheckboxes }
                                    pinPhotoset={ (id, bool) => pinPhotoset(id, bool) }
                                    isPinned={ data?.is_pinned }
                                    isMultipleSelect={ isMultipleSelect }
                                    onDeleteMessag={ onDeleteMessag }
                                    updateMessage={ updateMessage }
                                    contentType={ contentType }
                                    isDemoMode={ isDemoMode }
                                    updateMessageUnlockDetails={ updateMessageUnlockDetails }
                                    isMultipleAttachments={ isMultipleAttachments }
                                 />
                              </div>
                           </SortableItem>
                        )
                     })
                  }
               </ReorderingContent>
            }
            {
               isFetchingByFilter && (
                  <div
                     className='flex w-full justify-center items-center flex-1'>
                     <CircleLoader />
                  </div>
               )
            }
            {
               isNewFetching && (
                  <div
                     className='flex w-full h-20 justify-center items-center'>
                     <CircleLoader />
                  </div>
               )
            }
            {
               !isFetchingByFilter && !isEmpty && endList && (
                  <NoMoreResults className='pb-10 no_results mt-4' />
               )
            }
            {
               isEmpty && !isFetchingByFilter && (
                  <div
                     style={ isEmpty ? { minHeight: isInsideModal ? '100%' : 'calc(100vh - 320px)' } : {} }
                     className='flex items-center w-full flex-1'>
                     <EmptyState
                        type={ filterType === 'filter' ? 'filter' : 'search' }
                        result={  filterType }
                     />
                  </div>
               )
            }
         </div>
      </>
   );
}

Photosets.propTypes = {
   data: PropTypes.array,
   onFilterChange: PropTypes.func,
   isEmpty: PropTypes.bool,
   endList: PropTypes.bool,
   isFetchingByFilter: PropTypes.bool,
   goToAddNew: PropTypes.func,
   resources: PropTypes.object,
   desktopType: PropTypes.string,
   isNewFetching: PropTypes.bool,
   openDeleteConfirmModal: PropTypes.func,
   onMoreInfoPhotosets: PropTypes.func,
   onClearAllFilters: PropTypes.func,
   emptyFilters: PropTypes.bool,
   downloadOriginalFile: PropTypes.func,
   isDownloading: PropTypes.bool,
   isInsideModal: PropTypes.bool,
   onSelectThisPhotoset: PropTypes.func,
   onBulkEdit: PropTypes.func,
   destroyButtonDisabled: PropTypes.bool,
   destroy: PropTypes.func,
   filters: PropTypes.object,
   totalCount: PropTypes.number,
   onClickDetailsLink: PropTypes.func,
   siteUrl: PropTypes.string,
   onClickOpenTips: PropTypes.func,
   onClickSetId: PropTypes.func,
   countState: PropTypes.object,
   allowAddContent: PropTypes.bool,
   showNewStyles: PropTypes.bool,
   showCheckboxes: PropTypes.bool,
   selections: PropTypes.array,
   screenWidth: PropTypes.number,
   isReorderMode: PropTypes.bool,
   setReorderMode: PropTypes.func,
   onSortEnd: PropTypes.func,
   onClickPreview: PropTypes.func,
   pinPhotoset: PropTypes.func,
   contentType: PropTypes.string,
   bundleId: PropTypes.any,
   isMultipleSelect: PropTypes.bool,
   onDeleteMessag: PropTypes.func,
   updateMessage: PropTypes.func,
   isMultipleAttachments: PropTypes.bool,
   isDemoMode: PropTypes.bool,
   updateMessageUnlockDetails: PropTypes.func,
}

Photosets.defaultProps = {
   showNewStyles: false,
   showCheckboxes: false,
   selections: [],
   contentType: '',
   bundleId: null,
   isMultipleSelect: true,
}


export default Photosets;
