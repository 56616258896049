import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Router from 'admin/routes/router';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
   fetchPhotosetsOperation,
   fetchDataByFilterOperation,
   fetchNewDataOperation,
   deletePhotosetsOperation,
   destroyPhotosetsOperation,
   pinPhotosetOperations,
} from 'admin/state/modules/photosets/operations';
import {
   resourcesSelector,
   mediaDownloadButtonSelector,
   countesStateSelector,
} from 'admin/state/modules/resources/selectors';
import { fetchResourcesOperation, downloadMediaOperation, fetchMediaCountsStateOperation } from 'admin/state/modules/resources/operations';
import {
   photosetsDataSelector,
   isPhotosetsDataFetching,
   isNewFetchingSelector,
   isEmptyByFilterSelector,
   isFetchingByFilterSelector,
   isEmptySelector,
   deleteButtonDisabledSelector,

   isFetchingInsideModalSelector,
   listInsideModalSelector,
   isFetchingByFilterInsideModalSelector,
   isNewFetchingInsideModalSelector,
   isEmptyByFilterInsideModalSelector,
   isEmptyInsideModalSelector,
   isInitedSelector,
   filtersSelector,
   isDistoryFetchingSelector,
   warningModalSelector,
   filtersInsideModalSelector,
} from 'admin/state/modules/photosets/selectors';
import { validateStepsRequestCompleted, clearState, setFilterData, setBulkIds, reorderPhotoset,
   // updateBundleStateInData
   setWarningModal,
   updateMessageAction,
} from 'admin/state/modules/photosets/actions'
import PageLoader from 'common/components/modules/page-loading';
import EmptyState from 'common/components/modules/empty-states';
import PhotosetsMainPage from 'admin/views/photosets/index';
import './style.scss'
import QueryParams from 'admin/utils/QueryParams.js'
import Modal from 'common/components/modules/modal';
import DeleteModal from 'admin/views/manage-resource/delete-modal';
import { contentMetaInfoSelector, screenWidthSelector, siteInfoSelector } from 'admin/state/modules/common/selectors';
import PullRefresh from 'admin/utils/PullRefresh.js';
import PullToRefresh from 'pulltorefreshjs';
import { loginAsOperation, siteDetailsRefreshOperation } from 'admin/state/modules/common/operations';
import CreateNewPhotosetsModal from '../create';
import { getCountState } from 'common/utils/utils'
import { isEmpty } from 'lodash'
import { isMobileAndTablet } from 'common/utils/utils';
import TippersModal from 'admin/views/tips/tippers-details-modal'
import { changeContentOrders, getContentTipHistoryById,
   //  getPhotosetsById,
   getPinnedCountStatistics } from 'admin/api/AuthApi';
import CheckboxLabel from 'common/components/elements/inputs/checkbox-label';
import ConfirmModal from 'common/components/modules/modal-wrapper';
import { contentMetaInfo } from 'admin/state/modules/common/actions';
import toast from 'common/utils/toast';
import { history } from 'admin/state/store';
import { changeInitedState } from 'admin/state/modules/photosets/actions';
import { generateUriSegment } from 'common/utils/mediaRouteHelper';
import Intercom from 'common/utils/Intercom';
import { ARTICLE_ID_ON_PHOTOSETS } from 'common/utils/intercom-articles';
// import { generateUriSegment } from 'common/utils/mediaRouteHelper';


class PhotosetsContainer extends Component {

   static propTypes = {
      data: PropTypes.object.isRequired,
      isFetching: PropTypes.bool.isRequired,
      fetchData: PropTypes.func.isRequired,
      fetchDataByFilter: PropTypes.func.isRequired,
      fetchNewData: PropTypes.func.isRequired,
      isNewFetching: PropTypes.bool,
      isEmpty: PropTypes.bool,
      isFetchingByFilter: PropTypes.bool,
      isEmptyByFilter: PropTypes.bool,
      resources: PropTypes.object,
      fetchResources: PropTypes.func,
      validateStep: PropTypes.func,
      siteInfo: PropTypes.object,
      deleteButtonDisabled: PropTypes.bool,
      deletePhotoset: PropTypes.func,
      screenWidth: PropTypes.number,
      refreshSiteDetails: PropTypes.func,
      mediaDownloadButton: PropTypes.bool,
      downloadFile: PropTypes.func,
      isInsideModal: PropTypes.bool,
      onSelectThisPhotoset: PropTypes.func,

      isFetchingInsideModal: PropTypes.bool,
      listInsideModal: PropTypes.object,
      isFetchingByFilterInsideModal: PropTypes.bool,
      isNewFetchingInsideModal: PropTypes.bool,
      isEmptyByFilterInsideModal: PropTypes.bool,
      isEmptyInsideModal: PropTypes.bool,
      clearState: PropTypes.func,
      goTo: PropTypes.func,
      filters: PropTypes.object,
      isInited: PropTypes.bool,
      // setFilterData: PropTypes.func,
      destroyPhotosets: PropTypes.func,
      isDistoryFetching: PropTypes.bool,
      setBulkIds: PropTypes.func,
      fetchCountsState: PropTypes.func,
      countesState: PropTypes.object,

      showNewStyles: PropTypes.bool,
      showCheckboxes: PropTypes.bool,
      selections: PropTypes.array,
      reorderPhotoset: PropTypes.func,
      contentMetaInfo: PropTypes.object,
      updateContentMetaInfo: PropTypes.func,
      loginAs: PropTypes.func,
      changeInitedState: PropTypes.func,
      // showAllContent: PropTypes.bool,
      // updateBundleStateInData: PropTypes.func,
      pinPhotoset: PropTypes.func,
      contentType: PropTypes.string,
      prevetInsideModalDiscard: PropTypes.func,
      bundleId: PropTypes.any,
      setWarningModal: PropTypes.func,
      warningModal: PropTypes.object,
      isMultipleSelect: PropTypes.bool,
      conversationId: PropTypes.number,
      onDeleteMessag: PropTypes.func,
      updateMessageAction: PropTypes.func,

      isMultipleAttachments: PropTypes.bool,
      updateMessageUnlockDetails: PropTypes.func,
      filtersInsideModal: PropTypes.object,
      hideScheduledContents: PropTypes.bool,
   };

   static defaultProps = {
      showAllContent: false,
      isMultipleSelect: true,
      hideScheduledContents: false,
   }

   state = {
      photosetDataWithTips: null,
   }

   constructor(props) {
      super(props);
      this.state = {
         openDeletModal: false,
         dialogPageData: {
            on_home_page: false,
         },
         emptyFilters: true,
         isDownloading: false,
         sortBy: { value: 'date_added:desc', name: 'Newest', type: 'desc' },
         isOpenAddNewModal: false,
         isTippersModalOpen: false,
         contentId: null,
         isReorderMode: !props.isInsideModal && history.location.pathname === '/photosets/reorder',
         confirmModal: {
            isOpen: false,
         },
         reorderedData: {
            newData: [],
            newIndex: null,
         },
         pinnedCount: {},
      };

      this.filter = {
         count: 20,
         page: 1,
      }
      this.deletePhotosetId = null;
      this.key = !props.isInsideModal ? '' : 'InsideModal'
      this.keepInitedState = !!props.isMultipleAttachments
      this.alreadyFetched = false
      if(!props.isInsideModal){
         this.generateUrlQuery(props.filters, true)
      }
      if(props.isInsideModal) {
         this.filter = {
            ...this.filter,
            // status: props.showAllContent ? [] : 'published,unpublished',
            insideModal: 1,
            hideScheduledContents: !!this.props?.hideScheduledContents,
            ...props.filtersInsideModal,
         }
      }
   }

   componentDidUpdate(){
      const { isFetchingInsideModal, prevetInsideModalDiscard, isInsideModal } = this.props

      if(!!prevetInsideModalDiscard && isInsideModal) {
         prevetInsideModalDiscard(isFetchingInsideModal)
      }
   }

   componentWillUnmount() {
      window.photosetScrollTop = window.pageYOffset
      const { isInsideModal, clearState, isMultipleAttachments } = this.props;
      this.onCloseWarningModal();

      if(isMultipleAttachments && isInsideModal) {
         clearState({ filtersInsideModal: this.filter, isInited: true  })
      } else {
         clearState({ filters: this.keepInitedState ? this.filter : {}, filtersInsideModal: this.keepInitedState ? this.filter : {}, isInited: this.keepInitedState })
      }

      if(isInsideModal){
         let element = document.querySelector('#select-from-photosets')
         if(element) element.removeEventListener('scroll', this.handleScroll);
      } else {
         window.removeEventListener('scroll', this.handleScroll);
      }
      PullRefresh.destroyAll();
   }

   componentDidMount() {
      const {
         fetchData,
         validateStep,
         fetchResources,
         fetchDataByFilter,
         refreshSiteDetails,
         siteInfo: {
            site_status: siteStatus,
         },
         isInsideModal,
         isInited,
         fetchCountsState,
         conversationId,
         // filters,
         listInsideModal,
         filtersInsideModal,
      } = this.props;
      if(window.photosetScrollTop && isInited) {
         window.scroll({
            top: window.photosetScrollTop,
            left: 0,
         });
      }
      if('live' !== siteStatus) {
         refreshSiteDetails();
      }
      this.key = !isInsideModal ? '' : 'InsideModal'

      validateStep(null);
      fetchResources('cast_members,tags')
      if(history.location.pathname === '/photosets/reorder' && !isInsideModal) {
         this.filter = {
            count: 20,
            page: 1,
            status: ['published'],
         }
      }
      if(conversationId){
         this.filter = {
            ...this.filter,
            conversation: conversationId,
         }
      }
      let param = this.generateQuery()
      const params = QueryParams.getFilters();
      const emptyFilterParams = isEmpty(params)
      fetchCountsState(param);

      if(!isInsideModal){
         this.setState({
            emptyFilters: emptyFilterParams,
         })
      } else {
         const { clearState, isMultipleAttachments } = this.props

         if(isMultipleAttachments) {
            this.filter = {
               ...this.filter,
               // ...filters,
               ...filtersInsideModal,
            }

            const { count,
               // status
            } = this.filter
            let otherKeys = Object.keys(this.filter).filter(it => !(['conversation', 'count', 'insideModal', 'page', 'status', 'hideScheduledContents'].includes(it)))

            this.setState({
               // emptyFilters: (count === 20 && status === 'published,unpublished') && (otherKeys.length === 0),
               emptyFilters: count === 20 && otherKeys.length === 0,
            })

            clearState({ filtersInsideModal: this.filter  })
         } else {
            clearState({ filtersInsideModal: {} })
         }
      }

      if((fetchData && !isInited && !isInsideModal) || (fetchData && isInsideModal && !listInsideModal?.data)) {
         fetchData(param, this.key, emptyFilterParams)
      }

      if(params.create && '1' === params.create) {
         this.setState({ isOpenAddNewModal: true })
      }
      PullToRefresh.init({
         ...PullRefresh.init(),
         onRefresh: (done) => {
            this.filter = { ...this.filter, page: 1, count: 20 };
            param = this.generateQuery()
            if(param){
               fetchDataByFilter(param, this.key)
               done();
            } else {
               fetchData(param, this.key, emptyFilterParams)
               done();
            }
         },
      });
      if(isInsideModal){
         let element = document.querySelector('#select-from-photosets')
         if(element) element.addEventListener('scroll', this.handleScroll);
      } else {
         window.addEventListener('scroll', this.handleScroll);
      }
      getPinnedCountStatistics('photo-collections')
         .then(resp => {
            this.setState({
               pinnedCount: {
                  ...resp?.data,
               },
            })
         })
         .catch(err => console.log(err))
   }

   generateQuery = () => {
      const { isInsideModal, contentType } = this.props
      const query = [];
      Object.keys(this.filter).forEach((key) => {
         if(key){
            if(this.filter[key]) {
               'conversation' !== key && 'insideModal' !== key && key !== 'count' && key !== 'page' && key !== 'sortBy' && key !== 'hideScheduledContents' ?
                  query.push(`query[]=${ key }=${ encodeURIComponent(this.filter[key]) }`) :
                  query.push(`${ key }=${ encodeURIComponent(this.filter[key]) }`);
            }
         }
      });

      if(!!isInsideModal && contentType === 'store' && (!('status' in this.filter) || this.filter.status === '')) {
         query.push(`query[]=status=published,unpublished`)
      }
      return query;
   };

   handleScroll = (e) => {
      const { isFetching, isInsideModal, isFetchingInsideModal } = this.props
      if(!isInsideModal && isFetching) return
      if(isInsideModal && isFetchingInsideModal) return
      const { isAdded } = this.state;
      const { scrollHeight, clientHeight, scrollTop } =  isInsideModal ? e.target : document.documentElement;
      let diff = (scrollHeight - scrollTop) - clientHeight

      if(diff < 100 && !isAdded) {
         this.nextPage()
      }
   }

   nextPage = async () => {
      const {
         fetchNewData, isNewFetching, data, isInsideModal, isNewFetchingInsideModal, listInsideModal,
      } = this.props;
      let query = [];
      let fetching = isNewFetching;
      let list = data;
      if(isInsideModal) {
         fetching = isNewFetchingInsideModal
         list = listInsideModal
      }
      const { current_page: currentPage, last_page: pages } = list
      if(currentPage < pages && !fetching) {
         this.filter = { ...this.filter, page: currentPage + 1 };
         query = this.generateQuery();
         await fetchNewData(query, this.key);
      } else {
         return
      }
   }
   handleInputChange = (data) => {
      let newData = this.state.dialogPageData;
      newData = {
         ...newData,
         ...data,
      }
      this.setState({
         dialogPageData: newData,
      })
   }
   handleAllSettings = () => {

   }
   onFilterChange = (value, key) => {
      let data = value
      if(!!Array.isArray(value)) {
         data = value.join(',')
      }
      const { isInsideModal } = this.props
      if(!isInsideModal) {
         let urlKey = key
         if('casts' === key){
            urlKey = 'castmembers'
         }
         let filtersData = {}
         filtersData = { ...this.filter, [urlKey]: data }
         this.generateUrlQuery(filtersData)
      } else {
         this.filter = {
            ...this.filter,
            [key]: data,
            page: 1,
         }
         let emptyFilters = true
         Object.keys(this.filter).forEach((key) => {
            if(!['conversation', 'count', 'hideScheduledContents', 'insideModal', 'page'].includes(key)) {
               if(!!this.filter[key]) emptyFilters = false
            }
         })

         this.setState({ emptyFilters })
      }
      let query = this.generateQuery()
      const { fetchDataByFilter, fetchCountsState } = this.props
      fetchDataByFilter(query, this.key)
      fetchCountsState(query)
   }
   onClearAllFilters = () => {
      this.filter = {
         count: 20,
         page: 1,
      }
      const { isInsideModal, conversationId, hideScheduledContents } = this.props
      if(!isInsideModal) {
         QueryParams.removeAllQueryParam()
      }
      if(isInsideModal) {
         this.filter = {
            ...this.filter,
            insideModal: 1,
            hideScheduledContents,
         }
         if(conversationId){
            this.filter = {
               ...this.filter,
               conversation: conversationId,
            }
         }
      }
      this.setState({
         emptyFilters: true,
      })
      let query = this.generateQuery()
      const { fetchDataByFilter, fetchCountsState } = this.props
      fetchDataByFilter(query, this.key)
      fetchCountsState(query)
   }

   openCloseAddNewModal  = () => {
      this.setState((oldState) => {
         return {
            isOpenAddNewModal: !oldState.isOpenAddNewModal,
         }
      }, () => {
         const { isOpenAddNewModal } = this.state;
         if(isOpenAddNewModal) {
            QueryParams.setQueryParam('create', '1')
         } else {
            QueryParams.setQueryParam('create', null)
         }
      })
   }

   onBulkEdit = data => {
      const { goTo, setBulkIds } = this.props;
      let query = this.generateQuery();
      query = query.filter(el => el.includes('query[]='));
      setBulkIds({ ...data, params: query })
      const title = `Edit ${ getCountState(data.count, 'photoset') }`
      Router.route('PHOTOSETS_BULK_EDIT').setTitle(title)
      Router.route('PHOTOSETS_BULK_EDIT').setParent('PHOTOSETS')
      goTo(Router.route('PHOTOSETS_BULK_EDIT').getCompiledPath());
   }

   generateUrlQuery = (filters, inited) => {
      if(filters && !isEmpty(filters)) {
         const urlParams = {};
         Object.keys(filters).forEach((key) => {
            if(key){
               if(key !== 'count' && key !== 'page') {
                  urlParams[key] = filters[key]
               }
               if(key === 'casts') {
                  urlParams.castmembers = filters[key]
                  delete urlParams.casts
               }
               if(!filters[key]) {
                  delete urlParams[key]
               }
            }
         });
         QueryParams.defineFilters(urlParams)
      }
      let params = QueryParams.getFilters()
      if(params.castmembers) {
         params.casts = params.castmembers
         delete params.castmembers
      }
      this.filter = { count: 20, page: 1, ...params };
      if(!inited){
         this.setState({
            emptyFilters: isEmpty(params),
         })
      }
   };

   onClickDetailsLink = () => {
      this.keepInitedState = true
   }

   destroy = (data, callBack) => {
      const { destroyPhotosets } = this.props
      let q = this.generateQuery();
      q = q.filter(el => el.includes('query[]='))
      destroyPhotosets(data, callBack, q)
   }

   getState = () => {
      const {
         data,
         isFetching,
         isNewFetching,
         isFetchingByFilter,
         isEmptyByFilter,
         isEmpty,
         isInsideModal,
         isFetchingInsideModal,
         listInsideModal,
         isFetchingByFilterInsideModal,
         isNewFetchingInsideModal,
         isEmptyByFilterInsideModal,
         isEmptyInsideModal,
      } = this.props;
      let list = data
      let loading = isFetching
      let loadFilter = isFetchingByFilter
      let loadNewData = isNewFetching
      let emptyByFilter = isEmptyByFilter
      let empty = isEmpty
      if(isInsideModal){
         list = listInsideModal
         loading = isFetchingInsideModal
         loadFilter = isFetchingByFilterInsideModal
         loadNewData = isNewFetchingInsideModal
         emptyByFilter = isEmptyByFilterInsideModal
         empty = isEmptyInsideModal
      }
      return { data: list, loading, loadFilter, loadNewData, emptyByFilter, empty }
   }

   handleCloseModalByUrl = () => {
      this.onTipModalClose()
   }

   onTipModalClose = () => {
      this.setState((...state) => {
         return {
            state,
            isTippersModalOpen: !this.state.isTippersModalOpen,
         }
      })
   }
   onClickSetId = (id) => {
      this.setState((...state) => {
         return {
            state,
            contentId: id,
         }
      })
   }

   fetchContentTipsData = (id) => {
      // eslint-disable-next-line no-unused-vars
      let data = getContentTipHistoryById(id, 'photo-collections').then(res => {
         this.setState((...state) => {
            return {
               state,
               photosetDataWithTips: res.data,
            }
         })
      }).catch((err) => {
         console.log(err)
         this.setState((...state) => {
            return {
               state,
               storyDataWithTips: [],
            }
         })
      })
   }

   cleanTipsState = () => {
      this.setState((...state) => {
         return {
            state,
            photosetDataWithTips: null,
         }
      })


   }

   onSortEnd = async ({ newData, newIndex }, bool = false) => {
      const { reorderPhotoset, contentMetaInfo: { dont_show_content_reorder_message_photoset } } = this.props
      try {
         if(!bool && !dont_show_content_reorder_message_photoset) {
            this.setState({
               confirmModal: {
                  ...this.state.confirmModal,
                  isOpen: true,
               },
               reorderedData: { newData, newIndex },
            })
         } else {
            this.setState({
               confirmModal: {
                  ...this.state.confirmModal,
                  isOpen: false,
               },
               reorderedData: { newData: [], newIndex: null },
            })
            let replace_prev_id  = !!newData[newIndex - 1] ? newData[newIndex - 1].id : null
            let replaced_id = newData[newIndex].id
            let replace_next_id = !!newData[newIndex + 1] ? newData[newIndex + 1].id : null
            if(!!replace_next_id && !newData[newIndex + 1].is_published) {
               replace_next_id = null
            }
            if(!!replace_prev_id  && !newData[newIndex - 1].is_published) {
               replace_prev_id  = null
            }
            const { data } = await changeContentOrders('photo-collections', { replaced_id, replace_next_id, replace_prev_id, dont_show_message_again: dont_show_content_reorder_message_photoset })
            newData.forEach((item, index) => {
               if(item.id === replaced_id) {
                  newData[index].publish_date = data
               }
            })
            reorderPhotoset(newData)
            toast.success('Content has been reordered')
         }
      } catch (error) {
         console.log(error);
      }
   }

   onStatusFilter = (isReorderMode) => {
      const { goTo, changeInitedState } = this.props
      changeInitedState()
      let route = history.location.pathname === '/photosets' ? 'PHOTOSETS_REORDER' : 'PHOTOSETS'
      goTo(Router.route(`${ route }`).getCompiledPath())

   }

   // handleVisibilityChange = async () => {
   //    const { updateBundleStateInData } = this.props
   //    let bool = !this.alreadyFetched && !!this.deletePhotosetId

   //    if(document.visibilityState === "visible" && bool) {
   //       this.alreadyFetched = true;
   //       const { data }  = await getPhotosetsById(this.deletePhotosetId);
   //       updateBundleStateInData(data, false)
   //    } else if(document.visibilityState === 'hidden') {
   //       this.setState({
   //          ...this.state,
   //          warningModal: {
   //             ...this.state.warningModal,
   //             isOpen: false,
   //          },
   //       })
   //       this.alreadyFetched = false;
   //    }
   // }

   openDeleteConfirmModal  = (id = null, open = false) => {

      this.setState({
         ...this.state,
         openDeletModal: open,
      })

      this.deletePhotosetId = id;
   }

   onCloseWarningModal = () => {
      const { setWarningModal }  = this.props;
      setWarningModal({
         isOpen: false,
         description: null,
         ids: null,
         multiple_bundles: null,
         buttonText: null,
         title: null,
         buttonIconName: null,
      });
   }
   onActionWarningModal = () => {
      const { warningModal } = this.props;
      this.onCloseWarningModal();
      if('paid_for_unlock_or_download' !== warningModal.reason){
         if(warningModal?.multiple_bundles){
            window.open(`${ Router.route('STORE').getCompiledPath() }?q={"type":"content_bundle_item"}`)
         } else {
            window.open(Router.route('STORE_EDIT_BUNDLE').getCompiledPath({ id: generateUriSegment(warningModal.ids?.[0]) }))
         }
      }
   }

   render() {
      const {
         // data,
         resources,
         deleteButtonDisabled,
         deletePhotoset,
         screenWidth,
         mediaDownloadButton,
         downloadFile,
         isInsideModal,
         onSelectThisPhotoset,
         isDistoryFetching,
         siteInfo,
         countesState,
         showNewStyles,
         showCheckboxes,
         selections,
         contentMetaInfo,
         updateContentMetaInfo,
         loginAs,
         pinPhotoset,
         contentType,
         bundleId,
         warningModal,
         isMultipleSelect,
         onDeleteMessag,
         updateMessageAction,
         isMultipleAttachments,
         updateMessageUnlockDetails,
      } = this.props;
      const {
         sortBy,
         openDeletModal,
         emptyFilters,
         isOpenAddNewModal,
         contentId,
         isTippersModalOpen,
         photosetDataWithTips,
         isReorderMode,
         confirmModal,
         pinnedCount,
      } = this.state;
      const { data, loading, loadFilter, loadNewData, emptyByFilter, empty } = this.getState()
      if(loading) {
         return (
            <div className='flex h-full w-full'>
               <PageLoader withLoader={ isInsideModal } type='nonTab' customStyle='h-full w-full' />
            </div>
         )
      }
      return (
         <>
            {
               !!empty ?
                  <div className='flex h-full items-center'>
                     <EmptyState
                        icon='photosets'
                        noContentText='You don’t have any photosets'
                        buttonText={ isInsideModal ? null : siteInfo?.can_add_content ? 'Add new photoset' : null }
                        className='h-full'
                        onAdd={ isInsideModal ? null :  this.openCloseAddNewModal }
                        linkButtonText='More info on photosets'
                        linkButtonIcon='attention-outline'
                        onLinkClick={ () => Intercom.showArticle(ARTICLE_ID_ON_PHOTOSETS) }
                        dontAddIntercomClassName
                        buttonDisabled={ siteInfo?.site_status === 'demo_mode' }
                     />
                  </div>
                  :
                  <>
                     <PhotosetsMainPage
                        data={ data && !isReorderMode ? data.data : data?.data?.filter(photo => !photo.is_pinned)  }
                        onSortChange={ this.onSortChange }
                        onClickCreate={ () => {} }
                        activeSortValue={ sortBy }
                        endList={ data.current_page === data.last_page }
                        isEmpty={ emptyByFilter }
                        isFetchingByFilter={ loadFilter }
                        isNewFetching={ loadNewData }
                        goToAddNew={ () => this.openCloseAddNewModal() }
                        width={ screenWidth }
                        onFilterChange={ this.onFilterChange }
                        resources={ resources }
                        desktopType={ screenWidth > 768 ? 'descktop' : 'mobile' }
                        openDeleteConfirmModal={ (id) => this.openDeleteConfirmModal(id, true) }
                        onMoreInfoPhotosets={ () =>  Intercom.showArticle(ARTICLE_ID_ON_PHOTOSETS) }
                        onClearAllFilters={ this.onClearAllFilters }
                        emptyFilters={ emptyFilters }
                        downloadOriginalFile={ downloadFile }
                        isDownloading={ mediaDownloadButton }
                        isInsideModal={ isInsideModal }
                        onSelectThisPhotoset={ onSelectThisPhotoset }
                        onBulkEdit={ this.onBulkEdit }
                        filters={ this.filter }
                        destroyButtonDisabled={ isDistoryFetching }
                        destroy={ this.destroy }
                        totalCount={ countesState?.photosets?.total || data.total }
                        onClickDetailsLink={ this.onClickDetailsLink }
                        siteUrl={ siteInfo.site_url }
                        onClickOpenTips={ this.onTipModalClose }
                        onClickSetId={ (id) => {
                           this.fetchContentTipsData(id)
                           this.onClickSetId(id)
                        } }
                        allowAddContent={ siteInfo?.can_add_content }
                        showNewStyles={ showNewStyles }
                        showCheckboxes={ showCheckboxes }
                        selections={ selections }
                        countState={ isReorderMode ? { total: pinnedCount?.not_pinned_total } :  countesState?.photosets  }
                        screenWidth={ screenWidth }
                        isReorderMode={ isReorderMode }
                        setReorderMode={ () => this.onStatusFilter(isReorderMode) }
                        onSortEnd={ this.onSortEnd }
                        onClickPreview={ () => {
                           loginAs({ id: null, type: 'guest' }, siteInfo.site_url, 'photosets')
                        } }
                        pinPhotoset={ (id, bool) => pinPhotoset(id, Boolean(bool)) }
                        contentType={ contentType }
                        bundleId={ bundleId }
                        isMultipleSelect={ isMultipleSelect }
                        onDeleteMessag={ onDeleteMessag }
                        updateMessage={ updateMessageAction }
                        isMultipleAttachments={ isMultipleAttachments }
                        isDemoMode={ siteInfo?.site_status === 'demo_mode' }
                        updateMessageUnlockDetails={ updateMessageUnlockDetails }
                     />
                     {
                        openDeletModal && (
                           <Modal
                              contentPosition={ screenWidth < 701 ? 'bottom' : 'center' }
                              onClose={ () => this.openDeleteConfirmModal() }
                              isCloseAction
                              otherClassName={ screenWidth < 701 ? 'w-full' : '!w-[375px]' }
                           >
                              <DeleteModal
                                 type='photoset'
                                 isDisabled={ deleteButtonDisabled }
                                 deleteCollection={ () => {
                                    const params = QueryParams.getFilters();
                                    const emptyFilterParams = isEmpty(params);
                                    deletePhotoset(this.deletePhotosetId, (error) => {
                                       this.setState({
                                          ...this.state,
                                          warningModal: {
                                             isOpen: true,
                                             description: error.message,
                                             ids: error.bundle_ids,
                                             multiple_bundles: error.multiple_bundles,
                                          },
                                       })
                                    }, emptyFilterParams);
                                    this.openDeleteConfirmModal();
                                    if(data.data && data.data.length < 10){
                                       this.nextPage();
                                    }
                                 } }
                                 onClickCancel={ () => this.openDeleteConfirmModal() } />
                           </Modal>

                        )
                     }

                     {
                        contentId && isTippersModalOpen && (
                           <Modal
                              fullScreenMode={ isMobileAndTablet() }
                              contentPosition='responsive'
                              onClose={ () => this.handleCloseModalByUrl() }
                              otherClassName='tipper sm:!rounded-xlg'
                           >
                              <TippersModal
                                 onModalClose={ this.onTipModalClose }
                                 content={ photosetDataWithTips }
                                 contentId={ contentId }
                                 onTipsModalClose={ this.cleanTipsState }
                              />
                           </Modal>
                        )
                     }
                  </>
            }
            {
               isOpenAddNewModal && (
                  <CreateNewPhotosetsModal
                     onCloseModal={ this.openCloseAddNewModal }
                  />
               )
            }
            {
               confirmModal.isOpen &&
                  <ConfirmModal
                     title='Publication date change'
                     type='confirm'
                     icon='warning'
                     iconColor='warning'
                     description='Reordering has the effect of changing the publication date of content that is being reordered. Are you sure you want to reorder content?'
                     contentWidth='375px'
                     buttonText='Yes, I want to reorder content'
                     // buttonClassName='mt-6 !text-sm'
                     action={ () => this.onSortEnd({ newData: this.state.reorderedData.newData, newIndex: this.state.reorderedData.newIndex }, true) }
                     cancelButtonAction={ () => {
                        updateContentMetaInfo({
                           ...contentMetaInfo,
                           dont_show_content_reorder_message_photoset: false,
                        })
                        this.setState({
                           ...this.state,
                           confirmModal: {
                              isOpen: false,
                              checked: false,
                           },
                           reorderedData: {
                              newData: [],
                              newIndex: null,
                           },
                        })
                     } }
                     isPreventDeafault={ false }
                     isMobile={ screenWidth < 700 }
                  >
                     <div className='w-full mt-8 flex items-center justify-center'>
                        <CheckboxLabel
                           name='dont_show_message_again'
                           checked={ contentMetaInfo?.dont_show_content_reorder_message_photoset }
                           className='flex justify-center'
                           textColor='secondary'
                           label='Don’t show again'
                           onChange={ () => {
                              updateContentMetaInfo({
                                 ...contentMetaInfo,
                                 dont_show_content_reorder_message_photoset: !contentMetaInfo?.dont_show_content_reorder_message_photoset,
                              })
                           } }
                           padding='0'
                        />
                     </div>
                  </ConfirmModal >
            }
            {
               warningModal.isOpen &&
               <ConfirmModal
                  onCloseModal={ _ => {
                     if('paid_for_unlock_or_download' !== warningModal.reason){
                        this.onCloseWarningModal()
                     }
                  }
                  }
                  isCloseAction
                  type='confirm'
                  icon='warning'
                  withoutCloseButton
                  iconColor='warning'
                  title={ warningModal.title }
                  description={ warningModal?.description }
                  cancelText={ warningModal.cancelText }
                  buttonText={ warningModal.buttonText }
                  buttonIconName={ warningModal.buttonIconName }
                  secondaryButtonClassName={ warningModal.secondaryButtonClassName }
                  buttonClassName='mt-8'
                  action={ this.onActionWarningModal }
                  iconSize='2xl'
                  isMobile={ screenWidth < 701 }
               />
            }
         </>
      )
   }
}

const mapStateToProps = state => ({
   data: photosetsDataSelector(state),
   isFetching: isPhotosetsDataFetching(state),
   isNewFetching: isNewFetchingSelector(state),
   isEmptyByFilter: isEmptyByFilterSelector(state),
   isFetchingByFilter: isFetchingByFilterSelector(state),
   isEmpty: isEmptySelector(state),
   resources: resourcesSelector(state),
   deleteButtonDisabled: deleteButtonDisabledSelector(state),
   screenWidth: screenWidthSelector(state),
   siteInfo: siteInfoSelector(state),
   mediaDownloadButton: mediaDownloadButtonSelector(state),

   isFetchingInsideModal: isFetchingInsideModalSelector(state),
   listInsideModal: listInsideModalSelector(state),
   isFetchingByFilterInsideModal: isFetchingByFilterInsideModalSelector(state),
   isNewFetchingInsideModal: isNewFetchingInsideModalSelector(state),
   isEmptyByFilterInsideModal: isEmptyByFilterInsideModalSelector(state),
   isEmptyInsideModal: isEmptyInsideModalSelector(state),
   isInited: isInitedSelector(state),
   filters: filtersSelector(state),
   filtersInsideModal: filtersInsideModalSelector(state),
   isDistoryFetching: isDistoryFetchingSelector(state),
   countesState: countesStateSelector(state),
   contentMetaInfo: contentMetaInfoSelector(state),
   warningModal: warningModalSelector(state),

});

const mapDispatchToProps = dispatch => ({
   fetchData: (params = {}, key, bool) => {
      dispatch(fetchPhotosetsOperation(params, key, bool));
   },
   fetchCountsState: (query) => {
      dispatch(fetchMediaCountsStateOperation('photosets', query));
   },
   fetchResources: (params = {}) => {
      dispatch(fetchResourcesOperation(params));
   },
   fetchDataByFilter: (params = {}, key) => {
      dispatch(fetchDataByFilterOperation(params, key));
   },
   fetchNewData: (params = {}, key) => {
      dispatch(fetchNewDataOperation(params, key));
   },
   goTo: (location) => {
      dispatch(push(location))
   },
   validateStep: (step) => {
      dispatch(validateStepsRequestCompleted(step))
   },
   deletePhotoset: (id, callBack, emptyFilterParams) => {
      dispatch(deletePhotosetsOperation(id, 'list', callBack, emptyFilterParams))
   },
   refreshSiteDetails: () => {
      dispatch(siteDetailsRefreshOperation())
   },
   downloadFile: (id) => dispatch(downloadMediaOperation(id, 'photo-collections')),
   clearState: data => dispatch(clearState(data)),
   setFilterData: data => dispatch(setFilterData(data)),
   destroyPhotosets: (ids, callBack, query) => dispatch(destroyPhotosetsOperation(ids, callBack, query)),
   setBulkIds: (ids) => dispatch(setBulkIds(ids)),
   reorderPhotoset: data => dispatch(reorderPhotoset(data)),
   updateContentMetaInfo: info => dispatch(contentMetaInfo(info)),
   loginAs: (data, url, pageUrl) => {
      dispatch(loginAsOperation(data, url, pageUrl));
   },
   changeInitedState: () => dispatch(changeInitedState()),
   // updateBundleStateInData: (data, bool) => dispatch(updateBundleStateInData(data, bool)),
   pinPhotoset: (id, bool) => dispatch(pinPhotosetOperations(id, bool)),
   setWarningModal: data => {
      dispatch(setWarningModal(data))
   },
   updateMessageAction: data => dispatch(updateMessageAction(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhotosetsContainer);
