import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initial-state';
import { isArray } from 'lodash'

let reducersMap = {
   [types.FETCH_REQUEST_START]: (state, action) => {
      const key = action.payload
      return {
         ...state,
         key,
         [`isFetching${ key }`]: true,
         photos: [],
         photosLength: 0,
         cover: {},
         errors: {},
         openEditTitelMod: false,
         openStatusMod: false,
         [`isEmptyByFilter${ key }`]: false,
         [`isFetchingByFilter${ key }`]: false,
         [`isEmpty${ key }`]: false,
         [`isFetchingById${ key }`]: true,
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const  { payload: { data, emptyFilters, key: currentKey } } = action;
      const key = currentKey || state.key || ''
      let newState = {
         [`isEmpty${ key }`]: data.data.length === 0,
         [`isEmptyByFilter${ key }`]: false,
      }
      if(!key && !emptyFilters) {
         newState = {
            [`isEmptyByFilter${ key }`]: data.data.length === 0,
         }
      }
      return {
         ...state,
         [`isFetching${ key }`]: false,
         [`photosetsData${ key }`]: data,
         ...newState,
         isInited: 'InsideModal' !== state.key,
      }
   },
   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      const key = state.key || ''
      return {
         ...state,
         [`isFetching${ key }`]: false,
      }
   },

   [types.FETCH_NEW_REQUEST_START]: (state, action) => {
      const  { payload: { key: currentKey } } = action;
      const key = currentKey || state.key || ''
      return {
         ...state,
         [`isNewFetching${ key || '' }`]: !state?.[`isFetchingByFilter${ key || '' }`],
      }
   },

   [types.FETCH_NEW_COMPLETED]: (state, action) => {
      const { payload: { data, key: currentKey } } = action;
      const key = currentKey || state.key || ''
      return {
         ...state,
         [`isNewFetching${ key }`]: false,
         [`isFetchingByFilter${ key }`]: false,
         [`photosetsData${ key }`]: {
            ...data,
            data: [...state[`photosetsData${ key }`].data, ...data.data],
         },
      }
   },
   [types.FETCH_NEW_FAILED]: (state, action) => {
      const key = state.key || ''
      return {
         ...state,
         [`isNewFetching${ key }`]: false,
      }
   },

   [types.FETCH_BY_FILTER_REQUEST_START]: (state, action) => {

      const  { payload: { key: currentKey } } = action;
      const key = currentKey || state.key || ''
      return {
         ...state,
         [`isFetchingByFilter${ key || '' }`]: true,
         [`isNewFetching${ key || '' }`]: false,
      }
   },

   [types.FETCH_BY_FILTER_COMPLETED]: (state, action) => {
      const { data, key: currentKey } = action.payload;
      const key = currentKey || state.key || ''
      return {
         ...state,
         [`isFetchingByFilter${ key }`]: false,
         [`photosetsData${ key }`]: data,
         [`isEmptyByFilter${ key }`]: data.data.length === 0,
         [`isNewFetching${ key }`]: false,
      }
   },
   [types.FETCH_BY_FILTER_FAILED]: (state, action) => {
      const key = state.key || ''
      return {
         ...state,
         [`isFetchingByFilter${ key }`]: false,
         [`isNewFetching${ key }`]: false,
      }
   },
   [types.FETCH_BY_ID_REQUEST_START]: state => {
      return {
         ...state,
         isFetchingById: true,
         isDeleteFetching: false,
         updateStatusFetching: false,
         // openStatusMod: false,
      }
   },

   [types.FETCH_BY_ID_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         isFetchingById: false,
         activeData: payload,
         // owners: models.data.data,
         photosLength: payload.photos ? payload.photos.length : 0,
         photos: payload.photos ? payload.photos : [],
      }
   },
   [types.FETCH_BY_ID_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingById: false,
      }
   },
   [types.UPDATE_REQUEST_START]: (state, action) => ({
      ...state,
      errors: {},
      isUpdateFetching: true,
      openEditTitelMod: !!action.payload,
      // openStatusMod: !!action.payload,
      updateStatusFetching: true,
      updateButtonDisabled: true,
   }),
   [types.DELETE_REQUEST_START]: state => ({
      ...state,
      isDeleteFetching: true,
   }),
   // [types.DELETE_COMPLETED]: state => ({
   //    ...state,
   //    isDeleteFetching: true,
   //    deleteButtonDisabled: true,
   // }),
   [types.DELETE_COMPLETED]: (state, action) => {
      const { payload: { id, emptyFilterParams } } = action;
      let newState = {};
      if(state?.photosetsData?.data){
         const data = [...state.photosetsData.data].filter(i => i.id !== +id);
         newState = {
            photosetsData: {
               ...state.photosetsData,
               data,
            },

            isFetchingById: true,
         }
         if(emptyFilterParams){
            newState = {
               ...newState,
               isEmpty: data.length === 0,
            }
         } else {
            newState = {
               ...newState,
               isEmptyByFilter: data.length === 0,
            }
         }
      }
      return {
         ...state,
         ...newState,
         deleteButtonDisabled: false,
      }
   },
   [types.UPDATE_PUBLISH_DATA]: (state, action) => {
      let newData = action.payload;
      return {
         ...state,
         activeData: {
            ...state.activeData,
            ...newData,
         },
      }
   },
   [types.UPDATE_COMPLETED]: (state, action) => {
      const { payload: {
         data,
         step,
         allPhotosets,
      } } = action;
      let photoset = { ...data }
      if(photoset.is_published !== undefined && typeof photoset.is_published === 'boolean') {
         photoset.is_future_publish_enabled = false
      }
      let completedSteps = state.activeData.completed_steps;
      if(step) {
         completedSteps = [...completedSteps, step]
      }
      let newState = {}
      let { key } = state
      if(allPhotosets){
         if(isArray(allPhotosets)){
            newState = { [`photosetsData${ key }`]: {
               ...state[`photosetsData${ key }`],
               data: allPhotosets,
            } }
         } else if(state.activeData.id) {
            if(step) {
               newState = { [`photosetsData${ key }`]: {
                  ...state[`photosetsData${ key }`],
                  data: [...state[`photosetsData${ key }`].data, ...data],
               },
               }
            } else {
               newState = { [`photosetsData${ key }`]: {
                  ...state[`photosetsData${ key }`],
                  data: [...state[`photosetsData${ key }`].data].map(item => {
                     let { ...elm } = item
                     if(elm.id === state.activeData.id){
                        elm = { ...elm, ...data }
                     }
                     return elm
                  }),
               } }
            }
         }
      }
      let updateData = { ...photoset }
      const { multiple_access_members, multiple_access_others } = state.activeData
      if('access_members_different' in photoset) {
         updateData = {
            ...updateData,
            multiple_access_members: multiple_access_members.map(elm => {
               let i = { ...elm }
               let key = i.id
               if(!key) key = i.type
               if([key] in photoset.access_members_different){
                  i = { ...i, access: { ...photoset.access_members_different[key] } }
               }
               return i
            }),
         }
      }
      if('access_others_different' in photoset) {
         updateData = {
            ...updateData,
            multiple_access_others: multiple_access_others.map(elm => {
               let i = { ...elm }
               let key = i.id
               if(!key) key = i.type
               if([key] in photoset.access_others_different){
                  i = { ...i, access: { ...photoset.access_others_different[key] } }
               }
               return i
            }),
         }
      }
      return {
         ...state,
         activeData: {
            ...state.activeData,
            ...updateData,
            completed_steps: completedSteps,
         },
         isErrors: false,
         updateButtonDisabled: false,
         errors: {},
         openEditTitelMod: false,
         openStatusMod: false,
         updateStatusFetching: false,
         isUpdateFetching: false,
         ...newState,

      }
   },
   [types.UPDATE_FAILED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         errors: payload,
         updateButtonDisabled: false,
         isErrors: true,
         openEditTitelMod: !!(payload.title || payload.description),
         updateStatusFetching: false,
         openStatusMod: !!payload.publish_date,
      }
   },
   [types.UPLOAD_PHOTOS_START]: (state, action) => {
      const { payload: { data, name } } = action
      return {
         ...state,
         activeData: {
            ...state.activeData,
            photos: [
               {
                  cf_path: null,
                  status: 'loading',
                  index: data,
                  name: name,
               },
               ...state.activeData.photos,
            ],
         },
      }
   },
   [types.UPLOAD_PHOTOS_FAILED]: (state, action)  => {
      const { payload } = action
      const data = [...state.activeData.photos].map(item => {
         const { ...elm } = item
         if(elm.index === payload) {
            elm.status = 'error'
         }
         return elm
      })
      return {
         ...state,
         isFetchingByIdphotos: data,
      }
   },

   [types.UPLOAD_FAKE_PHOTOS]: (state, action)  => {
      const { payload: { data, status, callBack } } = action
      let photos = [...state.activeData.photos];
      switch (status) {
         case 'loading' :
            photos = [
               data,
               ...photos,
            ]
            break;
         case 'done' :
         case 'complete' :
         case 'error' :
            photos = [...data]
            break;
         default:
            break;
      }

      if(callBack){
         callBack(photos)
      }
      return {
         ...state,
         activeData: {
            ...state.activeData,
            photos: [...photos],
         },
      }
   },

   [types.UPLOAD_PHOTOS_COMPLETED]: (state, action) => {
      const { payload } = action
      let name = payload.name;
      let photosData = [...state.activeData.photos].map(item => {
         if(item.name && item.name === name) {
            return {
               ...item,
               ...payload.data,
            }
         }
         return item;
      })
      let newState = {
         ...state,
         activeData: {
            ...state.activeData,
            photos: [...photosData],
         },
      }
      if(!state.cover.id) {
         newState = {
            ...newState,
            cover: payload.data,
         }
      }
      return {
         ...newState,
      }
   },
   [types.REMOVE_UPLOAD_PHOTOS_COMPLETED]: (state, action) => {
      const { payload } = action
      const data = [...state.photos].filter(item => item.id !== payload.id)
      let newState = {
         ...state,
         photos: [
            ...data,
         ],
         newPhotos: [],
      }
      return {
         ...newState,
      }
   },
   [types.FETCH_PHOTOS_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         ...payload,
      }
   },

   [types.DELETE_PHOTOS_COMPLETED]: (state, action) => {
      const { payload } = action
      const data = [...state.activeData.photos].filter(item => item.id !== payload.id || item.index !== payload.index)
      return {
         ...state,
         activeData: {
            ...state.activeData,
            photos: data,
         },
      }
   },
   [types.REMOVE_DELETED_PHOTOS_COMPLETED]: (state, action) => {
      const { payload } = action
      const data = [...state.photos, ...payload];
      console.log(data, payload, 'REMOVE_DELETED_PHOTOS_COMPLETED')
      return {
         ...state,
         // photos: data,
         // deletePhotos: [],
         // photosLength: data.length,
      }
   },
   [types.SAVE_PHOTOS_COMPLETED]: (state, action) => {
      return {
         ...state,
         deletePhotos: [],
         newPhotos: [],
      }
   },

   [types.SELECT_COVER_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         cover: payload,
      }
   },
   [types.SAVE_NEW_PHOTOSETS_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         newPhotosets: {
            ...state.newPhotosets,
            ...payload,
         },
      }
   },
   [types.CUSTOM_PRICES_START]: (state, action) => {
      return {
         ...state,
         isFetchingAccess: true,
      }
   },
   [types.CUSTOM_PRICES_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         isFetchingAccess: false,
         customPrices: payload.customPrices,
         accessing: payload.accessing,
      }
   },
   [types.FETCH_STATS_START]: (state, action) => {
      return {
         ...state,
         isFetchingStats: true,
      }
   },
   [types.FETCH_STATS_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         isFetchingStats: false,
         statsData: payload,
      }
   },

   [types.VALIDATE_STEPS_START]: state => ({
      ...state,
      isFetchingValidate: true,
      errors: { },
   }),
   [types.VALIDATE_STEPS_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         activeTab: payload,
         isFetchingValidate: false,
      }
   },
   [types.SET_ACTIVE_TAB]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         activeTab: payload,
      }
   },
   [types.VALIDATE_STEPS_FAILED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         isFetchingValidate: false,
         errors: { ...payload },
      }
   },
   [types.SET_STATUS_MODAL]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         openStatusMod: payload,
      }
   },
   [types.CLEAR_ERRORE]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         errors: {
            ...state.errors,
            ...payload,
         },
      }
   },
   [types.SET_BUTTON_DISABLED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         updateButtonDisabled: payload,
      }
   },
   [types.CLEAR_DATA_ACTION]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         ...payload,
      }
   },
   [types.CLEAR_STATE]: (state, action) => {
      let newState = {}
      return {
         ...state,
         ...newState,
         ...action.payload,
      }
   },
   [types.SET_FILTERS]: (state, action) => {
      const { payload } =  action

      return {
         ...state,
         filters: {
            ...payload,
         },
      }
   },

   [types.DISTORY_PHOTOSETS_REQUEST]: (state, action) => {
      const { payload } =  action

      return {
         ...state,
         isDistoryFetching: payload,
      }
   },
   [types.DISTORY_PHOTOSETS_COMPLETED]: (state, action) => {
      const { payload: { hasFilter, ids, excludedIds } } =  action
      let data = []
      if('all' !== ids) {
         data = [...state.photosetsData.data].filter(i => !ids.includes(i.id))
      } else {
         data = [...state.photosetsData.data].filter(i => excludedIds.includes(i.id))
      }
      let  newState = {}
      if(!hasFilter) {
         newState = {
            isEmpty: data.length === 0,
         }
      } else {
         newState = {
            isEmptyByFilter: data.length === 0,
         }
      }
      return {
         ...state,
         isDistoryFetching: false,
         photosetsData: {
            ...state.photosetsData,
            data,
         },
         ...newState,
      }
   },
   [types.SET_BULK_IDS]: (state, action) => {
      const { payload } =  action

      return {
         ...state,
         bulkEditIds: { ...payload },
      }
   },
   [types.BULK_PHOTOSETS_REQUEST]: (state, action) => {
      const { payload } =  action
      return {
         ...state,
         isFetchingByIds: !!payload,
      }
   },
   [types.BULK_PHOTOSETS_COMLITED]: (state, action) => {
      const { payload } =  action

      return {
         ...state,
         photosetsDataByIds: payload,
         isFetchingByIds: false,
      }
   },
   [types.MULTIPLE_UPDATE_ACTION]: (state, action) => {
      const { payload: { bool, errors } } =  action

      return {
         ...state,
         bulkUpdateFetching: bool,
         bulkUpdateErrors: errors,
         isInited: false,
      }
   },
   [types.CLEAR_BULK_ERROR]: (state, action) => {
      const { payload } =  action
      return {
         ...state,
         bulkUpdateErrors: {
            ...state.bulkUpdateErrors,
            ...payload,
         },
      }
   },
   [types.SET_EDIT_TITLE_MODAL]: (state, action) => {
      const { payload } = action;
      let newErrorsState = {};
      let oldErrors = { ...state.errors };
      if(payload === false && (oldErrors?.title || oldErrors.description)){
         newErrorsState = {
            errors: {
               ...oldErrors,
               title: '',
               description: '',
            },
         }
      }
      return {
         ...state,
         openEditTitelMod: payload,
         ...newErrorsState,
      }
   },

   //reorder
   [types.REORDER_PHOTOSET]: (state, action) => {

      return {
         ...state,
         photosetsData: {
            ...state.photosetsData,
            data: action.payload,
         },
      }
   },
   [types.CHANGE_INITED_STATE]: (state) => {
      return {
         ...state,
         isInited: false,
      }
   },
   [types.SET_WARNING_MODAL]: (state, action) => {
      return {
         ...state,
         warningModal: {
            ...state.warningModal,
            ...action.payload,
         },
      }
   },

   [types.UPDATE_BUNDLE_STATE_IN_DATA]: (state, action) => {
      let { data: { id, attached_bundle_name, attached_bundle_id }, isDetailsPage } = action.payload

      if(isDetailsPage) {
         return {
            ...state,
            activeData: {
               ...state.activeData,
               attached_bundle_name, attached_bundle_id,
            },
         }
      }

      let updatedList = state.photosetsData.data.map(item => {
         if(item.id === id) {
            return {
               ...item,
               attached_bundle_name, attached_bundle_id,
            }
         }
         return item
      })

      return {
         ...state,
         photosetsData: {
            ...state.photosetsData,
            data: updatedList,
         },
      }
   },
   [types.PIN_PHOTOSET_COMPLETED]: (state, action) => {
      const { payload: { id, bool } } = action;
      const key = state.key || ''

      let data  = [...state?.[`photosetsData${ key }`].data].sort(function(item1, item2){
         if(item1.id === id)  item1.is_pinned = !bool
         if(item2.id === id)  item2.is_pinned = !bool
         if(item1.id === id) {
            if(bool){
               return item2.is_pinned ? -1 : new Date(item1.publish_date) - new Date(item2.publish_date)
            }
            return -1
         } else if(item2.id === id) {
            if(bool){
               return item1.is_pinned ? -1 : new Date(item2.publish_date) - new Date(item1.publish_date)
            }
            return 1
         } else if(item1.id !== id && item2.id !== id){
            return  item1.is_pinned && !item2.is_pinned ? -1 ? item2.is_pinned && !item1.is_pinned  : 1 : 0
         } else {
            return 0
         }
      });

      if(bool) {
         data  = [...data].sort(function(item1, item2){
            return item1.is_pinned ? -1 : item2.is_pinned ? 1 : 0
         })
      }

      return {
         ...state,
         [`photosetsData${ key }`]: {
            ...state?.[`photosetsData${ key }`],
            data: data,
         },
      }
   },
   [types.RESET_ACTIVE_DATA]: (state) => {
      return {
         ...state,
         isFetchingById: true,
         activeData: {},
      }
   },
   [types.UPDATE_MESSAGE_ACTION]: (state, action) => {
      const { ids, data, messageId } = action.payload;
      const key = state.key || '';
      let photosetsData = state?.[`photosetsData${ key }`]?.data ? [...state[`photosetsData${ key }`].data] : [];

      let updatedData = photosetsData

      updatedData = updatedData.reduce((prev, curr) => {
         let current = curr

         if(!!current?.messages?.length){

            current.messages = current.messages.map(message => {
               if(message?.id === messageId && message.unlock_details && message.unlock_details.resource_type === 'photoset') {
                  current.messages = current.messages.filter(it => it.id !== messageId)
               }
               if(message?.id === messageId && message.unlock_details && message.unlock_details.resource_type === 'multiple_attachment') {
                  if(message.unlock_details.attachments && message.unlock_details.attachments.length > 1) {
                     return {
                        ...message,
                        unlock_details: {
                           ...message.unlock_details,
                           attachments: message.unlock_details.attachments.filter(ct => ct.resource_id !== ids[0]),
                        },
                     }

                  } else {
                     current.messages = current.messages.filter(it => it.id !== messageId)
                  }

               }
               return message
            })
         }

         prev.push(current)
         return prev
      }, [])


      photosetsData = updatedData.map((el) => {
         let { ...item } = el;
         if(ids.includes(item.id)){
            let messages = null;
            if(item.messages && !action?.payload?.isDeleteAll){
               if(data){
                  messages = [...item.messages].map((message) => {
                     let { ...m } = message;
                     if(m.id === messageId){
                        m = {
                           ...m,
                           ...data,
                        }
                     }
                     return m;
                  })
               } else {
                  messages = [...item.messages].filter(m => m.id !== messageId);
               }
            }
            item.messages = messages;
            if(!messages?.length){
               item.messages = null;
            }
         }
         return item
      })
      return {
         ...state,
         [`photosetsData${ key }`]: {
            ...state?.[`photosetsData${ key }`],
            data: photosetsData,
         },
      }
   },

   [types.CHANGE_LOADING_STATE]: (state, { payload }) => {
      return {
         ...state,
         isFetchingById: payload,
      }
   },
   [types.UPDATE_PHOTOSETS_LIST]: (state, action) => {
      const  { payload: { date, id } } = action;

      const newData = state[`photosetsData${ state?.key }`]?.data?.map(it => {
         if(id === it?.id){
            return {
               ...it,
               last_repost_date: date,
            }
         }
         return it
      })

      return {
         ...state,
         [`photosetsData${ state?.key }`]: {
            ...state?.[`photosetsData${ state?.key }`],
            data: newData,
         },
      }
   },
};

export default createReducer(initialState)(reducersMap);
